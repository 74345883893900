import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { numberFormatter } from "../../libs/CommonUtil";

function UseQuantity(props) {
  const { t } = props;
  const { invitation } = props;
  const menuComboDetail = invitation.menu && invitation.menu.menuComboDetail;
  return (
    <>
      {invitation.menu && invitation.menu.menuCombo ? (
        <div>
          <div className="gochi-num-and-price flex_center flex_baseline text-green mt-3 mb-0">
            <span className="count-text">{t("pages.use of sheets 1")}</span>
            {invitation.couponCount - invitation.gochiCount + 1}
            <span className="count-text">
              /{invitation.couponCount}
              {t("pages.use of sheets 2")}
            </span>
          </div>
          <div className="gochi-num-and-price flex_center flex_baseline text-green mt-0">
            <span className="gochi-num">1</span>
            <span>{t("pages.sheet")}</span>
            <span className="ml-1">{t("pages.total sheet")}</span>
            <span className="gochi-price">
              {invitation.menu?.displayAmount ? 
                numberFormatter(invitation.menu?.displayAmount/menuComboDetail.quantity) : 
                  numberFormatter(invitation.menu &&
                    invitation.menu.menuComboDetail &&
                      invitation.menu.menuComboDetail.includedTaxPriceCombo)}
            </span>
            <span>円</span>
            {/* <span>({t("pages.Tax included")})</span> */}
          </div>
        </div>
      ) : (
        <div className="gochi-num-and-price flex_center flex_baseline text-green"> 
          <span className="gochi-num">{props.quantity}</span>
          <span>{t("pages.Serving")}</span>
          <span className="gochi-price">
            {numberFormatter(
              invitation.gochiType === "MENU"
                ? (invitation.menu.displayAmount
                    ? invitation.menu.displayAmount
                    : invitation.menu.includedTaxPrice) * props.quantity
                : ((invitation.amount + invitation.taxAmount) /
                    invitation.couponCount) *
                    props.quantity
            )}
          </span> 
          <span>円</span>
        </div>
      )}
    </>
  );
}

export default connect((state) => ({ invitation: state.gochis }))(
  withTranslation()(UseQuantity)
);
