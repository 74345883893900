import React from 'react';
import { withTranslation } from "react-i18next";
import GifPlayer from 'react-gif-player';
import {numberFormatter} from "../../libs/CommonUtil";
import i18n from '../../i18n';
import HamburgerMenu from './HamburgerMenu';
import Header from '../common/Header';

const NO_IMAGE_URL = '/images/gochi_noimg-ver4.jpg'
const gochiImage = (image) => ({
  backgroundImage: `url(${image ? image : NO_IMAGE_URL})`
});

class GiftComplete extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      copied: false
    }
    this.openInviteUrl = this.openInviteUrl.bind(this);
  }

  openInviteUrl = () => {  
    let {invitation} = this.props.location.state;
    window.location.replace(invitation.inviteUrl);
  };
  render() {
    const { t } = this.props;
    let {invitation} = this.props.location.state;
    let quantity = this.props.location.state.quantity;
    return (
      <div id="gochirareru">
        <Header title={t('pages.The order is complete')} />
        <figure className="gif-box">
          <GifPlayer
            gif="/images/thanks-img.png"
            still="/images/thanks-img.png"
            autoplay={true}
            width={"100%"}
            // pauseRef={pause => this.pauseGif = pause}
          />
        </figure>
        <div className="menu-box">
          <figure className="margin0">
            <img src={invitation.gochiBillImage[0] && invitation.gochiBillImage[0].image ? invitation.gochiBillImage[0].image : NO_IMAGE_URL} className="" width="150" height="auto" alt="" />
          </figure>
          <div className="menu-detail flex-1">
            <div className="text-right fs-14">
              <div className="menu-name menu-name-new text-right">{invitation.gochiType === 'MENU' ? invitation.menu.menuName : t('pages.GochiPRICE')}</div>
              <div className="menu-name menu-name-new text-right">{invitation.couponCount ? numberFormatter(quantity) : 0}{t('pages.pieces')}</div>
            </div>
          </div>
        </div>

        {!(invitation.menu && invitation.menu.menuCombo === 1) && (
          invitation.amount &&
            <div className="dish-infor mt-4 mb-3">
              <table className="table table-infor">
                <tbody>
                <tr>
                  <th className="text-left">{t('pages.textTotal')}</th>
                  <td>{numberFormatter(invitation.gochiType === 'MENU' ? ((invitation.menu.displayAmount ? invitation.menu.displayAmount : invitation.menu.includedTaxPrice) * (invitation.couponCount ? numberFormatter(quantity) : 0)) : ((invitation.amount + invitation.taxAmount)/invitation.couponCount * (invitation.couponCount ? numberFormatter(quantity) : 0)))}円（{t('pages.Tax included')}）</td>
                </tr>
                </tbody>
              </table>
            </div> 
        )}
        <div className="shop-name local-shop mb-3"><i className="icon-location"></i> {invitation.shop.shopName}</div>
        {invitation.gochiUserType !== 'YOURSELF' && (
          <div className="fs-14">
            <div>
              <span>{t('pages.The person who feasted this time')}</span>
            </div>
            <br/>
            <div className="user-name-new">
              <div className="user-box">
                <img className="user-img" src={invitation.gochiRegister ? (invitation.gochiRegister.userImage ? invitation.gochiRegister.userImage[0].image : NO_IMAGE_URL) : NO_IMAGE_URL} alt="" />
                <span className="user-name">{invitation.gochiRegister ? (invitation.gochiRegister.displayName ? invitation.gochiRegister.displayName : t('pages.GUEST')) : t('pages.GUEST')}</span>
                <span>{t('pages.Gochi from')}</span>
              </div>
            </div>
            <div className="message-box">
              <div className="message-row">
                {invitation.gochiBillMessage && invitation.gochiBillMessage[0] ? invitation.gochiBillMessage[0].message : ''}
              </div>
            </div>
          </div>
        )}

        {invitation.menu && invitation.menu.menuCombo === 1 && (
          <div className="invite-url"> 
            <button className="btn btn-green-new w-100p" onClick={this.openInviteUrl}>
              {t('pages.Display coupon tickets')}
            </button> 
          </div>
        )}
      </div>
    );
  }
}
export default (withTranslation()(GiftComplete));