import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Gochirareru from "./Gochirareru";
import Moment from "react-moment";
import moment from "moment";
import LoadingOverlay from "./LoadingOverlay";
import HamburgerMenu from "./HamburgerMenu";
import ListShop from "../common/ListShop";
import MenuInfo from "../common/MenuInfo";
import QuantityFreeDes from "../common/QuantityFreeDes";
import ThankYouMessage from "../common/ThankYouMessage";
import GochiBooking from "./booking/GochiBooking";
import Header from "../common/Header";

// const STEP_IMAGE_URL = "/images/steps.svg";

class GiftCombo extends React.Component {
  constructor(props) {
    super(props);
    this.refreshPage = this.refreshPage.bind(this);
    this.loadMoreShop = this.loadMoreShop.bind(this);
  }

  refreshPage() {
    window.location.reload(false);
  }

  loadMoreShop() {
    this.props.loadMoreShop();
  }

  async componentDidMount() {}

  render() {
    const { t } = this.props;
    const invitation = this.props.invitation;
    const shopData = this.props.invitation.shop;
    let totalCoupons = invitation.coupons.length + 1;
    const invitationCode = this.props.invitationCode;
    const expirationEndDate =
      invitation.menu?.menuComboDetail &&
      invitation.menu?.menuComboDetail.expirationEndDate
        ? new Date(invitation.menu?.menuComboDetail.expirationEndDate)
        : null;
    const isShowPrice = invitation.menu?.showPrice !== 0;
    return (
      <div className="gochi">
        <div id="menu">
          <Header title={invitation.menu &&
            (invitation.menu.menuChildType === 1 ||
              invitation.menu.menuChildType === 2)
              ? shopData && shopData.shopName
                ? shopData.shopName
                : ""
              : invitation.group && invitation.group.groupName
              ? invitation.group.groupName
              : ""} />
          {shopData && shopData.status === "CLOSED" && (
            <div className="shop-closed">{t("pages.This shop has closed")}</div>
          )}
          {invitation.menu && invitation.menu.isETicket === 0 && 
            (<div className="mb-3 paper-ticket-note">{t("pages.paper ticket note")}</div>)}
          {invitation && <ThankYouMessage invitation={invitation} />}
          <MenuInfo 
            invitation={invitation} 
            menuLimitTotal={invitation?.menuLimitTotal === 1} 
            hideAmount
          />

          {invitation.menu &&
            invitation.menu.description &&
            invitation.menu.description !== "" && (
              <div className="menu-description-combo fs-13">
                {invitation.menu.description}
              </div>
            )}

          <QuantityFreeDes />

          {invitation.menu &&
            invitation.menu.menuComboDetail &&
            invitation.menu.menuComboDetail.quantityFree > 0 && (
              <span className="big-free-ticket-label">
                {isShowPrice ? (
                  <>
                    {`+`}{t('pages.Advantageous (total)', { 
                      freeTicketCount: invitation.menu.menuComboDetail.quantityFree, 
                      totalCoupon: invitation.menu.menuComboDetail.quantityFree +
                      invitation.menu.menuComboDetail.quantity})}
                  </>
                ) : (
                  "合計" + (invitation.menu.menuComboDetail.quantityFree + invitation.menu.menuComboDetail.quantity) + "枚"
                )}
              </span>
            )}

          {invitation.menu.menuComboDetail &&
            invitation.menu.menuComboDetail.expiration && (
              <div className="mt-2">
                <span className="fs-12 clr-000000">
                  {t("pages.usage period")}
                </span>
                <span className="text-red fs-13 mt-2 font-weight-bold ml-2">
                  {t("pages.available days", {
                    days: invitation.menu.menuComboDetail.expiration,
                  })}
                </span>
              </div>
            )}

          {expirationEndDate && (
            <div className="mt-2">
              <span className="fs-12 clr-000000">
                {t("pages.usage period")}
              </span>
              <span className="text-red fs-13 mt-2 font-weight-bold ml-2">
                {t("pages.available to date", {
                  date: `${expirationEndDate.getFullYear()}/${
                    expirationEndDate.getMonth() + 1 < 10 ? "0" : ""
                  }${
                    expirationEndDate.getMonth() + 1
                  }/${expirationEndDate.getDate()}`,
                })}
              </span>
            </div>
          )}

          {invitation.menu.menuComboDetail &&
            invitation.menu.menuComboDetail.numberUsePerDay === 1 && (
              <div className="mt-2">
                <span className="fs-12 clr-000000">
                  {t("pages.usage limit")}
                </span>
                <span className="text-red fs-13 font-weight-bold ml-2">
                  {t("pages.1 sheet per day")}
                </span>
              </div>
            )}

          {invitation.groupId ? (
            <div className="lst-shop-cmm">
              {invitation.menu?.flagScanQr !== 1 && (
                <div
                  className="btn list-shop-btn new-btn p-1"
                  onClick={() => {
                    this.props.handleOpenListShopPopup();
                  }}
                >
                  {t("pages.List of stores")}
                </div>
              )}
              {invitation.menu?.flagScanQr === 1 && (
                <div>
                  <div
                    className="btn list-shop-btn new-btn p-1"
                    onClick={() => {
                      this.props.handleScanQRCode();
                    }}
                  >
                    {t("pages.Scan the QR code of the store you use")}
                  </div>
                  <div className="text-can-not-scan-qr">
                    <span dangerouslySetInnerHTML={{ __html: t("pages.Can not open camera to scan the QR code before")}}></span>
                    <span onClick={() => { this.props.handleOpenListShopPopup(); }} className="text-link-scan-qr" dangerouslySetInnerHTML={{ __html: t("pages.Click here to open list shop")}}></span>
                    <span dangerouslySetInnerHTML={{ __html: t("pages.Can not open camera to scan the QR code after")}}></span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              {invitation.shop && (
                <div>
                  {/* <div className="divider-dashed"></div> */}
                  <hr className="new-hr mt-1 mb-3" />
                  <div className="shop-name local-shop pl-0 pb-0">
                    {/* <i className="icon-location"></i>  */}
                    {invitation.shop.shopName}
                  </div>
                  <div className="fs-14 shop-address mt-1">
                    {invitation.shop.prefectures
                      ? invitation.shop.prefectures + " "
                      : ""}
                    {invitation.shop.city ? invitation.shop.city + " " : ""}
                    {invitation.shop.address
                      ? invitation.shop.address + " "
                      : ""}
                    {invitation.shop.building}
                  </div>
                  <div className="fs-14 text-green mt-2">
                    {invitation.shop.showPhone === 1 && invitation.shop.phone}
                  </div>
                  <div
                    className="btn btn-green-38 flex_center new-btn mb-2"
                    onClick={this.props.linkGoogleMap}
                  >
                    <span>{t("pages.Click here for MAP")}</span>
                  </div>
                  <GochiBooking
                    invitationGochi={invitation}
                    invitationCode={invitationCode}
                    handleOpenBookingPopup={this.props.handleOpenBookingPopup}
                />
                </div>
              )}
            </>
          )}

          <div className="gochi-stamp-combo mt-3">
            {invitation.menu &&
                (invitation.menu.menuChildType === 1 ||
                  invitation.menu.menuChildType === 2) && (
                  <div className="gochi-stamp-combo-info px-0">
                    <Gochirareru
                      invitationGochi={invitation}
                      invitationCode={invitationCode}
                    />
                    <p className="fs-12 mt-2">
                      {t("pages.use menu combo guide")}
                    </p>
                  </div>
              )}
            <div className="gochi-stamp-combo-info">
              {invitation.coupons && invitation.coupons.length > 0 && (
                <div className="list-stamps">
                  {invitation.coupons.map(function (coupon, key) {
                    if (coupon.useDate) {
                      totalCoupons = totalCoupons - 1;
                    }
                    return coupon.useDate ? (
                      <div className="stamp-item-wrapper" key={coupon.couponId}>
                        <div className="stamp-item stamp-used">
                          <i className="icon-checked-combo"></i>
                        </div>
                        <span className="stamp-item-used-date">
                          <Moment format="MM/DD">{coupon.useDate}</Moment>
                        </span>
                      </div>
                    ) : null;
                  })}

                  {invitation.coupons.map(function (coupon, key) {
                    if (
                      coupon.amount !== 0 &&
                      coupon.taxAmount !== 0 &&
                      !coupon.useDate
                    ) {
                      totalCoupons = totalCoupons - 1;
                    }
                    return coupon.amount !== 0 &&
                      coupon.taxAmount !== 0 &&
                      !coupon.useDate ? (
                      <div className="stamp-item-wrapper" key={coupon.couponId}>
                        <div className="stamp-item stamp-new">
                          <i className="icon-digit">{totalCoupons}</i>
                        </div>
                        <span className="stamp-item-used-date">&nbsp;</span>
                      </div>
                    ) : null;
                  })}

                  {invitation.coupons.map(function (coupon, key) {
                    if (
                      coupon.amount === 0 &&
                      coupon.taxAmount === 0 &&
                      !coupon.useDate
                    ) {
                      totalCoupons = totalCoupons - 1;
                    }
                    return coupon.amount === 0 &&
                      coupon.taxAmount === 0 &&
                      !coupon.useDate ? (
                      <div className="stamp-item-wrapper" key={coupon.couponId}>
                        <div className="stamp-item stamp-promotion">
                          <span className="stamp-item-promotion-label">
                            {t("pages.Free")}
                          </span>
                          <i className="icon-digit">{totalCoupons}</i>
                        </div>
                        <span className="stamp-item-used-date">&nbsp;</span>
                      </div>
                    ) : null;
                  })}
                </div>
              )}

              <div className="stamp-count-info-div">
                <div className="stamp-count-info">
                  <p className="text-center">
                    <span className="fs-20">{t("pages.Remaining")}</span>
                    <span className="count-stamp-remain">
                      {invitation.gochiCount ? invitation.gochiCount : 0}
                    </span>
                    <span className="count-stamp-remain-label">
                      {t("pages.sheet")}
                    </span>
                    <span className="fs-20">
                      {" / "}
                      {invitation.couponCount ? invitation.couponCount : 0}
                      {t("pages.sheet")}
                    </span>
                  </p>
                </div>
              </div>

              <div className="expired-date-label-combo mt-3">
                {t("pages.expiration date")}
              </div>
              <div className="message-row-combo mt-1">
                <div className="d-flex align-items">
                  <div className="expired-date-combo mr-0">
                    {invitation.gochiExpired &&
                      moment(invitation.gochiExpired).format(
                        `YYYY${t("pages.year")}MM${t("pages.month")}DD${t(
                          "pages.day"
                        )}`
                      )}
                    {t("pages.Until")}
                  </div>
                </div>

              </div>
              <p className="fs-12">※使用期限が過ぎたものは無効となります。規約上、返金等はできませんのでご注意ください。</p>

              <div className="mt-2">
                ※
                <span className="user-name">
                  {(invitation.gochiRegister &&
                    invitation.gochiRegister.displayName) ||
                    "GUEST"}
                </span>
                {t("pages.We have received the payment")}
              </div>
            </div>
          </div>

          <div className="mb-3">
            <div className="explain-icon ic-1 align-center">
              <div className="explain-note">{t("pages.Explain IC 1 menu combo")}</div>
            </div>
            <div className="explain-icon ic-2 align-center">
              <div className="explain-note">{t("pages.Explain IC 2 menu combo")}</div>
            </div>
            <div className="explain-icon ic-3 align-center">
              <div className="explain-note">{t("pages.Explain IC 3 menu combo")}</div>
            </div>
          </div>

          <div className="gochi-stamp-combo mt-3">
              <div className="gochi-stamp-combo-info border-radius-0">
                <div>
                  <div className="clr-000000 fs-18 font-weight-bold">領収書番号 : {invitation.gochiBillNo}</div>
                  <div><span className="fs-12 clr-000000">チケット利用問合先 : </span><a href="tel:050-5536-3317" className="fs-12 text-red clr-000000">050-5536-3317</a></div>
                  <div className="text-red">※サポートセンターへの問い合わせ電話です。ご予約は、直接店舗様にお願いいたします。</div>
                  <div className="fs-12 clr-000000">平日（月～金） 10:00～18:00／土曜日 10:00～15:00</div>
                  <div className="fs-12 clr-000000">※お休み：日曜日・祝日 ・長期休暇・お盆休み・年末年始</div>
                </div>
              </div>
            </div>
        </div>

        <LoadingOverlay loading={this.props.loading} />
        <ListShop
          hasMore={this.props.hasMore}
          loadMoreShop={this.props.loadMoreShop}
          invitation={invitation}
          invitationCode={invitationCode}
          listShop={this.props.listShop}
          setKeyword={this.props.setKeyword}
          keyword={this.props.keyword}
          searchKeyword={this.props.searchKeyword}
          showListShopPopup={this.props.showListShopPopup}
          handleCloseListShopPopup={this.props.handleCloseListShopPopup}
          shopCount={this.props.shopCount}
		  isQRScan={this.props.isQRScan}
        />
      </div>
    );
  }
}
export default connect((state) => ({ invitation: state.gochis }))(
  withTranslation()(GiftCombo)
);
