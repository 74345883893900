import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Media from "react-bootstrap/Media";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { Modal, ModalBody } from "react-bootstrap";

const customStyles = {
  overlay: {
    background: "#808080",
  },
  content: {
    marginTop: "10px",
  },
};

class ListShop extends Component {
  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  render() {
    const { t } = this.props;
    const invitation = this.props.invitation;

    const onSelect = this.props.onSelect;

    return (
      <>
        <Modal
          show={this.props.showListShopPopup}
          onHide={this.props.handleCloseListShopPopup}
          dialogClassName="custom-modal"
          contentClassName="top-modal"
          backdropClassName="top-modal-backdrop"
        >
          <Modal.Body className="alert-dialog">
            {/* {this.props.listShop.length > 0 && ( */}
              <div className="lst-shop-cmm mt-4">
                <div className="title-lst-shop align-center">
                  {/* {t(
                    "pages.List of stores where this meal voucher can be used"
                  )} */}
                  このお食事券が使える店舗一覧
                </div>

                <div className="searchShop">
                  <div className="inputField">
                    <div className="fieldset fieldset-lg">
                      <div className="fieldset-header">
                        <svg className="icon">
                          <use xlinkHref="/images/icon.svg#search" />
                        </svg>
                        <div className="title">キーワード</div>
                      </div>
                      <div className="fieldset-body">
                        <div className="field field-pill field-lg">
                          <input
                            type="text"
                            placeholder="キーワード"
                            value={this.props.keyword}
                            onChange={event => this.props.setKeyword(event)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btnSearchF">
                    <div className="fieldset fieldset-submit">
                      <button type="button" className="btn btn-submit search-btn" onClick={() => this.props.searchKeyword()}>
                        検索する
                      </button>
                    </div>
                  </div>
                </div>

                <div className="lst-shops">
                  <div className="d-flex align-items-center mt-3 mb-2">
                    <div className="font-weight-bold fs-15 clr-000000">
                      {/* {t("pages.searchResult")} */}
                      検索結果
                    </div>
                    <div className="ml-auto count-left">
                      {t("pages.booking total shop", {
                        count: this.props.listShop && this.props.shopCount,
                      })}
                    </div>
                  </div>
                  <hr className="new-hr mt-0" />
                  <ListGroup className="list-items">
                    {this.props.listShop.map(function (shop, key) {
                      return (
                        <ListGroup.Item key={shop.shopId} className="shop-item">
                          <Media>
                            <Media.Body className="shop-body">
                              <h5 className="shop-name">{shop.shopName}</h5>
                              <p className="shop-distance">
                                {!(!shop.distance && !shop.nearestStation) && (
                                  <FontAwesomeIcon
                                    icon={faMapMarkerAlt}
                                    className="mr-2 fs-16"
                                  />
                                )}
                                {shop.prefectures ? shop.prefectures + " " : ""}
                                {shop.city ? shop.city + " " : ""}
                                {shop.address ? shop.address + " " : ""}
                                {shop.building}
                              </p>
                              <div
                                className="btn btn-red no-min-height flex_center btn-shop bg-red"
                                onClick={() => {
                                  onSelect(shop);
                                }}
                              >
                                {/* {t("pages.Use at this shop")} */}
                                このお店で利用する
                              </div>
                            </Media.Body>
                          </Media>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>

                  {this.props.hasMore && (
                    <div className="text-center mt-3">
                      <Button
                        variant="link"
                        className="btn btn-load-more"
                        onClick={this.props.loadMoreShop}
                      >
                        {/* {t("pages.Load more")} */}
                        さらに表示
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            {/* )} */}
          </Modal.Body>

          <div className="fs-15 clr-000000 align-center">
            <button
              onClick={this.props.handleCloseListShopPopup}
              className="btn btn-close-popup"
            >
              {/* {t("pages.Close")} */}
              閉じる
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

// const mapDispatchToProps = (dispatch) => ({
//   showLoading: (showing) => dispatch(showLoading(showing)),
//   showError: (showing, title, content, buttonName, gotoUrl) =>
//     dispatch(showError(showing, title, content, buttonName, gotoUrl)),
// });

export default connect(
  mapStateToProps
  //   mapDispatchToProps
)(withTranslation()(ListShop));
