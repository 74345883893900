import React, { useEffect, useState } from 'react';
import { getSurvey, postSurveyAnswer, postImage } from '../../libs/apis';
import loadImage from 'blueimp-load-image';
import UploadImageForm from './UploadImageForm';

export const SURVEY_QUESTION_TYPE = {
    TEXT: 1,
    RADIO: 2,
    CHECKBOX: 3,
    IMAGE: 4
  };

function SurveyForm(props) {
    const { menuEventId, menuId, shopId, surveyType, gochiBillId } = props;
    const [surveyDetails, setSurveyDetails] = useState({});
    const questions = surveyDetails.questions || [];
    const [answers, setAnswers] = useState({});
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);
    const [arrImage, setArrImage] = useState([]);
    const [previewImage, setPreviewImage] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const updateArrImage = (file, index) => {
        const updateArrImages = [...arrImage];
        updateArrImages[index] = file;
        setArrImage(updateArrImages);
    }

    const updateArrImagePreview = (file, index) => {
        const updateArrImagePreviews = [...previewImage];
        updateArrImagePreviews[index] = file;
        setPreviewImage(updateArrImagePreviews);
    }

    const removeImage = (index) => {
        const newArr = [...arrImage];
        newArr.splice(index, 1);
        setArrImage(newArr);
        const newArrPreview = [...previewImage];
        newArrPreview.splice(index, 1);
        setPreviewImage(newArrPreview);
    }

    const toBlob = (base64, fileName, reject) => {
        const bin = atob(base64.replace(/^.*,/, ''));
        const buffer = new Uint8Array(bin.length);
        for (let i = 0; i < bin.length; i += 1) {
            buffer[i] = bin.charCodeAt(i);
        }

        try {
            const blob = new Blob([buffer.buffer], {
            type: 'image/*'
            });
            blob.name = fileName;
            return blob;
        } catch (e) {
            reject();
            return false;
        }
    };

    const resizeImage = image => {
    return new Promise((resolve, reject) => {
        loadImage.parseMetaData(image, data => {
        const options = {
            maxWidth: process.env.REACT_APP_RESIZE_MAX_WIDTH,
            canvas: true
        };
        if (data.exif) {
            options.orientation = data.exif.get('Orientation');
        }
        loadImage(
            image,
            canvas => {
            const imageUri = canvas.toDataURL('image/*');
            const resizeImageFile = toBlob(imageUri, image.name, reject);
            resolve({
                resizeImageFile
            });
            },
            options
        );
        });
    });
    };

    async function getSurveyDetails() {
        try {
            setIsButtonDisabled(false);
            const response = await getSurvey({
                menuEventId, menuId, shopId, surveyType
            });
            if (response.status !== 200) {
                if (response.status === 404) {
                    props.onInitFailed();
                    setSuccess(true);
                    return;
                } else {
                    alert('フォームの表示に失敗しました。前画面に戻って、もう一度やり直してください。');
                    setIsButtonDisabled(true);
                    props.onGetSurveyError();
                }
            }
            setSurveyDetails(response.data);
            const qs = response.data.questions || [];
            let initialAnwsers = {};
            for (let i = 0; i < qs.length; i++) {
                if (qs[i].questionType === SURVEY_QUESTION_TYPE.TEXT) {
                    initialAnwsers[i] = '';
                } else if (qs[i].questionType === SURVEY_QUESTION_TYPE.RADIO) {
                    initialAnwsers[i] = '';
                } else if (qs[i].questionType === SURVEY_QUESTION_TYPE.CHECKBOX) {
                    initialAnwsers[i] = new Set();
                }
            }
            setAnswers(initialAnwsers);
            props.onInitSuccess();
        } catch (e) {
            console.error('get survey detail error', e.response.status);
            if (e.response.status === 404) {
                props.onInitFailed();
                setSuccess(true);
                return;
            }
        } finally {
        };
    }

    useEffect(() => {
        getSurveyDetails();
    }, [menuEventId, menuId, shopId, surveyType]);

    function onChangeTextAnwers(index, value) {
        const newState = { ...answers };
        newState[index] = value;
        setAnswers(newState);
    }

    function onChangeRadioAnswer(index, value) {
        console.log('onChangeRadioAnswer');
        const newState = { ...answers };
        newState[index] = value;
        setAnswers(newState);
    }

    function onChangeCheckboxAnswer(index, checked, label) {
        const newState = { ...answers };
        if (!newState[index]) {
            newState[index] = new Set();
        }
        if (checked) {
            newState[index].add(label);
        } else {
            newState[index].delete(label);
        }
        setAnswers(newState);
    }

    async function validateAnswers() {
        setIsButtonDisabled(true);
        let newErrorState = {};
        let isValid = true;
        let arrImageP = [];
        for (let i = 0; i < questions.length; i++) {
            if (questions[i].questionType === SURVEY_QUESTION_TYPE.IMAGE) { 
                await Promise.all(
                    arrImage.map(async (image, index) => {
                    if (image !== null) {
                        if (image.size > Number(process.env.REACT_APP_RESIZE_BORDER)) {
                            const { resizeImageFile } = await resizeImage(image);
                            image = new File([resizeImageFile], image.name, {type: image.type});
                        }
                        await postImage(image, surveyDetails.surveyId)
                        .then((response) => {
                            console.log('response', response);
                            if(response.status === 200){
                                arrImageP = [...arrImageP, response.data];
                                return arrImageP;
                            } else {
                                alert('画像のアップロードにエラーが発生しました。');
                                isValid = false;
                            }
                        })
                        .catch((e) => {
                            alert('画像のアップロードにエラーが発生しました。');
                            isValid = false;
                        });
                    }
                    return null;
                    })
                );
            }

            if (questions[i].required === true) {
                if (questions[i].questionType === SURVEY_QUESTION_TYPE.CHECKBOX && answers[i].size === 0) {
                    newErrorState[i] = 'この項目が必須です。';
                    isValid = false;
                } else if (questions[i].questionType === SURVEY_QUESTION_TYPE.IMAGE && arrImageP.length === 0 ) {
                    newErrorState[i] = 'この項目が必須です。';
                    isValid = false;
                } else if (questions[i].questionType === SURVEY_QUESTION_TYPE.IMAGE && arrImageP.length < questions[i].imageNumber) {
                    newErrorState[i] = '必要な枚数の画像をアップロードしてください。';
                    isValid = false;
                } else if (questions[i].questionType !== SURVEY_QUESTION_TYPE.CHECKBOX && questions[i].questionType !== SURVEY_QUESTION_TYPE.IMAGE && !answers[i]) {
                    newErrorState[i] = 'この項目が必須です。';
                    isValid = false;
                }
            }


            if (questions[i].questionType === SURVEY_QUESTION_TYPE.TEXT && questions[i].regex && answers[i]) {
                const regex = new RegExp(questions[i].regex);
                if (!regex.test(answers[i])) {
                    isValid = false;
                    newErrorState[i] = questions[i].validationMessage;
                    console.log('5');
                }
            } 
        }
        setTimeout(() => setIsButtonDisabled(false), 1000);
        setErrors(newErrorState);
        return [isValid, arrImageP];
    }

    async function onSubmitSurvey() {
        console.log('onSubmitSurvey');
        const valid = await validateAnswers();
        if (valid[0]) {
            const submission = questions.map((item, i) => {
                if (item.questionType === SURVEY_QUESTION_TYPE.CHECKBOX) {
                    return Array.from(answers[i]).join(','); 
                } else if (item.questionType === SURVEY_QUESTION_TYPE.IMAGE) {
                    answers[i] = valid[1];
                    return Array.from(answers[i]).join(',');
                } else { 
                    return answers[i];
                }
            });
            console.log('SURVEY SUBMIT DATA: ', submission);
            try {
                const res = await postSurveyAnswer(surveyDetails.surveyId, JSON.stringify(submission), gochiBillId);
                console.log('res', res);
                if (res.status === 200) {
                    props.onSubmitSuccess(res.data.surveySubmissionId);
                    setSuccess(true);
                } else {
                    alert('フォームの送信に失敗しました。前画面に戻って、もう一度やり直してください。');
                }
            } catch (e) {
                console.error('Submit survey error', e);
                alert('フォームの送信に失敗しました。前画面に戻って、もう一度やり直してください。');
            } finally {
                // 
            }
        };
    }

    if (success) {
        return null;
    }

    return (
        <div className="servey">
            <h2 className="title">{surveyDetails.surveyName}</h2>
            <div className="white-space-pre-line description">{surveyDetails.surveyDescription}</div>
            {questions.map((q, index) => {
                return (<div className="mt-3" key={index}>
                    <div className="mt-3 text-red">{errors[index]}</div>
                    {q.questionType === SURVEY_QUESTION_TYPE.TEXT && (
                        <div>
                            <label className="ttl-label my-1 white-space-pre-line">
                                {q.question}{q.required && <span className="text-red">*</span>}
                            </label>
                            <div className="d-flex align-items-center">
                                <input className="form-control" placeholder="回答をご入力ください。" value={answers[index]} onChange={e => onChangeTextAnwers(index, e.target.value)} type="text" />
                            </div>
                        </div>
                    )}

                    {q.questionType === SURVEY_QUESTION_TYPE.RADIO && q.optionArr?.length > 0 && (
                        <div>
                            <label className="ttl-label my-1 white-space-pre-line">
                                {q.question}{q.required && <span className="text-red">*</span>}
                            </label>
                            <div>
                                {q.optionArr.map(a => (
                                    <label className="radio-custom" key={a}>
                                        <input onChange={e => onChangeRadioAnswer(index, e.target.value)} checked={answers[index] === a} name={q.question} value={a} type="radio" />
                                        <span className="checkmark"></span>
                                        <span className="white-space-pre-line">{a}</span>
                                    </label>
                                ))}
                            </div>
                        </div>
                    )}

                    {q.questionType === SURVEY_QUESTION_TYPE.CHECKBOX && q.optionArr?.length > 0 && (
                        <div>
                            <label className="ttl-label my-1 white-space-pre-line">
                                {q.question}{q.required && <span className="text-red">*</span>}
                            </label>
                            <div>
                                {q.optionArr.map(a => (
                                    <label className="checkbox-custom" key={a}>
                                        <input checked={answers[index]?.has(a)} onChange={e => onChangeCheckboxAnswer(index, e.target.checked, a)} name={q.question} value={a} type="checkbox" />
                                        <span className="checkmark"></span>
                                        <span className="font-weight-normal white-space-pre-line">{a}</span>
                                    </label>
                                ))}
                            </div>
                        </div>
                    )}
                    
                    {q.questionType === SURVEY_QUESTION_TYPE.IMAGE && q.imageNumber > 0 && (
                        <div>
                            <label className="ttl-label white-space-pre-line">
                                {q.question} {q.required && <span className="text-red">*</span>}
                            </label>
                            <div className="mt-2">
                                {[...Array(q.imageNumber)].map((a, index) => (
                                    <UploadImageForm
                                        label={"image" + index}
                                        setFile={updateArrImage}
                                        previewImage={previewImage[index]}
                                        setPreviewImage={updateArrImagePreview}
                                        indexImg={index}
                                        removeImage={removeImage}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>)
            })}
            <button onClick={onSubmitSurvey} disabled={isButtonDisabled} className="btn btn-green-38 mt-4">送信と次の画面へ</button>
        </div>
    );
}

export default SurveyForm;
