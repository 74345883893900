import {
  getPrefecturesApi,
  getCitiesApi,
  getGenresApi,
  getShopsApi,
  getShopDetailApi,
  createBillApi
} from '../libs/apis/DaigasApi';

export const GET_LIST_PREFECTURES_REQUEST = 'GET_LIST_PREFECTURES_REQUEST';
export const GET_LIST_PREFECTURES_SUCCESS = 'GET_LIST_PREFECTURES_SUCCESS';
export const GET_LIST_PREFECTURES_FAILURE = 'GET_LIST_PREFECTURES_FAILURE';

export const GET_LIST_CITIES_REQUEST = 'GET_LIST_CITIES_REQUEST';
export const GET_LIST_CITIES_SUCCESS = 'GET_LIST_CITIES_SUCCESS';
export const GET_LIST_CITIES_FAILURE = 'GET_LIST_CITIES_FAILURE';

export const GET_LIST_GENRES_REQUEST = 'GET_LIST_GENRES_REQUEST';
export const GET_LIST_GENRES_SUCCESS = 'GET_LIST_GENRES_SUCCESS';
export const GET_LIST_GENRES_FAILURE = 'GET_LIST_GENRES_FAILURE';

export const GET_LIST_DAIGAS_SHOPS_REQUEST = 'GET_LIST_DAIGAS_SHOPS_REQUEST';
export const GET_LIST_DAIGAS_SHOPS_SUCCESS = 'GET_LIST_DAIGAS_SHOPS_SUCCESS';
export const GET_LIST_DAIGAS_SHOPS_FAILURE = 'GET_LIST_DAIGAS_SHOPS_FAILURE';

export const GET_DETAIL_DAIGAS_SHOPS_REQUEST = 'GET_DETAIL_DAIGAS_SHOPS_REQUEST';
export const GET_DETAIL_DAIGAS_SHOPS_SUCCESS = 'GET_DETAIL_DAIGAS_SHOPS_SUCCESS';
export const GET_DETAIL_DAIGAS_SHOPS_FAILURE = 'GET_DETAIL_DAIGAS_SHOPS_FAILURE';

export const  CREATE_BILL_REQUEST = 'CREATE_BILL_REQUEST';
export const CREATE_BILL_SUCCESS = 'CREATE_BILL_SUCCESS';
export const CREATE_BILL_FAILURE = 'CREATE_BILL_FAILURE';

export const getListPrefectures = () => dispatch => {
  dispatch({ type: GET_LIST_PREFECTURES_REQUEST });
  return getPrefecturesApi()
    .then(response =>
      dispatch({
        type: GET_LIST_PREFECTURES_SUCCESS,
        payload: response.data
      })
    )
    .catch(error =>
      dispatch({
        type: GET_LIST_PREFECTURES_FAILURE,
        payload: error.response,
        error: true
      })
    );
};

export const getListCities = () => dispatch => {
  dispatch({ type: GET_LIST_CITIES_REQUEST });
  return getCitiesApi()
    .then(response =>
      dispatch({
        type: GET_LIST_CITIES_SUCCESS,
        payload: response.data
      })
    )
    .catch(error =>
      dispatch({
        type: GET_LIST_CITIES_FAILURE,
        payload: error.response,
        error: true
      })
    );
};

export const getListGenres = () => dispatch => {
  dispatch({ type: GET_LIST_GENRES_REQUEST });
  return getGenresApi()
    .then(response =>
      dispatch({
        type: GET_LIST_GENRES_SUCCESS,
        payload: response.data
      })
    )
    .catch(error =>
      dispatch({
        type: GET_LIST_GENRES_FAILURE,
        payload: error.response,
        error: true
      })
    );
};

export const getListDaigasShops = (prefectureName, cityName, genreId, keyword, page, limit, giftCode) => dispatch => {
  dispatch({ type: GET_LIST_DAIGAS_SHOPS_REQUEST });
  return getShopsApi(prefectureName, cityName, genreId, keyword, page, limit, giftCode)
    .then(response =>
      dispatch({
        type: GET_LIST_DAIGAS_SHOPS_SUCCESS,
        payload: response.data
      })
    )
    .catch(error =>
      dispatch({
        type: GET_LIST_DAIGAS_SHOPS_FAILURE,
        payload: error.response,
        error: true
      })
    );
};

export const getDetailDaigasShops = (shopId, giftCode) => dispatch => {
  dispatch({ type: GET_DETAIL_DAIGAS_SHOPS_REQUEST });
  return getShopDetailApi(shopId, giftCode)
    .then(response =>
      dispatch({
        type: GET_DETAIL_DAIGAS_SHOPS_SUCCESS,
        payload: response.data
      })
    )
    .catch(error =>
      dispatch({
        type: GET_DETAIL_DAIGAS_SHOPS_FAILURE,
        payload: error.response,
        error: true
      })
    );
};

export const createDaigasBill = (shopId, giftCode, email) => dispatch => {
  dispatch({ type: CREATE_BILL_REQUEST });
  return createBillApi(shopId, giftCode, email)
    .then(response =>
      dispatch({
        type: CREATE_BILL_SUCCESS,
        payload: response.data
      })
    )
    .catch(error =>
      dispatch({
        type: CREATE_BILL_FAILURE,
        payload: error.response,
        error: true
      })
    );
};