import { createStore, applyMiddleware } from 'redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import client from 'axios';
import createBrowserHistory from 'history/createBrowserHistory';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import reducer from '../reducers';

// browser格納用storage生成
const history = createBrowserHistory();
const middleware = routerMiddleware(history);
// axiosをthunkの引数に追加
const thunkWithClient = thunk.withExtraArgument(client);

export default initialState => {
  const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(thunk, logger, middleware, thunkWithClient)
    )
  );
  return store;
};
