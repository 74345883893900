import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import queryString from "query-string";
import { getInvitations, utilizeGochis, getAuthenCode } from "../../actions/Gochis";
import MenuInfo from "../common/MenuInfo";
import OrderConfirmButton from "../common/OrderConfirmButton";
import QuantityFreeDes from "../common/QuantityFreeDes";
import UseQuantity from "../common/UseQuantity";
import HamburgerMenu from "./HamburgerMenu";
import history from "../history";
import Modal from "react-modal";
import LoadingOverlay from "./LoadingOverlay";
import Header from "../common/Header";

const customStyles = {
  overlay: {
    background: "#808080",
    padding: 15,
  },
  content: {},
};

function ShopTicketUseConfirm(props) {
  const { t } = props;
  const { dispatch } = props;
  const { invitation } = props;
  
  const [isValidCode, setIsValidCode] = useState(true);
  const [isValidInvitation, setIsValidInvitation] = useState(true);
  const [isValidUsingGochi, setIsValidUsingGochi] = useState(true);
  const [shopId, setShopId] = useState(null);
  const [shopName, setShopName] = useState(null);
  const [quantity, setQuantity] = useState(0);

  // const params = queryString.parse(window.location.search);
  const [crfsToken, setCrfsToken] = useState("");
  const [overLimitError, setOverLimitError] = useState(false);
  const [overLimitErrorMess, setOverLimitErrorMess] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const [loading, setLoading] = useState(false);

  const params = queryString.parse(window.location.search);
  var query = new URLSearchParams(props.location.search);
  var authenCode = query.get("authenCode");
  var useAmount = query.get("useAmount");
  var useAmount = query.get("useAmount");
  var menuLimitTotal = query.get("menuLimitTotal");

  function getAuthenCodeInfo() {
    let action = getAuthenCode(authenCode);
    dispatch(action).then((response) => {
      if (response.type === "GET_AUTHEN_CODE_SUCCESS") {
        setIsValidCode(true);
        if (response.payload) {
          setShopId(response.payload.authenData && response.payload.authenData.shopId);
          setQuantity(response.payload.authenData && response.payload.authenData.quantity);
          setShopName(response.payload.extraData && response.payload.extraData.shopName);
        }
      } else {
        setIsValidCode(false);
      }
    });
  }

  function getInvitationInfo() {
    setLoading(true);
    try {
      let action = getInvitations({ authenCode: authenCode });
      dispatch(action).then((response) => {
        if (response.type === 'GET_GOCHIS_SUCCESS') {
          setIsValidInvitation(true);
        } else {
          setIsValidInvitation(false);
        }
        setLoading(false);
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }
  
  function cancelAction() {
    history.goBack();
  }

  function generateRandomString(length = 32) {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    setCrfsToken(text);
    console.log("crfs token", text);
  }

  function confirmAction() {
    setLoading(true);
    let urlComplete = '';
    if (menuLimitTotal == 'true') {
      urlComplete = `?authenCode=${authenCode}&menuLimitTotal=true&useAmount=${useAmount}&shopName=${shopName || params.shopName}`;
    } else {
      urlComplete = `?authenCode=${authenCode}`;
    }
    let action = utilizeGochis(
      {
        shopId: invitation.shopId || shopId,
        menuId: invitation.menu ? invitation.menu.menuId : null,
        quantity: quantity,
        gochiBillId: invitation.gochiBillId,
        memo: {
          shopId: invitation.shopId,
        },
        // childFoodId: params.childFoodId,
        groupId: invitation.groupId,
        authenCode: authenCode,
        useAmount: useAmount ? Number(useAmount) : null
      },
      crfsToken
    );
    dispatch(action).then((response) => {
      if (!response.error && response.type === "POST_USE_GOCHI_SUCCESS") {
        setIsValidUsingGochi(true);
        let invitation_new = { ... invitation, status: 'REGISTERED' };
        history.push({
          pathname: "/shop/ticket-use-complete",
          state: {
            invitation: invitation_new,
            shopName,
            // couponId: response.payload.list[0].couponId,
            quantity,
          },
          search: urlComplete,
        });
      } else {
        if (
          response.payload &&
          response.payload.data &&
          response.payload.data.errorInfo &&
          response.payload.data.errorInfo.code ===
            "validation.errors.menuCombo.used.over.limit"
        ) {
          setOverLimitError(true);
          setOverLimitErrorMess(t("This ticket can only be used once a day"));
        } else if (
          response.payload &&
          response.payload.data &&
          response.payload.data.errorInfo &&
          response.payload.data.errorInfo.code ===
            "exception.errors.security.has.csrf.token"
        ) {
          setConfirmError(
            t("Please wait for a while as it is being processed")
          );
        } else if (
          response.payload &&
          response.payload.data &&
          response.payload.data.errorInfo &&
          response.payload.data.errorInfo.code ===
            "exception.errors.external.coupon.exception"
        ) {
          generateRandomString();
          setConfirmError(
            t("external coupon exception")
          );
        } else if (
          response.payload &&
          response.payload.data &&
          response.payload.data.errorInfo &&
          response.payload.data.errorInfo.code ===
            "authen.code.invalid.use"
        ) {
          setIsValidUsingGochi(false);
        }
      }
      setLoading(false);
    });
  }

  function closeErrorPopup() {
    setOverLimitError(false);
  }

  useEffect(() => {
    getAuthenCodeInfo();
    getInvitationInfo();
    generateRandomString();
    window.scrollTo(0,0);
  }, []);

  return (
    <>
      <div className="gochi">
        <Header title={t("pages.Confirm")} hasBack />
        <div className="mt-3">
          <ul className="progressbar align-center">
            <li className="step-choice">
              <strong>STEP 1</strong>
            </li>
            <li className="step-select active">
              <strong>STEP 2</strong>
            </li>
            <li className="step-confirm">
              <strong>STEP 3</strong>
            </li>
          </ul>
        </div>

        {confirmError && (
          <div className="text-red bold mt-2">{confirmError}</div>
        )}

        {isValidCode && isValidInvitation && isValidUsingGochi ? (
          <>
            <MenuInfo 
              showShopName 
              shopName={shopName || params.shopName} 
              menuLimitTotal={menuLimitTotal == 'true' ? 1 : 0} 
              hideAmount={menuLimitTotal == 'true' ? true : false}
            />

            {menuLimitTotal ? (
              <>
                <hr className="new-hr mt-2 mb-2" />
                <div className="d-flex justify-content-between align-items-center">
                  <div className="required-dot">
                    <div className="dot-red"></div>
                    <div className="dot-text-label fs-15">
                      {t("pages.amount_to_use")}
                    </div>
                  </div>

                  <div className="number-red">
                    <span className="fs-44">
                      {Number(useAmount).toLocaleString()}
                    </span>
                    円
                  </div>
                </div>
              </>
            ) : (
              <>
                <QuantityFreeDes />

                <UseQuantity 
                  quantity={quantity} 
                  menuLimitTotal={menuLimitTotal == 'true' ? 1 : 0} 
                  paramsMenu={{amount: useAmount}}
                />
              </>
            )}

            <OrderConfirmButton
              confirmAction={confirmAction}
              cancelAction={cancelAction}
            />
          </>
        ) : (
          <div
            className="align-center font-weight-bold fs-20"
            style={{
              color:'red',
              marginTop: '40px'
            }}
          >
            QRコードが無効になりました。
          </div>
        )}

        <Modal
          isOpen={overLimitError}
          onRequestClose={closeErrorPopup}
          style={customStyles}
          className="modail-content"
        >
          <div className="modal-use pt-0">
            <i onClick={closeErrorPopup} className="icon-close"></i>

            <div className="text-error-limit">{overLimitErrorMess}</div>
          </div>
        </Modal>
      </div>

      <LoadingOverlay loading={loading} />
    </>
  );
}

const mapStateToProps = (state) => ({
  invitation: state.gochis
});

export default connect(mapStateToProps)(
  withTranslation()(ShopTicketUseConfirm)
);
