import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

function OrderConfirmButton(props) {
  const { t } = props;
  const { invitation } = props;
  return (
    <div className="order-btn-container">
      <div className="text-center">
        <span className="label_18">
          {t("pages.Do you want to confirm this order?")}
        </span>
      </div>
      <div
        className="btn btn-use-55 bg-red"
        onClick={props.confirmAction}
        style={{opacity: props.disabledBtn ? '0.5' : 1}}
      >
        <span className="white_text">{t("pages.yes")} / confirm</span>
      </div>
      <div className="btn btn-use-55 bg-grey" onClick={props.cancelAction}>
        <span className="white_text">{t("pages.no")} / cancel</span>
      </div>
      <div
        className="text-red mt-2 fs-14 bold pl-5 text-center text-left"
        dangerouslySetInnerHTML={{
          __html: t("pages.The store staff should click the button", {
            interpolation: { escapeValue: false },
          }),
        }}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({ invitation: state.gochis });

export default connect(mapStateToProps)(withTranslation()(OrderConfirmButton));
