import React from "react";
import { DaigasNotification } from "../common/DaigasNotification";

function DaigasEndEvent(props) {
  return (
    <DaigasNotification
      title="受け取り期間は終了しました<br/>たくさんのご利用ありがとうございました"
      message=""
    />
  );
}

export default DaigasEndEvent;
