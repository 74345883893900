import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import queryString from 'query-string'
import QRCode from 'qrcode.react'

class AlipayQRCode extends Component {
  constructor (props) {
    super(props)
    let url = window.location.search
    let params = queryString.parse(url)
    this.state = {
      value: params.value,
      timeStamp: params.ts,
      time: Date.now(),
    }
  }

  componentDidMount () {
    this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000)
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  msToTime (duration) {
    let milliseconds = Math.floor((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60)

    minutes = (minutes < 10) ? '0' + minutes : minutes
    seconds = (seconds < 10) ? '0' + seconds : seconds

    return minutes + ':' + seconds;
  }

  render () {
    const { t } = this.props;
    const remainTime = 300000 - (this.state.time - this.state.timeStamp);
    return (
      <div className="content text-center" style={{marginTop: 60}}>
        {remainTime > 0 ? (
          <>
            <div className="fs-14 font-weight-bold space-pre-line text-center">
              {t('pages.Please use the Alipay app to scan this payment QR code')}
            </div>
            <div
              className="fs-14 font-weight-bold space-pre-line text-center">{t('pages.QR code expiration time')}{this.msToTime(remainTime)}</div>
            <QRCode renderAs="svg" value={this.state.value} className="mt-3" style={{ width: '345px', height: '345px' }}/>
          </>
        ) : (
          <div
            className="fs-14 font-weight-bold space-pre-line text-center">{t('pages.This payment QR code has expired')}</div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AlipayQRCode))