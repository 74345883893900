import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Gochirareru from "./Gochirareru";
import GochiBooking from "./booking/GochiBooking";
import BookingModal from "./booking/BookingModal";
import GochiNotFound from "./GochiNotFound";
import { getInvitations, getGochiChargeHistoryCharge, getGochiChargeHistoryUsed, GET_GOCHI_CHARGE_HISTORY_USED_SUCCESS } from "../../actions/Gochis";
import { getListShops } from "../../actions/Menus";
import { numberFormatter } from "../../libs/CommonUtil";
import moment from "moment";
import LoadingOverlay from "./LoadingOverlay";
import GiftCombo from "./GiftCombo";
import HamburgerMenu from "./HamburgerMenu";
import ListShop from "../common/ListShop";
import ThankYouMessage from "../common/ThankYouMessage";
import MenuInfo from "../common/MenuInfo";
import LimitTotalTicketUseHistoryModal from "../common/LimitTotalTicketUseHistoryModal";
import ChargeHistoryModal from "../common/ChargeHistoryModal";
import Header from "../common/Header";
import history from "../history";

// const NO_IMAGE_URL = "/images/gochi_noimg-ver4.jpg";
// const STEP_IMAGE_URL = "/images/steps.svg";

class Gift extends React.Component {
  geted = false;

  constructor(props) {
    super(props);

    this.state = {
      listShop: [],
      latitude: null,
      longitude: null,
      sort: "distance",
      hasMore: false,
      shopCount: 0,
      menuId: null,
      page: 1,
      keyword: null,
      textButtonSort: "現在地からの距離",
      loading: false,
      groupData: null,
      shopData: null,
      isQRScan: false,
      showListShopPopup: false,
      showBooking: false,
      openHistoryModal: false,
      listCoupons: [],
      listFirst: [],
      page: 1,
      pageLimit: 5,
      gochiChargeHistoryUsed: [],
      couponsCount: 0,
      listCharges: [],
      pageCharge: 1,
      pageChargeLimit: 5,
      openChargeHistoryModal: false,
      gochiChargeHistoryCharge: [],
      chargeCount: 0,
      loadingLoadMore: false,
      gochiErrorNew: false,
      gochiErrorTitleNew: "",
      gochiErrorContentNew: "",
      listCouponsGochiCanNotUse: [],
      listCouponsGochiCanNotUseAll: [],
      limitGochiCanNotUse: 3,
      pageGochiCanNotUse: 1,
      totalGochiCanNotUse: 0
    };
    const { dispatch } = props; 

    this.boundActionCreators = bindActionCreators(
      { ...getInvitations, ...getListShops },
      dispatch
    );
    this.getMenuPrice = this.getMenuPrice.bind(this);
    this.linkGoogleMap = this.linkGoogleMap.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
    this.loadMoreShop = this.loadMoreShop.bind(this);
    this.setKeyword = this.setKeyword.bind(this);
    this.searchKeyword = this.searchKeyword.bind(this);
    this.handleCloseListShopPopup = this.handleCloseListShopPopup.bind(this);
    this.handleOpenListShopPopup = this.handleOpenListShopPopup.bind(this);
    this.closeErrorPopup = this.closeErrorPopup.bind(this);
    this.handleCloseBookingPopup = this.handleCloseBookingPopup.bind(this);
    this.handleOpenBookingPopup = this.handleOpenBookingPopup.bind(this);
    this.onLoadMoreCoupon = this.onLoadMoreCoupon.bind(this);
    this.handleScanQRCode = this.handleScanQRCode.bind(this);
    this.onLoadMoreCharge = this.onLoadMoreCharge.bind(this);
    this.getHistoryCharge = this.getHistoryCharge.bind(this);
    this.getHistoryUsedGochiCharge = this.getHistoryUsedGochiCharge.bind(this);
    this.onLoadMoreCouponGochiCharge = this.onLoadMoreCouponGochiCharge.bind(this);
    this.handleCloseHistoryCouponsPopup = this.handleCloseHistoryCouponsPopup.bind(this);
    this.closeErrorNewPopup = this.closeErrorNewPopup.bind(this);
    this.onLoadMoreCouponGochiCanNotUse = this.onLoadMoreCouponGochiCanNotUse.bind(this);
  } 
  refreshPage() {
    window.location.reload(false);
  }

  getListShopsArr = ({ menuId, latitude, longitude, sort, keyword, page }) => {
    this.setState({
      loading: true,
    });
    let { dispatch } = this.props;
    try {
      let actionListShops = getListShops({
        menuId,
        latitude,
        longitude,
        sort,
        keyword,
        page,
      });
      dispatch(actionListShops).then((res) => {
        console.log(res);
        if (res.type === "GET_SHOPS_MENU_SUCCESS") {
          if (res.payload.list) {
            const listShop = [...this.state.listShop, ...res.payload.list];

            this.setState({
              listShop,
              shopCount: res.payload.count,
            });
            if (listShop.length < res.payload.count) {
              this.setState({
                hasMore: true,
              });
            } else {
              this.setState({
                hasMore: false,
              });
            }
          }
        }
      });
      this.setState({
        loading: false,
      });
    } catch (error) {
      console.log("error getListShops", error);
      this.setState({
        loading: false,
      });
    }
  };

  loadMoreShop() {
    try {
      this.getListShopsArr({
        menuId: this.state.menuId,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        sort: this.state.sort,
        keyword: this.state.keyword,
        page: this.state.page + 1,
      });
      this.setState({
        page: this.state.page + 1,
      });
    } catch (error) {
      console.log("error loadMoreShop", error);
    }
  }

  async searchKeyword() {
    await this.setState({
      page: 1,
    });
    await this.setState({
      listShop: []
    });
    try {
      this.getListShopsArr({
        menuId: this.state.menuId,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        sort: this.state.sort,
        keyword: this.state.keyword,
        page: this.state.page,
      });
    } catch (error) {
      console.log("error loadMoreShop", error);
    }
  }

  setKeyword(event) {
    var value = event.target.value;
    console.log(value);
    this.setState({ keyword: value });
  }

  closeErrorPopup(e) {
    this.setState({ gochiUseError: false });
    this.props.onCloseError && this.props.onCloseError();
    // window.location.reload();
  }

  closeErrorNewPopup() {
    this.setState({
      gochiErrorNew: false,
      gochiErrorTitleNew: "",
      gochiErrorContentNew: ""
    });
  }

  async getCoords() {
    var options = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };

    //this.setState({
    //  loading: true,
    //});

    var p1 = new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (success) => {
          return resolve(success);
        },
        (error) => {
          console.log("error========", error);
          if (error.code === 1) {
            return resolve(null);
          } else {
            return reject(error);
          }
        },
        options
      );
      setTimeout(() => {
        resolve(null);
      }, 11000);
    });
    var result = await p1;

    //this.setState({
    //  loading: false,
    //});

    return result;
  }

  handleCloseListShopPopup() {
    this.setState({
      showListShopPopup: false,
    });
  }

  handleOpenListShopPopup() {
    if (this.props.invitation.isAvailable === false) {
      this.setState({
        gochiUseError: true,
        gochiUseErrorMess: this.props.t("Ticket unavailable"),
      });
      return;
    }
    if (this.props.invitation.menu?.isETicket === 0) {
      this.setState({
        gochiUseError: true,
        gochiUseErrorMess: this.props.t("Prevent paper ticket"),
      });
      return;
    }
    if (this.props.invitation.menu?.menuChildType === 5 && this.props.invitation.menuChildType5?.amountCanUse === 0) {
      this.setState({
        gochiErrorNew: true,
        gochiErrorTitleNew: "任意の金額をチャージしてください。",
        gochiErrorContentNew: "現在、ご利用頂ける金額が0円のため、本チケットをお使い頂けません。マイページより、任意の金額をチャージしてください。",
      });
      return;
    }
    this.setState({
      showListShopPopup: true,
      isQRScan: false
    });
  }

  handleScanQRCode() {
    if (this.props.invitation.isAvailable === false) {
      this.setState({
        gochiUseError: true,
        gochiUseErrorMess: this.props.t("Ticket unavailable"),
      });
      return;
    }
    if (this.props.invitation.menu?.isETicket === 0) {
      this.setState({
        gochiUseError: true,
        gochiUseErrorMess: this.props.t("Prevent paper ticket"),
      });
      return;
    }
    if (this.props.invitation.menu?.menuChildType === 5 && this.props.invitation.menuChildType5?.amountCanUse === 0) {
      this.setState({
        gochiErrorNew: true,
        gochiErrorTitleNew: "利用可能金額をチャージしてください。",
        gochiErrorContentNew: "現在、ご利用頂ける金額が0円のため、本地域通貨チケットをお使い頂けません。決済方法、またはQRコードで利用可能金額をチャージして、お使い頂ける店舗様をお選びくださいますようお願いいたします。",
      });
      return;
    }
    this.setState({
      isQRScan: true,
      showListShopPopup: true,
    });
  }

  handleCloseBookingPopup() {
    this.setState({
      showBooking: false,
    });
  }

  handleOpenBookingPopup() {
    this.setState({
      showBooking: true,
    });
  }

  async componentDidMount() {
    let { dispatch } = this.props;
    this.setState({
      loading: true,
    });

    let latitude = null;
    let longitude = null;

    console.log("navigator.geolocation======", navigator.geolocation);

    if (navigator.geolocation) {
      try {
        const position = await this.getCoords();
        console.log("ggg=====", position);

        if (position && position.coords) {
          latitude = position.coords.latitude;
          longitude = position.coords.longitude;
        }
        //this.setState({
        //  loading: false,
        //});
      } catch (e) {
        //this.setState({
        //  loading: false,
        //});
        console.log("======================", e);
      }
    } else {
      //this.setState({
      //  loading: false,
      //});
    }

    this.setState({
      latitude: latitude,
      longitude: longitude,
    });

    var query = new URLSearchParams(this.props.location.search);
    var invitationCode = query.get("code");

    let action = getInvitations({ invitationCode: invitationCode });
    // dispatch(action);

    dispatch(action).then((response) => {
      console.log("response ===", response);
      if (!response.error && response.type === "GET_GOCHIS_SUCCESS") {
        this.setState({
          menuId: response.payload && response.payload.menuId,
        });

        if (
          response.payload &&
          response.payload.menu &&
          (response.payload.menu.menuChildType === 3 ||
            response.payload.menu.menuChildType === 4 ||
            response.payload.menu.menuChildType === 5)
        ) {
          this.getListShopsArr({
            menuId: response.payload.menuId,
            page: 1,
            latitude: latitude,
            longitude: longitude,
            sort: this.state.sort,
          });
        }

        if(response.payload?.menu?.menuChildType === 5) {
          return Promise.all([
            this.getHistoryCharge(response.payload.gochiBillId, this.state.pageCharge, this.state.pageChargeLimit),
            this.getHistoryUsedGochiCharge(response.payload.gochiBillId, this.state.page, this.state.pageLimit)
          ]);
        }
      } else {
        this.setState({
          error: true,
        });
        if (response?.payload?.status === 404) {
          const listAll = response?.payload?.data?.content && response?.payload?.data?.content.length > 0 ? response?.payload?.data?.content : [];
          const listFirst = listAll && listAll.length > 0 && listAll.filter((item,idx) => idx < this.state.limitGochiCanNotUse);
          this.setState({
            listCouponsGochiCanNotUseAll: listAll,
            listCouponsGochiCanNotUse: listFirst,
            totalGochiCanNotUse: listAll.length
          });
        }
      }
    }).then(() => {
      this.setState({
        loading: false,
      });
    });

    this.geted = true;
  }

  async getHistoryCharge(gochiBillId, page, limit){
    // get gochi charge history
    let { dispatch } = this.props;
    let actionHistory = getGochiChargeHistoryCharge({
        gochiBillId,
        page,
        limit,
      });

    await dispatch(actionHistory).then((response) => {
      console.log("response history charge gochi charge ===", response);
      if (!response.error && response.type === "GET_GOCHI_CHARGE_HISTORY_CHARGE_SUCCESS") {
        this.setState({
          gochiChargeHistoryCharge: this.state.gochiChargeHistoryCharge.concat(response.payload?.list),
          chargeCount: response.payload?.count
        });
      } else {
        this.setState({
          error: true,
        });
      }
    });
  }

  async getHistoryUsedGochiCharge(gochiBillId, page, limit){
    // get gochi charge history coupons
    let { dispatch } = this.props;
    let actionHistory = getGochiChargeHistoryUsed({
        gochiBillId,
        page,
        limit,
      });

    await dispatch(actionHistory).then((response) => {
      console.log("response history used gochi charge ===", response);
      if (!response.error && response.type === GET_GOCHI_CHARGE_HISTORY_USED_SUCCESS) {
        if(response.payload?.list) {
          this.setState({
            gochiChargeHistoryUsed: this.state.gochiChargeHistoryUsed.concat(response.payload?.list),
            couponsCount: response.payload?.count
          });
        }
      } else {
        this.setState({
          error: true,
        });
      }
    });
  }

  linkGoogleMap() {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=
			${this.props.invitation.shop.prefectures ? this.props.invitation.shop.prefectures + " " : ""}
			${this.props.invitation.shop.city ? this.props.invitation.shop.city + " " : ""}
			${this.props.invitation.shop.address ? this.props.invitation.shop.address + " " : ""}
			${this.props.invitation.shop.building}`
    );
  }
  getMenuPrice(invitation) {
    var price;
    if (invitation.menu) {
      price =
        numberFormatter(invitation.menu.includedTaxPrice) +
        " 円" +
        "(税抜" +
        numberFormatter(invitation.menu.excludedTaxPrice) +
        "円)";
    } else {
      price =
        numberFormatter(invitation.amount) +
        " 円（" +
        this.props.t("pages.Tax not included") +
        "）";
    }
    return price;
  }

  // setListCoupons(e, param) {
  //   this.setState({listCoupons: param});
  // }

  onLoadMoreCoupon() {
    if (this.props.invitation?.menu?.menuChildType === 5) {
      this.onLoadMoreCouponGochiCharge();
    } else {
      const arrTemp = this.state.listCoupons;
      const page = this.state.page;
      const listCouponAll = this.props.invitation?.couponLimitTotal;
      let arrPush = listCouponAll && 
                      listCouponAll.length > 0 && 
                        listCouponAll.filter((item,idx) => idx >= page*this.state.pageLimit && idx < (page+1) * this.state.pageLimit);
      arrPush.map((item) => {
        return arrTemp.push(item);
      }); 
      this.setState({page: page+1, listCoupons: arrTemp});
    }
  }

  async onLoadMoreCharge() {
    this.setState({
      loadingLoadMore: true,
    });
    const arrTemp = this.state.listCharges;
    const pageCharge = this.state.pageCharge;

    await this.getHistoryCharge(this.props.invitation.gochiBillId, pageCharge + 1, this.state.pageChargeLimit);

    let arrPush = this.state.gochiChargeHistoryCharge.length > 0 && 
                    this.state.gochiChargeHistoryCharge.filter((item,idx) => idx >= pageCharge*this.state.pageChargeLimit && idx < (pageCharge+1) * this.state.pageChargeLimit);
    arrPush.map((item) => {
      return arrTemp.push(item);
    }); 
    this.setState({pageCharge: pageCharge+1, listCharges: arrTemp, loadingLoadMore: false});
  }

  async onLoadMoreCouponGochiCharge() {
    this.setState({
      loadingLoadMore: true,
    });
    const arrTemp = this.state.listCoupons;
    const page = this.state.page;

    await this.getHistoryUsedGochiCharge(this.props.invitation.gochiBillId, page + 1, this.state.pageLimit);

    let arrPush = this.state.gochiChargeHistoryUsed.length > 0 && 
                    this.state.gochiChargeHistoryUsed.filter((item,idx) => idx >= page*this.state.pageLimit && idx < (page+1) * this.state.pageLimit);
    arrPush.map((item) => {
      return arrTemp.push(item);
    }); 
    this.setState({page: page+1, listCoupons: arrTemp, loadingLoadMore: false});
  }

  handleCloseHistoryCouponsPopup() {
    this.setState({openHistoryModal: false, page: 1});
    if(this.props.invitation?.menu?.menuChildType === 5) {
      this.setState({
        gochiChargeHistoryUsed: this.state.gochiChargeHistoryUsed.slice(0, this.state.pageLimit)
      });
    }
  }

  onLoadMoreCouponGochiCanNotUse() {
    const arrTemp = this.state.listCouponsGochiCanNotUse;
    const page = this.state.pageGochiCanNotUse;
    const listCouponAll = this.state.listCouponsGochiCanNotUseAll;
    let arrPush = listCouponAll && 
                    listCouponAll.length > 0 && 
                      listCouponAll.filter((item,idx) => idx >= page * this.state.limitGochiCanNotUse && idx < (page + 1) * this.state.limitGochiCanNotUse);
    arrPush.map((item) => {
      return arrTemp.push(item);
    }); 
    this.setState({
      pageGochiCanNotUse: page + 1,
      listCouponsGochiCanNotUse: arrTemp
    });
  }

  render() {

    console.log('LIST SHOPS: ', this.state.listShop);
    let { invitation } = this.props;
    const { t } = this.props;
    const listCouponAll = this.props.invitation?.menu?.menuChildType === 5 ? this.state.gochiChargeHistoryUsed : this.props.invitation?.couponLimitTotal;
    const listChargeAll = this.state.gochiChargeHistoryCharge;
    var query = new URLSearchParams(this.props.location.search);
    var invitationCode = query.get("code");

    console.log("invitation", invitation);

    const listHisCouponLimitTotalFist = listCouponAll && 
              listCouponAll.length > 0 && listCouponAll.filter((item,idx) => idx < this.state.pageLimit);
    
    const listHisGochiChargeFist = listChargeAll && 
              listChargeAll.length > 0 && listChargeAll.filter((item,idx) => idx < this.state.pageChargeLimit);
    
    console.log('listHisCouponLimitTotalFist', listHisCouponLimitTotalFist);

    const paramsMenu = {
      menuLimitTotal: invitation.menuLimitTotal,
      remainAmount: invitation.remainAmount,
      displayAmount: invitation.displayAmount
    };

    if (
      this.geted &&
      (invitation.gochiBillId === undefined || invitation.gochiBillId === 0) &&
      invitation.status &&
      invitation.status.status === 404
    ) {
      return (
        <GochiNotFound
          listHistoryCouponUsed = {this.state.listCouponsGochiCanNotUse}
          page = {this.state.pageGochiCanNotUse}
          limit = {this.state.limitGochiCanNotUse}
          total = {this.state.totalGochiCanNotUse}
          onLoadMoreCouponGochiCanNotUse = {this.onLoadMoreCouponGochiCanNotUse}
        />
      );

    } else {
      if (invitation.menu && invitation.menu.menuCombo === 1) {
        return (
          <>
          <GiftCombo
            invitation={this.invitation}
            loadMoreShop={this.loadMoreShop}
            listShop={this.state.listShop}
            shopCount={this.state.shopCount}
            loading={this.state.loading}
            hasMore={this.state.hasMore}
            menuId={this.state.menuId}
            setKeyword={this.setKeyword}
            keyword={this.state.keyword}
            searchKeyword={this.searchKeyword}
            invitationCode={invitationCode}
            handleOpenListShopPopup={this.handleOpenListShopPopup}
            handleCloseListShopPopup={this.handleCloseListShopPopup}
            showListShopPopup={this.state.showListShopPopup}
            linkGoogleMap={this.linkGoogleMap}
            handleOpenBookingPopup={this.handleOpenBookingPopup}
            handleScanQRCode={this.handleScanQRCode}
            isQRScan={this.state.isQRScan}
          />
          <BookingModal
              handleOpenBookingPopup={this.handleOpenBookingPopup}
              handleCloseBookingPopup={this.handleCloseBookingPopup}
              showBooking={this.state.showBooking}
              invitation={invitation}
              invitationCode={invitationCode}
            />
			<Modal
              show={this.state.gochiUseError}
              onHide={this.closeErrorPopup}
              size="sm"
              centered
            >
              <Modal.Header
                className="rounded-bottom align-items-start"
                closeLabel={false}
              >
                <div className="text-error-limit">
                  {this.state.gochiUseErrorMess}
                </div>
                <i onClick={this.closeErrorPopup} className="btn-close"></i>
              </Modal.Header>
            </Modal>
          </>
        );
      } else {
        return (
          <div className="gochi">
            {this.geted === false ? (
              <></>
            ) : (
              invitation.error &&
              invitation.status &&
              invitation.status.status !== 404 &&
              invitation.status.data?.errorInfo?.code !==
                "exception.errors.security.has.csrf.token" && (
                <div className="loading-overlay">
                  <div className="popup-noti">
                    <div className="message-error">
                      {t("pages.Unexpected error")}
                    </div>
                    <button
                      className="btn btn-green-38 btn-reload"
                      onClick={this.refreshPage}
                    >
                      {t("pages.Reload")}
                    </button>
                  </div>
                </div>
              )
            )}
            <div id="menu">
              <Header title={t("pages.The ticket has arrived")}/>
              {invitation.shop && invitation.shop.status === "CLOSED" && (
                <div className="shop-closed">
                  {t("pages.This shop has closed")}
                </div>
              )}
              {invitation.menu && invitation.menu.isETicket === 0 && 
                (<div className="mb-3 paper-ticket-note">{t("pages.paper ticket note")}</div>)}
              {invitation && <ThankYouMessage invitation={invitation} />}
              <MenuInfo 
                invitation={invitation} 
                menuLimitTotal={invitation.menuLimitTotal} 
                paramsMenu={paramsMenu}
                showShopName
                showPrice
              />
              {invitation.menu && (
                <div className="menu-detail menu-detail-new pl-0">
                  <div>
                    <div className="fs-13 break-line">
                      {invitation.menu.description}
                    </div>
                  </div>
                </div>
              )}
              
              <div className="menu-detail menu-detail-new pl-0">
                {invitation.groupId ? (
                  <div className="lst-shop-cmm">
                    <hr className="new-hr mt-1 mb-3" />
                    {(invitation?.menuLimitTotal === 1 || !invitation?.menuChildType5)  && (
                      <div className="d-flex justify-content-between align-items-center mb-24px">
                        <div className="new-menu-name cursor-pointer fs-14 underline ml-auto" 
                          onClick={() => { 
                            this.setState({
                              openHistoryModal: true,
                              listCoupons: listHisCouponLimitTotalFist
                            });  
                          }}
                        >
                          <FontAwesomeIcon icon={faCaretRight} size="sm" style={{marginRight: 5}} />
                          {t('pages.usage history')}
                        </div>
                        {invitation?.menuChildType5 && (
                          <div className="new-menu-name cursor-pointer fs-14 underline ml-54" 
                            onClick={() => { 
                              this.setState({
                                openChargeHistoryModal: true,
                                listCharges: listHisGochiChargeFist
                              });  
                            }}
                          >
                          <FontAwesomeIcon icon={faCaretRight} size="sm" style={{marginRight: 5}} />
                          {t('pages.charge history')}
                        </div>
                        )}
                      </div>
                    )}
                    {invitation.menu?.flagScanQr !== 1 && (
                      <div
                        className="btn btn-red flex_center btn-shop bg-red"
                        onClick={() => {
                          this.handleOpenListShopPopup();
                        }}
                      >
                        {t(
                          "pages.List of stores"
                        )}
                      </div>
                    )}
                    {invitation.menu?.flagScanQr === 1 && (
                      <div>
                        <div
                          className="btn btn-red flex_center btn-shop bg-red"
                          onClick={() => {
                            this.handleScanQRCode();
                          }}
                        >
                          {t(
                            "pages.Scan the QR code of the store you use"
                          )}
                        </div>
                        <div className="text-can-not-scan-qr">
                          <span dangerouslySetInnerHTML={{ __html: t("pages.Can not open camera to scan the QR code before")}}></span>
                          <span onClick={() => { this.handleOpenListShopPopup(); }} className="text-link-scan-qr" dangerouslySetInnerHTML={{ __html: t("pages.Click here to open list shop")}}></span>
                          <span dangerouslySetInnerHTML={{ __html: t("pages.Can not open camera to scan the QR code after")}}></span>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    {invitation.shop && (
                      <div>
                        {/* <div className="divider-dashed"></div> */}
                        <hr className="new-hr mt-1 mb-3" />
                        {invitation?.menuLimitTotal === 1 && (
                          <div className="d-flex justify-content-between align-items-center"> 
                            <div className="new-menu-name cursor-pointer fs-14 underline ml-auto" 
                              onClick={() => { 
                                this.setState({
                                  openHistoryModal: true,
                                  listCoupons: listHisCouponLimitTotalFist
                                });  
                              }}
                            >
                              <FontAwesomeIcon icon={faCaretRight} size="sm" style={{marginRight: 5}} />
                              {t('pages.usage history')}
                            </div>
                          </div>
                        )}
                        {invitation?.menuLimitTotal !== 1 && (
                          <>
                            <div className="shop-name local-shop pl-0 pb-0">
                              {/* <i className="icon-location"></i>  */}
                              {invitation.shop.shopName}
                            </div>
                            <div className="fs-14 shop-address mt-1">
                              {invitation.shop.prefectures
                                ? invitation.shop.prefectures + " "
                                : ""}
                              {invitation.shop.city
                                ? invitation.shop.city + " "
                                : ""}
                              {invitation.shop.address
                                ? invitation.shop.address + " "
                                : ""}
                              {invitation.shop.building}
                            </div>
                          </>
                        )}
                        <div className="fs-14 text-green mt-2">
                          {invitation.shop.showPhone === 1 &&
                            invitation.shop.phone}
                        </div>
                        {invitation.shop.latitude && invitation.shop.longitude && (
                          <div
                            className="btn btn-green-38 flex_center new-btn"
                            onClick={this.linkGoogleMap}
                          >
                            <span>{t("pages.Click here for MAP")}</span>
                          </div>
                        )}
                        <GochiBooking
                          invitationGochi={invitation}
                          invitationCode={invitationCode}
                          handleOpenBookingPopup={this.handleOpenBookingPopup}
                        />
                      </div>
                    )}
                  </>
                )}

                {!(invitation.menu?.menuChildType === 5 && invitation.menuChildType5.amountCanUse === 0) && (
                  <div>
                    <div className="fs-16 bold mt-3">
                      {t("pages.Ticket expiration date")}
                    </div>

                    <div className="message-row d-flex align-items mt-2">
                      <div className="expired-date fs-24">
                        {invitation?.menuChildType5 && invitation?.menuChildType5.walletNearestExpired ? 
                          moment(invitation?.menuChildType5?.walletNearestExpired).format(
                            `YYYY${t("pages.year")}MM${t("pages.month")}DD${t(
                              "pages.day"
                            )}`
                          )
                        : invitation.gochiExpired &&
                          moment(invitation.gochiExpired).format(
                            `YYYY${t("pages.year")}MM${t("pages.month")}DD${t(
                              "pages.day"
                            )}`
                          )}
                        {t("pages.Until")}
                      </div>
                    </div>
                  </div>
                )}

                {invitation.menu?.menuChildType === 5 && invitation.menuChildType5.amountCanUse !== 0 && (
                  <div className="text-menu-type-5 mb-2 fs-13">
                    ※チケット使用期限日は最も早く期限日を迎える日時を表示しています。詳しくは<a className="text-decoration-none" href="https://user-guide.gochimeshi.com/communitycurrency_deadline/ "target="_blank" rel="noopener noreferrer">こちら</a>
                  </div>
                )}

                <p className="fs-12">
                  ※使用期限が過ぎたものは無効となります。規約上、返金等はできませんのでご注意ください。
                </p>
              </div>
            </div>

            {(invitation.gochiType === "PRICE" ||
              (invitation.menu &&
                invitation.menu.menuChildType !== 3 &&
                invitation.menu.menuChildType !== 4 &&
                invitation.menu.menuChildType !== 5)) && (
              <>
                <Gochirareru
                  invitationGochi={invitation}
                  invitationCode={invitationCode}
                />
                <div className="text-btm">
                  {t(
                    "pages.When you tap Use this ticket a confirmation screen for the shop will be displayed"
                  )}
                </div>
              </>
            )}

            <div className="mt-2 font-weight-bold">
              ※
              <span className="user-name">
                {(invitation.gochiRegister &&
                  invitation.gochiRegister.displayName) ||
                  "GUEST"}
              </span>
              {t("pages.We have received the payment")}
            </div>

            <div className="mb-3">
              <div className="explain-icon ic-1 align-center">
                <div className="explain-note">{t("pages.Explain IC 1")}</div>
              </div>
              <div className="explain-icon ic-2 align-center">
                <div className="explain-note">{t("pages.Explain IC 2")}</div>
              </div>
              <div className="explain-icon ic-3 align-center">
                <div className="explain-note">{t("pages.Explain IC 3")}</div>
              </div>
            </div>

            <div className="gochi-stamp-combo mt-3">
              <div className="gochi-stamp-combo-info border-radius-0">
                <div>
                  <div className="clr-000000 fs-18 font-weight-bold">領収書番号 : {invitation.gochiBillNo}</div>
                  <div><span className="fs-12 clr-000000">チケット利用問合先 : </span><a href="tel:050-5536-3317" className="fs-12 text-red clr-000000">050-5536-3317</a></div>
                  <div className="text-red">※サポートセンターへの問い合わせ電話です。ご予約は、直接店舗様にお願いいたします。</div>
                  <div className="fs-12 clr-000000">平日（月～金） 10:00～18:00／土曜日 10:00～15:00</div>
                  <div className="fs-12 clr-000000">※お休み：日曜日・祝日 ・長期休暇・お盆休み・年末年始</div>
                </div>
              </div>
            </div>

            <LoadingOverlay loading={this.state.loading} />

            <ListShop
              hasMore={this.state.hasMore}
              loadMoreShop={this.loadMoreShop}
              listShop={this.state.listShop}
              showListShopPopup={this.state.showListShopPopup}
              handleCloseListShopPopup={this.handleCloseListShopPopup}
              invitation={invitation}
              setKeyword={this.setKeyword}
              keyword={this.state.keyword}
              searchKeyword={this.searchKeyword}
              invitationCode={invitationCode}
              shopCount={this.state.shopCount}
              isQRScan={this.state.isQRScan}
            />

            <Modal
              show={this.state.gochiUseError}
              onHide={this.closeErrorPopup}
              size="sm"
              centered
            >
              <Modal.Header
                className="rounded-bottom align-items-start"
                closeLabel={false}
              >
                <div className="text-error-limit">
                  {this.state.gochiUseErrorMess}
                </div>
                <i onClick={this.closeErrorPopup} className="btn-close"></i>
              </Modal.Header>
            </Modal>
            <BookingModal
              handleOpenBookingPopup={this.handleOpenBookingPopup}
              handleCloseBookingPopup={this.handleCloseBookingPopup}
              showBooking={this.state.showBooking}
              invitation={invitation}
              invitationCode={invitationCode}
            />
            <LimitTotalTicketUseHistoryModal 
              show={this.state.openHistoryModal} 
              onHide={this.handleCloseHistoryCouponsPopup}  
              listCoupons={this.state.listCoupons}
              setListCoupons={this.setListCoupons}
              pageLimit={this.state.pageLimit}
              onLoadMoreCoupon={this.onLoadMoreCoupon}
              page={this.state.page}
              listCouponAll={listCouponAll}
              couponsCount={invitation?.menu?.menuChildType === 5 ? this.state.couponsCount : (listCouponAll?.length || 0)}
              loadingLoadMore={this.state.loadingLoadMore}
            />
            <ChargeHistoryModal 
              show={this.state.openChargeHistoryModal} 
              onHide={() => this.setState({
                openChargeHistoryModal: false, 
                pageCharge: 1,
                gochiChargeHistoryCharge: this.state.gochiChargeHistoryCharge.slice(0, this.state.pageChargeLimit)
              })}  
              listCharges={this.state.listCharges}
              pageLimit={this.state.pageChargeLimit}
              onLoadMoreCharge={this.onLoadMoreCharge}
              page={this.state.pageCharge}
              listChargeAll={listChargeAll}
              chargeCount={this.state.chargeCount}
              loadingLoadMore={this.state.loadingLoadMore}
            />
            <Modal
              show={this.state.gochiErrorNew}
              onHide={this.closeErrorNewPopup}
              size="sm"x
              centered
              dialogClassName="modal-error-new"
            >
              <Modal.Header
                className="rounded-bottom align-items-start modal-header-error-new"
                closeLabel={false}
              >
                <i onClick={this.closeErrorNewPopup} className="btn-close"></i>
              </Modal.Header>
              <Modal.Body
                className="modal-body-error-new"
              >
                <div className="modal-title-error-new">
                  <span>{this.state.gochiErrorTitleNew}</span>
                </div>
                <div className="modal-box-content-error-new">
                  <div className="modal-content-error-new">{this.state.gochiErrorContentNew}</div>
                  <div>
                    {(invitation.menu?.menuChildType === 5 && invitation.menuChildType5?.amountCanUse === 0) && (
                      <button className="btn-close-modal-error-new" onClick={() => {window.location.href = process.env.REACT_APP_GIFT_SITE_URL + "/mypage/mygochi_charge"}}>
                        マイページに戻る
                      </button>
                    )}
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        );
      }
    }
  }
}
export default connect((state) => ({ invitation: state.gochis }))(
  withTranslation()(Gift)
);
