import React from 'react';
import Card from 'react-bootstrap/Card';
import { get } from 'lodash';
import Button from 'react-bootstrap/Button';

function UploadImageForm(props) {
    const { label, setFile, previewImage, setPreviewImage, isOnlyImage, indexImg, removeImage } = props;

    const imagePreview = (preview, indexImg) => {
      const reader = new FileReader();
      reader.readAsDataURL(preview);
      reader.onloadend = () => {
        setPreviewImage(reader.result, indexImg);
      };
    };

    const handleOnChange = e => {
      const newFile = e.target.files[0];
      console.log('newFile', newFile);
      if (isOnlyImage) {
        const mineFile = get(newFile, 'type', '');
        if (mineFile.search('image/') >= 0) {
          setFile(newFile, indexImg);
          imagePreview(newFile, indexImg);
        } else {
          alert('画像の形式が正しくありま線。');
        }
      } else {
        setFile(newFile, indexImg);
        imagePreview(newFile, indexImg);
      }
      e.target.value = null;
    };

    return (
      <div className="uploadFile">
        <div
          id={label}
          margin="dense"
          className="inputLabel"
        > 
          <label class="btn btn-default btn-file">
            画像アップロード
          <input
            id={label}
            label="画像アップロード"
            type="file"
            accept="image/*"
            className="inputImage"
            onChange={e => handleOnChange(e)}
          />
          </label>
          <Button 
            onClick={() => removeImage(indexImg)} 
            variant="outline-danger"
            className="btn-delete">
            クリア
          </Button>
        </div>
        {previewImage && (
          <Card className="image">
            <Card.Img variant="top" src={previewImage} />
          </Card>
        )}
      </div>
    );
  }

export default UploadImageForm;