import React from 'react';
import Validator from '../../utils/validator';
// import {CopyToClipboard} from 'react-copy-to-clipboard';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchInviteUrl } from "../../actions/Gochis";
import ModalAlert from "./ModalAlert";
import { withTranslation } from "react-i18next";
import HamburgerMenu from './HamburgerMenu';

class GochiCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gochiCode: '',
      // gochiBillNo: '',
      // email: '',
      // code: '',
      // copied: false,
      errors: {},
      errorResponse: false,
      successCodeResponse: false,
      errorUrlResponse: false,
      showUrl: false,
      messageError: this.props.t('There is no matching gochis')
    };

    const { dispatch } = props;

    const rules = [
      {
        field: 'gochiCode',
        method: 'isEmpty',
        validWhen: false,
        message: this.props.t('pages.This field is required'),
      },
      {
        field: 'gochiCode',
        method: 'isLength',
        args: [{min: 5}],
        validWhen: true,
        message: this.props.t('pages.Please enter within 5 digits'),
      },
      {
        field: 'gochiCode',
        method: 'isLength',
        args: [{max: 5}],
        validWhen: true,
        message: this.props.t('pages.Please enter within 5 digits'),
      }
    ];
    this.validator = new Validator(rules);
    this.boundActionCreators = bindActionCreators({ ...fetchInviteUrl }, dispatch);
    this.closePop = this.closePop.bind(this);
  }

  closePop = () => {
    this.setState({errorResponse: false});
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmitCode = () => {
    let valid = this.validator.validate(this.state);
    this.setState({
      errors: valid,
    });
    if (Object.keys(valid).length === 0) {
      let { dispatch } = this.props;

      let action = fetchInviteUrl({
        gochiCode: this.state.gochiCode
      });

      dispatch(action).then(response => {
        console.log('data ===', response.payload);
        if(response.payload.status && response.payload.status !== 200) {
          this.setState({
            errorResponse: true
          });
        } else {
          if(response.payload.inviteUrl && response.payload.inviteUrl !== '') {
            window.location = `${response.payload.inviteUrl}`;
          }
        }
      });
    }
  };

  render() {
    const { t } = this.props;
    const {errors} = this.state;
    console.log('props', this.props);

    return (
      <div id="gochiUrl">
        {this.state.errorResponse && 
          <ModalAlert message={this.state.messageError} func={this.closePop} />
        }

        <div className="navBar-gochi-code position-relative">
          {t('pages.Gochi code screen')}
          <div className="">
            <HamburgerMenu />
          </div>
        </div>
        <div className="gochi-code-contentBody">
          <img
            src="/images/gochiCode_logo@2x.png"
            alt=""
            width="70%"
          />
          <div
            className="des-gochi-code"
            dangerouslySetInnerHTML={{__html: t('pages.Please send your love through meals', {interpolation: {escapeValue: false}})}}
          />
        </div>
        <div className="gochi-code-form">
          <form>
            <div>
              <div className="form-group d-inline-block">
                <p>{t('pages.Gochi code label')}<span className="text-red">*</span></p>
                <input className={errors.gochiCode ? 'error-input form-input' : 'form-input'} type="text" name="gochiCode" placeholder={t('pages.5 alphanumeric characters')} value={this.state.gochiCode} onChange={this.handleInput}/>
                {errors.gochiCode && <div className="validation" style={{display: 'block'}}>{errors.gochiCode}</div>}
              </div>
            </div>
            <button type="button" className="btn btn-green-new" onClick={this.handleSubmitCode}>{t('pages.Send gochi code')}</button>
          </form>
          <div
            className="des-gochi-code"
            dangerouslySetInnerHTML={{__html: t('pages.Write on the card, please enter the 5-digit Gochi code', {interpolation: {escapeValue: false}})}}
          />
          <div
            className="des-gochi-code"
            dangerouslySetInnerHTML={{__html: t('pages.Input gochi code to display the available ticket can use', {interpolation: {escapeValue: false}})}}
          />
        </div>
      </div>
    );
  }
}
export default connect(state => ({ code: state.gochis.code, inviteUrl: state.gochis.inviteUrl }))(withTranslation()(GochiCode));
