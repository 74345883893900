import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import MenuInfo from "../common/MenuInfo";
import HamburgerMenu from "./HamburgerMenu";
import ReactModal from "react-modal";
import queryString from "query-string";
import { Modal, Button } from "react-bootstrap";
import {
  postReviewMessage,
  getReviewMessage,
  POST_REVIEW_MESSAGE_SUCCESS,
  GET_REVIEW_MESSAGE_SUCCESS,
  getInvitations,
  getCompleteSession
} from "../../actions/Gochis";
import { getCompleteSessionData } from "../../libs/apis";
import history from "../history";
import GiftBoxButton from "../common/GiftBoxButton";
import LoadingOverlay from "./LoadingOverlay";
import Moment from "react-moment";
import Header from "../common/Header";
import Barcode from "../common/Barcode";

const customStyles = {
  overlay: {
    background: "#444444",
    padding: 15,
  },
};

function TicketUseComplete(props) {
  const { t } = props;
  const { dispatch } = props;
  const params = queryString.parse(window.location.search);
  const action = getInvitations({ invitationCode: params.code });

  const location = props.location; 
  const invitationState = location.state && location.state.invitation;
  let invitationInit = props.invitation?.gochiBillId === 0 ? invitationState : props.invitation;
  const sushiroQrImage = location.state?.sushiroQrImage;

  console.log('invitation comple', invitationInit);

  const shopName = (invitationInit && invitationInit.shopName) || params.shopName;
  const useAmount = params.useAmount;
  
  // const couponId = ;
  const usedQuantity = location.state && location.state.quantity;
  const gochiTipUrl = location.state && location.state.gochiTipUrl;
  const barCodeYoshinoya = location.state && location.state.barCodeYoshinoya;
  const [showReviewMessage, setShowReviewMessage] = useState(true);
  const [sentMessage, setSentMessage] = useState("");
  const [showSendMessageConfirm, setShowSendMessageConfirm] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [couponId, setCouponId] = useState(location.state && location.state.couponId);
  const [loading, setLoading] = useState(false);
  const [showErrorPopup, setshowErrorPopup] = useState(false);
  // const [invitation, setInvitation] = useState(invitationInit);
  const [invitationReload, setInvitationReload] = useState({...invitationInit});

  const invitation = location.state && location.state.invitation;

  const [showPaperTicketAlert, setShowPaperTicketAlert] = useState(() => (invitation.menu && invitation.menu.isETicket === 0));
  
  console.log('invitation?.remainAmount', invitation?.remainAmount, useAmount);
  useEffect(() => {
    dispatch(action).then((response) => {
      if (response.type === 'GET_GOCHIS_SUCCESS') {
        getReviewedMessage(location.state && location.state.couponId);
      }
    });
    // getInvitationInfo();
    console.log('couponId', couponId);
    // getReviewedMessage();
    window.scrollTo(0,0);
    if (params.merchantPaymentId) {
      completeSession();
    }
  }, []);

  // const action = getInvitations({ invitationCode: params.code });
  async function completeSession() { 
    setLoading(true);
    if (params.merchantPaymentId) {
      let result;
      // If payment gateway is Onepay, re-call API complete until response status is not 404 or timeout (5 * 10 = 50 seconds)
      for (let i = 0; i < 5; i++) {
        result = await dispatch(getCompleteSession(params.merchantPaymentId)).then((response) => {
          return response;
        });
        if (result?.payload?.status !== 404) break;
        await new Promise(r => setTimeout(r, 10000));
      }

      if (result.type === "GET_COMPLETE_SESSION_SUCCESS") { 
        setLoading(false);
        if (invitationInit === undefined) {
          const invitaReload = {
            menuLimitTotal: 1,
            menu: {
              menuName: result.payload.menuName
            },
            gochiBillImage: [
              { image: result.payload.menuImage }
            ],
            shop: {
              shopName: result.payload.shopName
            },
            gochiType: 'MENU'
          }

          setInvitationReload({ ...invitation, ...invitaReload});
          getReviewedMessage(result.payload.couponMasterId);
        }
      } else if (result.payload?.status === 402) {
        
        let messageErr;
        if (result.payload?.data) {
          if (result.payload?.data.stripe_code === 'expired_card') {
            messageErr = t('pages.error create gochi using stripe incase expired card');
          } else if (result.payload?.data.stripe_code === 'amount_too_small') {
            messageErr = t('pages.error create gochi using stripe incase amount to small');
          } else if (result.payload?.data.stripe_code === 'insufficient_funds') {
            messageErr = t('pages.error create gochi using stripe incase insufficient funds');
          } else if (result.payload?.data.stripe_code === 'card_velocity_exceeded') {
            messageErr = t('pages.error create gochi using stripe incase card velocity exceeded');
          } else {
            messageErr = invitation.subscription === 1 
            ? t('pages.error subsription failed') 
            : t('pages.error create gochi user');
          }
          setErrorMessage(messageErr);
          setshowErrorPopup(true);
          setLoading(false);
        } 
      } else {
        if (result.payload?.status === 404 || result.payload?.status === 400 || result.payload?.status === 500) {
          setErrorMessage(t('pages.please try reloading with the retry button'));
          setshowErrorPopup(true);
          setLoading(false);
        }
        if (result.payload?.status === 504) {
          setErrorMessage(t('pages.error create gochi with guest'));
          setshowErrorPopup(true);
          setLoading(false);
        }
      }  
    }
  }

  function onOpenSendMessageConfirm() {
    if (message) setShowSendMessageConfirm(true);
  }

  function onOpenSendMessageConfirm() {
    if (message) setShowSendMessageConfirm(true);
  }

  function viewTicket() {
    history.push({
      pathname: "/gift",
      search: `?code=${params.code}`,
    });
  }

  function sendReviewMessage() {
    setShowSendMessageConfirm(false);
    dispatch(postReviewMessage(couponId, message)).then((response) => {
      console.log("data ===", response);
      if (response.type === POST_REVIEW_MESSAGE_SUCCESS) {
        setShowReviewMessage(false);
        setSentMessage(message);
      } else {
      }
    });
  }

  function getReviewedMessage(couponIdd) {
    dispatch(getReviewMessage(couponIdd)).then((response) => {
      console.log("data ===", response);
      if (response.type === GET_REVIEW_MESSAGE_SUCCESS) {
        if (response.payload.shopMessage) {
          setSentMessage(response.payload.shopMessage);
          setShowReviewMessage(false);
        }
      } else {
      }
    });
  }

  function goToMenuDetail() {
    if (invitation.shopId) {
      window.location.href = process.env.REACT_APP_GIFT_SITE_URL + `/shopdetails/${invitation.shopId}/menu/${invitation?.menuId}`;
    } else {
      window.location.href = process.env.REACT_APP_GIFT_SITE_URL + `/shopdetails/group-menu/menu/${invitation?.menuId}`;
    }
  }

  const couponLeft = invitation && invitation.gochiCount - Number(usedQuantity);

  function closeErrorPopup() {
    setshowErrorPopup(false);
  }

  function retryAction() {
    window.location.reload();
  } 

  console.log('invitaition reload', invitationReload);
  const menuComboDetail = invitation.menu && invitation.menu.menuComboDetail
  let transferForShopAmount = 0;
  let priceForOneCoupon = 0;
  if (invitation.menu?.menuCombo) {
    priceForOneCoupon = invitation.menu?.displayAmount
        ? invitation.menu?.displayAmount/menuComboDetail.quantity
        : invitation.menu &&
        invitation.menu.menuComboDetail &&
          invitation.menu.menuComboDetail.includedTaxPriceCombo;
    transferForShopAmount = priceForOneCoupon * usedQuantity;
  } else if (invitation?.menuLimitTotal) {
    transferForShopAmount = Number(useAmount);
    priceForOneCoupon = Number(useAmount);
  } else {
    transferForShopAmount = invitation.gochiType === "MENU"
    ? (invitation.menu.displayAmount
        ? invitation.menu.displayAmount
        : invitation.menu.includedTaxPrice) * usedQuantity
    : ((invitation.amount + invitation.taxAmount) /
        invitation.couponCount) *
        usedQuantity;
    priceForOneCoupon = invitation.gochiType === "MENU"
    ? (invitation.menu.displayAmount
        ? invitation.menu.displayAmount
        : invitation.menu.includedTaxPrice)
    : ((invitation.amount + invitation.taxAmount) /
        invitation.couponCount);
  }
  const isShowPrice = invitation.menu?.showPrice !== 0;

  return (
    <>
      <audio autoPlay src="/audio/gochi_jingle_master_final.mp3" />
      <div className="gochi">
        <Header title="お支払い完了" />
        {errorMessage && (
          <Modal
            show={showErrorPopup}
            onHide={closeErrorPopup}
            size="sm"
            className="modal-error"
          >

            <Modal.Body className="space-pre-line" style={{minHeight: 'unset', fontSize: 14}}>{errorMessage}</Modal.Body>
            <Modal.Footer className="footer-popup d-flex justify-content-center">
              <Button variant="primary" onClick={retryAction} className="w-50p-left">
                { t('pages.retry') }
              </Button>
              <Button variant="secondary" onClick={closeErrorPopup} className="w-50p-right">
              { t('pages.Cancel') }
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {errorMessage && (
          <div className="text-red bold mt-2">
            {errorMessage}
          </div>
        )}
      <div className="add-padding">
        <div className="fs-22 font-weight-bold my-5 text-center">
          お支払い完了
        </div>

          <div className="used-price-box mt-3">
            <div className="top">
              {shopName}
            </div>
            <div className="mid mt-3 mb-2">
              {isShowPrice ? (
                <>
                  <span
                    className={
                      transferForShopAmount < 1000000
                        ? 'fs-72 line-height-72'
                        : 'fs-48 line-height-48'
                    }
                  >
                    {transferForShopAmount &&
                      transferForShopAmount.toLocaleString()}
                  </span>
                  <span className="fs-20 line-height-20">円</span>
                </>
              ) : (
                <div className="fs-20 fw-bold">
                  {invitation?.menu?.menuName}
                </div>
              )}
            </div>
            <Moment
              className="bot"
              interval={1000}
              format="YYYY.MM.DD HH:mm:ss"
              local
            />
          </div>

          <div className="pt-2">
            {barCodeYoshinoya && (
              <Barcode value={barCodeYoshinoya} options={{ format: 'codabar' }} className="barcode" />
            )}
          </div>

          <div className="px-content-text">
            {isShowPrice ? (
              <>
                <div className="line-break mt-2 fs-18 font-weight-bold">
                  {invitation?.menu?.menuName}
                </div>
                {invitation?.menuLimitTotal !== 1 && <div className="fs-18 font-weight-medium mt-2 line-height-18">
                  <span>単価: </span>
                  <span className="font-weight-bold">
                    {priceForOneCoupon && priceForOneCoupon.toLocaleString()}
                  </span>
                  <span className="fs-14">円</span>
                  <span className="ml-4">個数: </span>
                  <span className="font-weight-bold">{
                    `${usedQuantity}`
                  }</span>
                  <span className="fs-14">個</span>
                </div>}
              </>
            ) : (
              invitation?.menuLimitTotal !== 1 && usedQuantity > 1 && (
                <div className="fs-18 mt-2 font-weight-medium line-height-18">
                  <span>個数: </span>
                  <span className="font-weight-bold">{
                    `${usedQuantity}`
                  }</span>
                  <span className="fs-14">個</span>
                </div>
            ))}
          </div>

        {sushiroQrImage && <div>
          <img className="sushiro-qr-image mt-2 mb-2" alt="Sushiro QR Code" src={sushiroQrImage} />
          <div className="text-center text-red font-weight-bold">
            スシロークーポンQRコード
            <br />
            を店頭にておみせ下さい。
          </div>
        </div>}

        <div className="mt-4" />

        {showReviewMessage ? (
          <>
            <div className="text-gray font-weight-bold fs-13">
              {t(
                "pages.Would you like to send a support message / review to the store"
              )}
            </div>
            <textarea
              maxLength={300}
              className="send-message-text-box h-120 fs-14"
              placeholder={t("pages.Message placeholder")}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
            <div
              className="btn btn-green-38 flex_center new-btn"
              style={{opacity: message ? '100%' : '50%'}}
              onClick={onOpenSendMessageConfirm}
            >
              <span>{t("pages.Send a message review")}</span>
            </div>
          </>
        ) : (
          <>
            <div className=" text-gray font-weight-bold fs-13 mt-4 mb-2">
              {t("pages.I send a review message")}
            </div>
            <div className="sent-thank-message-container">
              <div className="fs-35 font-weight-bold">MESSAGE</div>
              <div className="fs-35 font-weight-bold">THANK YOU</div>
              <div className="fs-18 line-break">{sentMessage}</div>
            </div>
          </>
        )}

        {invitation?.menuId && <GiftBoxButton onClick={goToMenuDetail}/>}

        <hr className="new-hr my-2" />
        {(couponLeft > 0 || invitation?.remainAmount > Number(useAmount) || invitation?.menu?.menuChildType === 5) && (
          <div
            className="btn btn-green-38 flex_center new-btn"
            onClick={viewTicket}
          >
            <span>{t("pages.View ticket")}</span>
          </div>
        )}

        <div className="fs-13 font-weight-bold  text-gray mt-3">
          {t(
            "pages.Why dont you use the feast with a reward for the person you feasted on and a gratitude for your loved one"
          )}
        </div>

        {/* {gochiTipUrl && (
          <div className="gochi-tip mt-3">
            <div className="d-flex flex-direction-row-reverse">
              <div className="mr-2">
                <img src="/images/external-link-icon.svg" loading="lazy" alt="" />
                <a
                  className="fs-11 font-weight-bold text-black link ml-1"
                  href="https://gochi-tip.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("pages.What is gochi-tip")}
                </a>
              </div>
            </div>
            <a
              href={gochiTipUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="border-bt new-btn btn-white-orange flex_center mt-1"
            >
              <div className="btn-gochi-tip flex_center">
                <img
                  src="/images/gochi-tip-heart.svg"
                  alt=""
                />
                <div className="fs-14 text-black ml-2">{t("pages.Give a tip to shop with gochi-tip")}</div>
              </div>
            </a>
          </div>
        )} */}

        <a
          href="https://gochi.online/"
          className="border-bt new-btn btn-white-green flex_center mt-3"
        >
          {t("pages.Go to the feast website")}
        </a>

        <div className="gochi-stamp-combo mt-5">
          <div className="gochi-stamp-combo-info border-radius-0">
            <div>
              <div className="clr-000000 fs-18 font-weight-bold">領収書番号 : {invitation.gochiBillNo}</div>
              <div><span className="fs-12 clr-000000">チケット利用問合先 : </span><a href="tel:050-5536-3317" className="fs-12 text-red clr-000000">050-5536-3317</a></div>
              <div className="text-red">※サポートセンターへの問い合わせ電話です。ご予約は、直接店舗様にお願いいたします。</div>
              <div className="fs-12 clr-000000">平日（月～金） 10:00～18:00／土曜日 10:00～15:00</div>
              <div className="fs-12 clr-000000">※お休み：日曜日・祝日 ・長期休暇・お盆休み・年末年始</div>
            </div>
          </div>
        </div>
        </div>
      </div>

      <ReactModal
        className="modail-content"
        size="sm"
        isOpen={showSendMessageConfirm}
        onRequestClose={() => {
          setShowSendMessageConfirm(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        style={customStyles}
      >
        <div className="fs-21 text-black text-center font-weight-normal">
          {t("pages.Message confirmation")}
        </div>

        <div className="fs-13 text-grey text-center font-weight-normal">
          {t("pages.The following thank you message will be sent")}
        </div>
        <hr className="new-hr my-1" />
        <div className="message-confirm text-grey line-break">{message}</div>
        <hr className="new-hr my-1" />
        <div
          className="btn btn-green-38 flex_center new-btn"
          onClick={sendReviewMessage}
        >
          <span>{t("pages.Send a message review")}</span>
        </div>
        <div className="fs-15 clr-000000 align-center mt-2">
          <button
            onClick={() => {
              setShowSendMessageConfirm(false);
            }}
            className="btn btn-close-popup"
          >
            {t("pages.Cancel")}
          </button>
        </div>
      </ReactModal>

      <ReactModal
        className="modail-content"
        size="sm"
        isOpen={showPaperTicketAlert}
        onRequestClose={() => {
          setShowPaperTicketAlert(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        style={customStyles}
      >
        <div className="fs-21 text-black text-center font-weight-normal">
          {t("pages.paper ticket message")}
        </div>

        <div className="fs-13 text-grey text-center font-weight-normal">
          {`ごちコード ${invitation.gochiBillNo}`}
        </div>
        <hr className="new-hr my-1" />

        <div
          className="btn btn-green-38 flex_center new-btn"
          onClick={() => {
            setShowPaperTicketAlert(false);
          }}
        >
          <span>確認</span>
        </div>
      </ReactModal>
      <LoadingOverlay loading={loading} />
    </>
  );
}

// export default connect()(
//   withTranslation()(TicketUseComplete)
// );

const mapStateToProps = (state) => ({
  invitation: state.gochis,
  authenCodeForShop: state.authenCodeForShop,
});

export default connect(mapStateToProps)(withTranslation()(TicketUseComplete));