import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";
import { Modal, ModalBody, ModalFooter, Row } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

function LimitTotalTicketUseHistoryModal(props) { 

  useEffect(() => {
    // setListCoupon(arr);
    console.log('props.listCoupons', props.listCoupons);
  }, []);
  const { t } = props;

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="sm"
      centered
      dialogClassName="history-modal-container"
      scrollable
    >
      <ModalHeader className="history-modal-header mb-1" closeButton>利用履歴</ModalHeader>
      {props.listCoupons?.length > 0 ? (
      <>
        <ModalBody className="py-0 modal-history">
          {props.listCoupons?.map(item => (
          <div className="history-box" key={item?.couponId}>
            <div className="p-3">
              <div className="history-line">
                <div className="title-lst-history">日付・日時</div>
                <div className="content-lst-history">{moment(item?.useDate).format('YYYY年MM月DD日 H時mm分')}</div>
              </div>
              <div className="my-8px history-line">
                <div className="title-lst-history extra-small-title">利用店舗</div>
                <div className="content-lst-history extra-large-content">{item?.shopName}</div>
              </div>
              <div className="history-line">
                <div className="title-lst-history">利用金額</div>
                <div className="content-lst-history">
                  <span>{item?.displayAmount.toLocaleString()}</span>
                  <span>円</span>
                </div>
              </div>
            </div>
          </div>
          ))}
        </ModalBody>
        <ModalFooter className="history-modal-footer">
          <div className="history-load-more">
            {(props.page+1) <= Math.ceil(props.couponsCount/props.pageLimit) && (
              props.loadingLoadMore ? (<i className="icon-loader-new"></i>) :
              (<span className="cursor-pointer fs-15 underline fw-bold history-load-more" onClick={props.onLoadMoreCoupon}>もっと見る</span>)
            )}
          </div>
        </ModalFooter>
      </>) : (<div className="no-line-history">利用履歴がございません</div>)}
    </Modal>
  );
}

// export default LimitTotalTicketUseHistoryModal;

const mapStateToProps = (state) => ({
  invitation: state.gochis
});

export default connect(mapStateToProps)(
  withTranslation()(LimitTotalTicketUseHistoryModal)
);
