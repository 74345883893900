import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ReactModal from "react-modal";
import {
  postThankYouMessage,
  POST_THANK_YOU_MESSAGE_SUCCESS,
} from "../../actions/Gochis";

const NO_IMAGE_URL = "/images/gochi_noimg-ver4.jpg";
const customStyles = {
  overlay: {
    background: "#444444",
    padding: 15,
  },
};

function ThankYouMessage(props) {
  function sendThankYouMessage() {
    setShowSendMessageConfirm(false);
    dispatch(postThankYouMessage({ message, couponId, gochiBillId })).then(
      (response) => {
        console.log("data ===", response);
        if (response.type === POST_THANK_YOU_MESSAGE_SUCCESS) {
          setShowSendMessage(false);
          setSentMessage(message);
        } else {
        }
      }
    );
  }

  const { t } = props;
  const [showSendMessageConfirm, setShowSendMessageConfirm] = useState(false);
  const [message, setMessage] = useState("");
  const [showSendMessage, setShowSendMessage] = useState(true);
  const invitation = props.invitation;
  const { dispatch } = props;
  const couponId = props.couponId;
  const gochiBillId = invitation.gochiBillId;
  const [sentMessage, setSentMessage] = useState("");

  useEffect(() => {
    const couponMessage =
      invitation.couponMessageDto && invitation.couponMessageDto.message;
    console.log("COUPON MESSAGE: ", couponMessage);
    if (couponMessage) {
      setSentMessage(couponMessage);
      setShowSendMessage(false);
    }
  }, [invitation]);

  return (
    invitation.gochiUserType !== "YOURSELF" && (
      <div>
        <div
          className="fs-18 font-weight-bold gift-card-user-info"
          dangerouslySetInnerHTML={{
            __html: t("pages.has arrived", {
              username:
                (invitation.gochiRegister &&
                  invitation.gochiRegister.displayName) ||
                "GUEST",
            }),
            interpolation: { escapeValue: false },
          }}
        >
          {/* <span className="gochi-user-register-name">
            {(invitation.gochiRegister && invitation.gochiRegister.displayName) || "GUEST"}
          </span> */}
          {/* <span>{t("pages.has arrived")}</span> */}
        </div>
        {invitation.giftCard && invitation.giftCard.image && (
          <img
            className="thank-you-image"
            src={
              (invitation.giftCard && invitation.giftCard.image) || NO_IMAGE_URL
            }
            alt="Thank you"
          />
        )}

        {invitation &&
          invitation.gochiBillMessage &&
          invitation.gochiBillMessage[0] &&
          invitation.gochiBillMessage[0].message && (
            <div className="thank-you-message-container">
              <div className="thank-you-message-top-box" />
              <div className="thank-you-message-bottom-box" />
              {invitation.gochiBillMessage[0].message}
            </div>
          )}

        {showSendMessage ? (
          <>
            <div className="text-black font-weight-bold fs-13 mt-4">
              {t("pages.Would you like to send a thank you message")}
            </div>

            <textarea
              maxLength={200}
              className="send-message-text-box"
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              placeholder={t("pages.Example thank you message")}
            />
            <button
              className="btn btn-green-38 flex_center new-btn mb-5"
              style={{opacity: message ? '100%' : '50%'}}
              onClick={() => {
                if (message) setShowSendMessageConfirm(true);
              }}
            >
              <span>{t("pages.Send thank you message")}</span>
            </button>
          </>
        ) : (
          <>
            <div className="text-black font-weight-bold fs-13 mt-4 mb-2">
              {t("pages.I sent a thank you message")}
            </div>
            <div className="sent-thank-message-container">
              <div className="fs-35 font-weight-bold">MESSAGE</div>
              <div className="fs-35 font-weight-bold">THANK YOU</div>
              <div className="fs-18 line-break">{sentMessage}</div>
            </div>
          </>
        )}

        <ReactModal
          className="modail-content"
          size="sm"
          isOpen={showSendMessageConfirm}
          onRequestClose={() => {
            setShowSendMessageConfirm(false);
          }}
          aria-labelledby="contained-modal-title-vcenter"
          style={customStyles}
        >
          <div className="fs-21 text-black text-center font-weight-normal">
            {t("pages.Message confirmation")}
          </div>

          <div className="fs-13 text-grey text-center font-weight-normal">
            {t("pages.The following thank you message will be sent")}
          </div>
          <hr className="new-hr my-1" />
          <div className="message-confirm text-grey line-break">{message}</div>
          <hr className="new-hr my-1" />
          <div
            className="btn btn-green-38 flex_center new-btn"
            onClick={sendThankYouMessage}
          >
            <span>{t("pages.Send thank you message")}</span>
          </div>
          <div className="fs-15 clr-000000 align-center mt-2">
            <button
              onClick={() => {
                setShowSendMessageConfirm(false);
              }}
              className="btn btn-close-popup"
            >
              {t("pages.Cancel")}
            </button>
          </div>
        </ReactModal>
      </div>
    )
  );
}

const mapStateToProps = (state) => ({ invitation: state.gochis });

export default connect(mapStateToProps)(withTranslation()(ThankYouMessage));
