import React from 'react';
import { withTranslation } from "react-i18next";
import moment from "moment";
import '../../stylesheets/CommingSoon.css'


class GochiNotFounnd extends React.Component {
 
  render() {
  const { t } = this.props;

    return(
      <div className="comming-soon-box">
        <div className="gochimeshi-logo"></div>
        <div>
          <span className="label_18">{t('pages.This feast cannot be used because it has been used or has expired')}</span>
          <span className="label_18">{t('pages.Contact Center is')}</span>
          <a 
            href="https://support.gigi.tokyo/contact/contact01/"
            rel="noopener noreferrer"
            target="_blank"
            className="text-green"
          >
            {t('pages.here')}
          </a>
          <span className="label_18">{t('pages.is')}</span>
        </div>
        {this.props.listHistoryCouponUsed && this.props.listHistoryCouponUsed.length > 0 && (
          <div className='history-used-gochi-can-not-use-box'>
            <div className='history-used-gochi-can-not-use-title'>利用履歴</div>
            <div>
              {this.props.listHistoryCouponUsed.map(item => (
                item.useDate ? (
                  <div className="history-box" key={item?.couponId}>
                    <div className="p-3">
                      <div className="history-line">
                        <div className="title-lst-history">日付・日時</div>
                        <div className="content-lst-history">{moment(item?.useDate).format('YYYY年MM月DD日 H時mm分')}</div>
                      </div>
                      <div className="my-8px history-line">
                        <div className="title-lst-history extra-small-title">利用店舗</div>
                        <div className="content-lst-history extra-large-content">{item?.shopName}</div>
                      </div>
                      <div className="history-line">
                        <div className="title-lst-history">利用金額</div>
                        <div className="content-lst-history">
                          <span>{item?.premiumAmount ? item?.premiumAmount?.toLocaleString() : (item?.amount + item?.taxAmount).toLocaleString()}</span>
                          <span>円</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="history-box" key={item?.couponId}>
                    <div className="p-3">
                      <div className="history-line mb-8">
                        <div className="title-lst-history text-red">期限切れになった日時</div>
                        <div className="content-lst-history">{moment(item?.expiration).format('YYYY年MM月DD日 23時59分')}</div>
                      </div>
                      <div className="history-line">
                        <div className="title-lst-history text-red">期限切れ金額</div>
                        <div className="content-lst-history">
                          <span>{item?.premiumAmount ? item?.premiumAmount?.toLocaleString() : (item?.amount + item?.taxAmount).toLocaleString()}</span>
                          <span>円</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )))}
            </div>
            <div className="history-load-more history-load-more-gochi-can-not-use">
              {(this.props.page + 1) <= Math.ceil(this.props.total / this.props.limit) && (
                this.props.loadingLoadMore ? (<i className="icon-loader-new"></i>) :
                (<span className="cursor-pointer fs-15 underline fw-bold history-load-more" onClick={this.props.onLoadMoreCouponGochiCanNotUse}>▼ もっと見る</span>)
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default (withTranslation()(GochiNotFounnd));