import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  getInvitations,
  getTaxAction,
  getFeeAction
} from "../../actions/Gochis";
import MenuInfo from "../common/MenuInfo"; 
import HamburgerMenu from "./HamburgerMenu";
import LoadingOverlay from "./LoadingOverlay";
import queryString from "query-string";
import history from "../history";
import AmountInput from "../components/AmountInput";
import GochiNotFound from "./GochiNotFound";
import {
  PAYMENT_TYPE_STRIPE
} from "../../constants";
import Header from "../common/Header";

function TicketUseInput(props) {
  const { t } = props; 
  const { invitation } = props;
  // const { authenCodeForShop } = props;
  const { dispatch } = props;

  var query = new URLSearchParams(props.location.search);
  var invitationCode = query.get("code");

  const params = queryString.parse(window.location.search); 
  const shopName = params.shopName;

  const location = props.location;
  // const addParams = location.state && location.state.addParams;
  const paramsMenu = location.state && location.state.paramsMenu; 
  console.log('paramsMenu in input', paramsMenu);
  const [useAmount, setUseAmount] = useState(paramsMenu?.useAmount || 0);
  const [inputAmount, setInputAmount] = useState(paramsMenu.inputAmount !== undefined ? paramsMenu.inputAmount : null);
  const [taxAmountType, setTaxAmountType] = useState(paramsMenu?.taxAmountType === undefined ? 'INCLUDED' : paramsMenu?.taxAmountType);
  const [addedAmount, setAddedAmount] = useState(paramsMenu?.addedAmount || 0);
  const [paymentMethod, setPaymentMethod] = useState(paramsMenu?.paymentMethod || PAYMENT_TYPE_STRIPE);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [taxRate, setTaxRate] = useState(0.1);
  const [feeRate, setFeeRate] = useState(0.1);
  // const isHold = false;

  useEffect(() => {
    let action = getInvitations({ invitationCode: invitationCode });
    dispatch(action).then((response) => {
      if (response.payload.status === 404) {
        window.location.href = 'gift?code=' + invitationCode;
      }
    });
    getFee();
    getTax();
  }, []);

  function getTax() {
    setLoading(true);
    try {
      dispatch(getTaxAction()).then((response) => {
        console.log('Tax = ', response);
        if (response.type === "GET_TAX_SUCCESS") {
          setLoading(false);
          setTaxRate(response.payload);
          // setErrorMessage(null);
        } else if (response.type === "GET_TAX_FAILURE") {
          setLoading(false);
          // setErrorMessage(message);
        }
      });
    } catch (e) {
      console.log(e);
      // setErrorMessage(message);
    }
  }

  async function getFee() {
    setLoading(true);
    try {
      await dispatch(getFeeAction()).then((response) => { 
        if (response.type === "GET_FEE_SUCCESS") {
          setLoading(false);
          setFeeRate(response.payload); 
        } else if (response.type === "GET_FEE_FAILURE") {
          setLoading(false);
        }
      });
    } catch (e) {
      console.log(e); 
    }
  }

  function openTicketUse() {
    if (inputAmount > 0) {
      if (addedAmount > 0 && process.env.REACT_APP_ENABLED_EXCEEDED_AMOUNT_PAYMENT === 'false') {
        setErrorMessage(t('pages.Please enter the amount within the balance')); 
      } else {
        setErrorMessage('');
        let addParams = {
          type: "USE_GOCHI",
          authenData: {
            invitationCode: invitationCode ? invitationCode : null,
            quantity: 1,
            shopId: paramsMenu.shopId || invitation.shopId || (invitation.shop && invitation.shop.shopId)
          },
          extraData: {
            gochiBillId: paramsMenu?.invitation?.gochiBillId || invitation.gochiBillId,
            groupId: paramsMenu?.invitation?.groupId || invitation.groupId,
            menuId: paramsMenu?.invitation?.menu?.menuId || invitation.menu?.menuId || null,
            shopName: paramsMenu?.invitation?.shopName || invitation.shopName || (invitation.shop && invitation.shop.shopName),
            useAmount: Number(useAmount)
          }
        };
        const newParamsMenuState = {
          menuLimitTotal: invitation.menuLimitTotal || paramsMenu?.menuLimitTotal,
          remainAmount: invitation.remainAmount || paramsMenu?.remainAmount,
          displayAmount: invitation.displayAmount || paramsMenu?.displayAmount,
          addedAmount,
          paymentMethod,
          useAmount,
          inputAmount,
          taxAmountType,
          paymentMethod,
          showQRCode: addedAmount <= 0,
          shopId: invitation.shopId || (invitation.shop && invitation.shop.shopId) || paramsMenu?.shopId,
          gochiType: invitation.gochiType,
          gochiBillImage: invitation?.gochiBillImage[0]?.image,
          invitation: paramsMenu?.invitation ? paramsMenu?.invitation : invitation
        };
        /*
        history.replace({
          ...location,
          state: {
            ...location.state,
            addParams,
            paramsMenu: newParamsMenuState
          },
        });
        */
        history.replace({
          pathname: "/ticket-use",
          state: {
            addParams,
            paramsMenu: newParamsMenuState
          },
          search:
            window.location.search,
        }); 
      }
    } else {
      setErrorMessage(t('pages.Please enter the amount correctly'));
    }
  } 

  return (
    <>
      <div className="gochi">
        <Header title={t("pages.Enter the usage amount")} hasBack backAction={() => {
              window.location.href = `/gift?code=${invitationCode}`;
            }} />
        
        <>
          <MenuInfo 
            menuLimitTotal 
            showShopName 
            shopName={shopName} 
            hideAmount 
            paramsMenu={paramsMenu}
            menuLimitTotal={invitation.gochiBillId !== 0 ? invitation.menuLimitTotal : paramsMenu?.menuLimitTotal} 
          />
          
          <hr className="new-hr mt-1 mb-3" />
          <div className="add-padding">
            <AmountInput 
              onSubmitInput={openTicketUse}
              useAmount={useAmount}
              setUseAmount={setUseAmount}
              inputAmount={inputAmount}
              setInputAmount={setInputAmount}
              taxAmountType={taxAmountType}
              setTaxAmountType={setTaxAmountType}
              addedAmount={addedAmount}
              setAddedAmount={setAddedAmount}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              paramsMenu={paramsMenu}
              invitationCode={invitationCode}
              taxRate={taxRate}
              feeRate={feeRate}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              // shopId={invitation.shopId || (invitation.shop && invitation.shop.shopId)}
            />
            <div className="gochi-stamp-combo mt-4">
            <div className="gochi-stamp-combo-info border-radius-0">
              <div>
                <div className="clr-000000 fs-18 font-weight-bold">領収書番号 : {invitation.gochiBillNo}</div>
                <div><span className="fs-12 clr-000000">チケット利用問合先 : </span><a href="tel:050-5536-3317" className="fs-12 text-red clr-000000">050-5536-3317</a></div>
                <div className="text-red">※サポートセンターへの問い合わせ電話です。ご予約は、直接店舗様にお願いいたします。</div>
                <div className="fs-12 clr-000000">平日（月～金） 10:00～18:00／土曜日 10:00～15:00</div>
                <div className="fs-12 clr-000000">※お休み：日曜日・祝日 ・長期休暇・お盆休み・年末年始</div>
              </div>
            </div>
          </div>
        </div>
        </>

        <LoadingOverlay loading={loading} />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  invitation: state.gochis,
  authenCodeForShop: state.authenCodeForShop,
});

export default connect(mapStateToProps)(withTranslation()(TicketUseInput));
