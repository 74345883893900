import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Media from "react-bootstrap/Media";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import Gochirareru from "../pages/Gochirareru";

const customStyles = {
  overlay: {
    background: "#808080",
  },
  content: {
    marginTop: '10px'
  },
};

const NO_IMAGE_URL = "/images/gochi_noimg-ver4.jpg"; 

class ListShop extends Component {
  UNSAFE_componentWillMount() {}

  componentDidMount() {
    console.log(this.props.searchKeyword);
  }

  render() {
    const { t } = this.props;
    const invitation = this.props.invitation;
    const invitationCode = this.props.invitationCode;
	const isQRScan = this.props.isQRScan;

    const handleCloseListShopPopup = this.props.handleCloseListShopPopup;

    function goToShopDetailOnGiftSite(shopId) {
      window.open(`${process.env.REACT_APP_GIFT_SITE_URL}/shopdetails/${shopId}`);
    }

    function handleOpenMap(shop) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${shop.prefectures ? shop.prefectures + " " : ""}${shop.city ? shop.city + " " : ""}${shop.address ? shop.address + " " : ""}${shop.building}`
      );
    }

    return (
      <>
        <Modal
          className="modail-content"
          size="sm"
          isOpen={this.props.showListShopPopup}
          onRequestClose={this.props.handleCloseListShopPopup}
          aria-labelledby="contained-modal-title-vcenter"
          style={customStyles}
        >
          <>
            {/* {this.props.listShop.length > 0 && ( */}
              <div className="lst-shop-cmm mt-4">
                <div className="title-lst-shop d-flex justify-content-center align-center fs-22">
                  <span style={{width: 40, marginRight: 'auto'}} className=""></span>
                  <span>{isQRScan ? t("pages.QR code reading") : "店舗選択"}</span>
                  <button onClick={this.props.handleCloseListShopPopup} style={{marginLeft: 'auto'}} type="button" className="btn-close-modal">
                    <i className="icon-close" />
                  </button>
                </div>
                {!isQRScan && (
                  <>
                    <div className="searchShop">
                      <div>
                        <div className="fieldset fieldset-lg">
                          <div className="fieldset-header">
                            <div className="title fs-16">キーワード（ジャンル・住所・店舗名等）</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="searchShop">
                      <div className="inputField">
                        <div className="fieldset fieldset-lg">
                          <div className="fieldset-body">
                            <div className="field field-pill field-lg">
                              <input
                                className="fs-18"
                                type="text"
                                placeholder="地域名や店舗名"
                                value={this.props.keyword}
                                onChange={event => this.props.setKeyword(event)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="btnSearchF">
                        <div className="fieldset fieldset-submit">
                          <button type="button" className="btn fs-20 btn-submit search-btn mt-0" onClick={() => this.props.searchKeyword()}>
                            検索
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="lst-shops">
                  {!isQRScan && (
                  <div className="d-flex align-items-center mt-3 mb-2">
                    <div className="font-weight-bold fs-18 clr-000000">
                    {t("pages.searchResult")}
                    </div>
                    <div className="ml-auto fs-18 count-left">
                    {t("pages.total", {
                      count: this.props.listShop && this.props.shopCount,
                    })}
                    </div>
                  </div>
                  )}

                  {!isQRScan ? (
                  <ListGroup className="list-items">
                    {this.props.listShop.map(function (shop, key) {
                      return (
                        <ListGroup.Item key={shop.shopId} className="shop-item border-none">
                          <Media>
                            <img width={105} 
                              height={105} 
                              src={(shop.shopImage && shop.shopImage[0] && shop.shopImage[0].image) || NO_IMAGE_URL} 
                              alt={shop.shopName} 
                              style={{objectFit: 'contain'}}
                              />
                            <Media.Body className="shop-body">
                              <h5 onClick={() => goToShopDetailOnGiftSite(shop.shopId)} className="shop-name cursor-pointer clr-2EC1B4 text-break">{shop.shopName}</h5>
                              <p className="shop-distance fs-16">
                                {!(!shop.distance && !shop.nearestStation) && (
                                  <FontAwesomeIcon
                                    icon={faMapMarkerAlt}
                                    className="mr-2 fs-16"
                                  />
                                )}
                                {shop.prefectures ? shop.prefectures + " " : ""}
                                {shop.city ? shop.city + " " : ""}
                                {shop.address ? shop.address + " " : ""}
                                {shop.building}
                              </p>
                              <div onClick={() => handleOpenMap(shop)} className="btn btn-cyan-outline">
                                <i className="ic-map" />
                                <span>MAPはこちらから</span>
                              </div>
                            </Media.Body>
                          </Media>
                          <div className="mt-2" />
                          <Gochirareru
                                // invitation={{...invitation, shop: {shopId : shop.shopId, shopName: shop.shopName} }}
                                invitationGochi={Object.assign({}, invitation, {
                                  shop: {
                                    shopId: shop.shopId,
                                    shopName: shop.shopName,
                                    sushiroFlag: shop.sushiroFlag,
                                  },
                                })}
                                textButton={t("pages.Use at this shop")}
                                isShopBtn={true}
                                shopId={shop.shopId}
                                invitationCode={invitationCode}
                                onCloseError={handleCloseListShopPopup}
                              />
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                  ) : (
                  <Gochirareru
                    invitationGochi={invitation}
                    invitationCode={invitationCode}
                    onCloseError={handleCloseListShopPopup}
                    isQRScan={isQRScan}
                  />
                  )}

                  {!isQRScan && this.props.hasMore && (
                    <div className="text-center mt-3">
                      <Button
                        className="btn btn-green-38 flex_center new-btn mb-2 btn-cyan"
                        onClick={this.props.loadMoreShop}
                      >
                        さらに表示する
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            {/* )} */}
          </>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

// const mapDispatchToProps = (dispatch) => ({
//   showLoading: (showing) => dispatch(showLoading(showing)),
//   showError: (showing, title, content, buttonName, gotoUrl) =>
//     dispatch(showError(showing, title, content, buttonName, gotoUrl)),
// });

export default connect(
  mapStateToProps
  //   mapDispatchToProps
)(withTranslation()(ListShop));
