import * as gochisActions from '../actions/Gochis';

const initialState = {
  authenCode: null
}

const authenCodeForShop = (state = initialState, action) => {
  switch (action.type) {
    case gochisActions.POST_AUTHEN_CODE_SUCCESS:
      return Object.assign({}, state, action.payload);
    case gochisActions.POST_AUTHEN_CODE_FAILURE:
      return Object.assign({}, state, {
        status: action.payload,
        error: action.error
      });
    default:
      return state;
  }
};

export default authenCodeForShop;
