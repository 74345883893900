import React from "react";
import { DaigasFooter } from "./DaigasFooter";
import { DaigasHeader } from "./DaigasHeader";

export function DaigasNotification(props) {
  return (
    <>
      <DaigasHeader />

      <div className="noti-content daigas-content">
        <div
          className="noti-title"
          dangerouslySetInnerHTML={{__html: props.title}}
        >
        </div>
        <div
          className="noti-message"
          dangerouslySetInnerHTML={{__html: props.message}}
        >
        </div>
      </div>

      <DaigasFooter />
    </>
  );
}

export default DaigasNotification;