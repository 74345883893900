import axios from 'axios';
import queryString from 'query-string';

const API_HOST = process.env.REACT_APP_API_BASE_PATH;
const lang = localStorage.getItem('lang');

axios.defaults.headers.get['acceptLanguage'] = lang;

// export const getListShopsMenu = params => {
//   return axios.get(`${API_HOST}/menus/53368/shops`, { params, withCredentials: true });
// }; 

export const getListShopsMenu = ({menuId, latitude, longitude, sort, keyword, page, limit = 10}) => { 
  let params = [];
  if (latitude) {
    params.push(`latitude=${latitude}`);
  }
  if (longitude) {
    params.push(`longitude=${longitude}`);
  }
  if (sort) {
    params.push(`sort=${sort}`);
  }
  if (keyword) {
    params.push(`keyword=${keyword}`);
  }
  params.push(`page=${page}`);
  params.push(`limit=${limit}`);

  var url = `${API_HOST}/v2/menus/${menuId}/shops?${params.join("&")}`;
  return axios.get(`${url}`, { withCredentials: true });
};

export async function checkShopIdScanQrCode (menuId, shopId) {
  let url = `${API_HOST}/v2/menus/${menuId}/shops/${shopId}/check`;
  return axios.get(url);
}

export const getListShopsMenuBooking = ({menuId, latitude, longitude, sort, keyword, page, limit = 10}) => { 
  let params = [];
  if (latitude) {
    params.push(`latitude=${latitude}`);
  }
  if (longitude) {
    params.push(`longitude=${longitude}`);
  }
  if (sort) {
    params.push(`sort=${sort}`);
  }
  if (keyword) {
    params.push(`keyword=${keyword}`);
  }
  params.push(`bookingEnabled=1`);
  params.push(`page=${page}`);
  params.push(`limit=${limit}`);

  var url = `${API_HOST}/v2/menus/${menuId}/shops?${params.join("&")}`;
  return axios.get(`${url}`, { withCredentials: true });
};

export async function createSession(params){
  var url = `${API_HOST}/v3/gochi/session/create`;
  return axios.post(url, params);
};


export async function getSurvey(params) {
  var url = `${API_HOST}/survey?${queryString.stringify(params)}`;
  return axios.get(url);
}

export async function postSurveyAnswer(surveyId, answers, gochiBillId) {
  const url = `${API_HOST}/survey/answer`;
  return axios.post(url, {
    surveyId,
    gochiBillId,
    answers
  });
}

export async function postImage(image, surveyId) {
  const url = `${API_HOST}/upload/image`;
    var formData = new FormData();
    formData.append("imageFile", image);
    formData.append("surveyId", surveyId);
    return axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    });
}