import {
  utilizeGochi,
  createGochiCode,
  createGochiUrl,
  getChildfoodsData,
  getGroupData,
  getShopData,
  fetchInviteUrlData,
  postThankYouMessageData,
  postReviewMessageData,
  getReviewMessageData,
  getAuthenCodeData,
  createAuthenCode,
  createSessionId,
  getCompleteSessionData,
  getTaxData,
  getFeeData,
  getGochiChargeHistoryChargeData,
  getGochiChargeHistoryUsedData
} from "../libs/apis/GochiApi";
import { getInvitation } from "../libs/apis";
export const GET_GOCHIS_INVITATIONS = "GET_GOCHIS_INVITATIONS";
export const GET_GOCHIS_SUCCESS = "GET_GOCHIS_SUCCESS";
export const GET_GOCHIS_FAILURE = "GET_GOCHIS_FAILURE";

export const POST_USE_GOCHI = "POST_USE_GOCHI";
export const POST_USE_GOCHI_SUCCESS = "POST_USE_GOCHI_SUCCESS";
export const POST_USE_GOCHI_FAILURE = "POST_USE_GOCHI_FAILURE";

export const POST_CREATE_GOCHI_CODE = "POST_CREATE_GOCHI_CODE";
export const POST_CREATE_GOCHI_CODE_SUCCESS = "POST_CREATE_GOCHI_CODE_SUCCESS";
export const POST_CREATE_GOCHI_CODE_FAILURE = "POST_CREATE_GOCHI_CODE_FAILURE";

export const POST_CREATE_GOCHI_URL = "POST_CREATE_GOCHI_URL";
export const POST_CREATE_GOCHI_URL_SUCCESS = "POST_CREATE_GOCHI_URL_SUCCESS";
export const POST_CREATE_GOCHI_URL_FAILURE = "POST_CREATE_GOCHI_URL_FAILURE";

export const GET_CHILDFOODS_REQUEST = "GET_CHILDFOODS_REQUEST";
export const GET_CHILDFOODS_SUCCESS = "GET_CHILDFOODS_SUCCESS";
export const GET_CHILDFOODS_FAILURE = "GET_CHILDFOODS_FAILURE";

export const GET_GROUP_INFO_REQUEST = "GET_GROUP_INFO_REQUEST";
export const GET_GROUP_INFO_SUCCESS = "GET_GROUP_INFO_SUCCESS";
export const GET_GROUP_INFO_FAILURE = "GET_GROUP_INFO_FAILURE";

export const GET_SHOP_INFO_REQUEST = "GET_SHOP_INFO_REQUEST";
export const GET_SHOP_INFO_SUCCESS = "GET_SHOP_INFO_SUCCESS";
export const GET_SHOP_INFO_FAILURE = "GET_SHOP_INFO_FAILURE";

export const FETCH_INVITE_URL_REQUEST = "FETCH_INVITE_URL_REQUEST";
export const FETCH_INVITE_URL_SUCCESS = "FETCH_INVITE_URL_SUCCESS";
export const FETCH_INVITE_URL_FAILURE = "FETCH_INVITE_URL_FAILURE";

export const POST_THANK_YOU_MESSAGE_REQUEST = "POST_THANK_YOU_MESSAGE_REQUEST";
export const POST_THANK_YOU_MESSAGE_SUCCESS = "POST_THANK_YOU_MESSAGE_SUCCESS";
export const POST_THANK_YOU_MESSAGE_FAILURE = "POST_THANK_YOU_MESSAGE_FAILURE";

export const POST_REVIEW_MESSAGE_REQUEST = "POST_REVIEW_MESSAGE_REQUEST";
export const POST_REVIEW_MESSAGE_SUCCESS = "POST_REVIEW_MESSAGE_SUCCESS";
export const POST_REVIEW_MESSAGE_FAILURE = "POST_REVIEW_MESSAGE_FAILURE";

export const GET_REVIEW_MESSAGE_REQUEST = "GET_REVIEW_MESSAGE_REQUEST";
export const GET_REVIEW_MESSAGE_SUCCESS = "GET_REVIEW_MESSAGE_SUCCESS";
export const GET_REVIEW_MESSAGE_FAILURE = "GET_REVIEW_MESSAGE_FAILURE";

export const GET_AUTHEN_CODE_REQUEST = "GET_AUTHEN_CODE_REQUEST";
export const GET_AUTHEN_CODE_SUCCESS = "GET_AUTHEN_CODE_SUCCESS";
export const GET_AUTHEN_CODE_FAILURE = "GET_AUTHEN_CODE_FAILURE";

export const POST_AUTHEN_CODE_REQUEST = "POST_AUTHEN_CODE_SUCCESS";
export const POST_AUTHEN_CODE_SUCCESS = "POST_AUTHEN_CODE_SUCCESS";
export const POST_AUTHEN_CODE_FAILURE = "POST_AUTHEN_CODE_FAILURE";

export const POST_PAYMENT_SESSION_REQUEST = "POST_PAYMENT_SESSION_SUCCESS";
export const POST_PAYMENT_SESSION_SUCCESS = "POST_PAYMENT_SESSION_SUCCESS";
export const POST_PAYMENT_SESSION_FAILURE = "POST_PAYMENT_SESSION_FAILURE";

export const GET_COMPLETE_SESSION_REQUEST = "GET_COMPLETE_SESSION_SUCCESS";
export const GET_COMPLETE_SESSION_SUCCESS = "GET_COMPLETE_SESSION_SUCCESS";
export const GET_COMPLETE_SESSION_FAILURE = "GET_COMPLETE_SESSION_FAILURE";

export const GET_TAX_REQUEST = "GET_TAX_SUCCESS";
export const GET_TAX_SUCCESS = "GET_TAX_SUCCESS";
export const GET_TAX_FAILURE = "GET_TAX_FAILURE";

export const GET_FEE_REQUEST = "GET_FEE_SUCCESS";
export const GET_FEE_SUCCESS = "GET_FEE_SUCCESS";
export const GET_FEE_FAILURE = "GET_FEE_FAILURE";

export const GET_GOCHI_CHARGE_HISTORY_CHARGE_REQUEST = "GET_GOCHI_CHARGE_HISTORY_CHARGE_REQUEST";
export const GET_GOCHI_CHARGE_HISTORY_CHARGE_SUCCESS = "GET_GOCHI_CHARGE_HISTORY_CHARGE_SUCCESS";
export const GET_GOCHI_CHARGE_HISTORY_CHARGE_FAILURE = "GET_GOCHI_CHARGE_HISTORY_CHARGE_FAILURE";

export const GET_GOCHI_CHARGE_HISTORY_USED_REQUEST = "GET_GOCHI_CHARGE_HISTORY_USED_REQUEST";
export const GET_GOCHI_CHARGE_HISTORY_USED_SUCCESS = "GET_GOCHI_CHARGE_HISTORY_USED_SUCCESS";
export const GET_GOCHI_CHARGE_HISTORY_USED_FAILURE = "GET_GOCHI_CHARGE_HISTORY_USED_FAILURE";

export const getInvitations = (params) => (dispatch) => {
  dispatch({ type: GET_GOCHIS_INVITATIONS });
  return getInvitation(params)
    .then((response) =>
      dispatch({
        type: GET_GOCHIS_SUCCESS,
        payload: response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: GET_GOCHIS_FAILURE,
        payload: error.response,
        error: true,
      })
    );
};
export const utilizeGochis = (params, crfsToken) => (dispatch) => {
  dispatch({ type: POST_USE_GOCHI });
  return utilizeGochi(params, crfsToken)
    .then((response) =>
      dispatch({
        type: POST_USE_GOCHI_SUCCESS,
        payload: response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: POST_USE_GOCHI_FAILURE,
        payload: error.response,
        error: true,
      })
    );
};

export const createGochiCodes = (params) => (dispatch) => {
  dispatch({ type: POST_CREATE_GOCHI_CODE });
  return createGochiCode(params)
    .then((response) =>
      dispatch({
        type: POST_CREATE_GOCHI_CODE_SUCCESS,
        payload: response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: POST_CREATE_GOCHI_CODE_FAILURE,
        payload: error.response,
        error: true,
      })
    );
};

export const createGochiUrls = (params) => (dispatch) => {
  dispatch({ type: POST_CREATE_GOCHI_URL });
  return createGochiUrl(params)
    .then((response) =>
      dispatch({
        type: POST_CREATE_GOCHI_URL_SUCCESS,
        payload: response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: POST_CREATE_GOCHI_URL_FAILURE,
        payload: error.response,
        error: true,
      })
    );
};

export const getChildfoods = (params) => (dispatch) => {
  dispatch({ type: GET_CHILDFOODS_REQUEST });
  return getChildfoodsData(params)
    .then((response) =>
      dispatch({
        type: GET_CHILDFOODS_SUCCESS,
        payload: response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: GET_CHILDFOODS_FAILURE,
        payload: error.response,
        error: true,
      })
    );
};

export const getGroupDatas = (params) => (dispatch) => {
  dispatch({ type: GET_GROUP_INFO_REQUEST });
  return getGroupData(params)
    .then((response) =>
      dispatch({
        type: GET_GROUP_INFO_SUCCESS,
        payload: response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: GET_GROUP_INFO_FAILURE,
        payload: error.response,
        error: true,
      })
    );
};

export const getShopDatas = (params) => (dispatch) => {
  dispatch({ type: GET_SHOP_INFO_REQUEST });
  return getShopData(params)
    .then((response) =>
      dispatch({
        type: GET_SHOP_INFO_SUCCESS,
        payload: response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: GET_SHOP_INFO_FAILURE,
        payload: error.response,
        error: true,
      })
    );
};

export const fetchInviteUrl = (params) => (dispatch) => {
  dispatch({ type: FETCH_INVITE_URL_REQUEST });
  return fetchInviteUrlData(params)
    .then((response) =>
      dispatch({
        type: FETCH_INVITE_URL_SUCCESS,
        payload: response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: FETCH_INVITE_URL_FAILURE,
        payload: error.response,
        error: true,
      })
    );
};

export const postThankYouMessage = (params) => (dispatch) => {
  dispatch({ type: POST_THANK_YOU_MESSAGE_REQUEST });
  return postThankYouMessageData(params)
    .then((response) =>
      dispatch({
        type: POST_THANK_YOU_MESSAGE_SUCCESS,
        payload: response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: POST_THANK_YOU_MESSAGE_FAILURE,
        payload: error.response,
        error: true,
      })
    );
};

export const postReviewMessage = (couponId, params) => (dispatch) => {
  dispatch({ type: POST_REVIEW_MESSAGE_REQUEST });
  return postReviewMessageData(couponId, params)
    .then((response) =>
      dispatch({
        type: POST_REVIEW_MESSAGE_SUCCESS,
        payload: response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: POST_REVIEW_MESSAGE_FAILURE,
        payload: error.response,
        error: true,
      })
    );
};

export const getReviewMessage = (couponId) => (dispatch) => {
  dispatch({ type: GET_REVIEW_MESSAGE_REQUEST });
  return getReviewMessageData(couponId)
    .then((response) =>
      dispatch({
        type: GET_REVIEW_MESSAGE_SUCCESS,
        payload: response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: GET_REVIEW_MESSAGE_FAILURE,
        payload: error.response,
        error: true,
      })
    );
};

export const getAuthenCode = (authenCode) => (dispatch) => {
  dispatch({ type: GET_AUTHEN_CODE_REQUEST });
  return getAuthenCodeData(authenCode)
    .then((response) =>
      dispatch({
        type: GET_AUTHEN_CODE_SUCCESS,
        payload: response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: GET_AUTHEN_CODE_FAILURE,
        payload: error.response,
        error: true,
      })
    );
};

export const createAuthenCodeForShop = (params) => (dispatch) => {
  dispatch({ type: POST_AUTHEN_CODE_REQUEST });
  return createAuthenCode(params)
    .then((response) =>
      dispatch({
        type: POST_AUTHEN_CODE_SUCCESS,
        payload: response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: POST_AUTHEN_CODE_FAILURE,
        payload: error.response,
        error: true,
      })
    );
};

export const createSession = (params) => (dispatch) => {
  dispatch({ type: POST_PAYMENT_SESSION_REQUEST });
  return createSessionId(params)
    .then((response) =>
      dispatch({
        type: POST_PAYMENT_SESSION_SUCCESS,
        payload: response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: POST_PAYMENT_SESSION_FAILURE,
        payload: error.response,
        error: true,
      })
    );
};

export const getCompleteSession = (merchantPaymentId) => (dispatch) => {
  dispatch({ type: GET_COMPLETE_SESSION_REQUEST });
  return getCompleteSessionData(merchantPaymentId)
    .then((response) =>
      dispatch({
        type: GET_COMPLETE_SESSION_SUCCESS,
        payload: response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: GET_COMPLETE_SESSION_FAILURE,
        payload: error.response,
        error: true,
      })
    );
};

export const getTaxAction = () => async (dispatch) => {
  dispatch({ type: GET_TAX_REQUEST });
  try {
    const response = await getTaxData();
    return dispatch({
      type: GET_TAX_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    return dispatch({
      type: GET_TAX_FAILURE,
      payload: error.response,
      error: true,
    });
  }
};

export const getFeeAction = () => async (dispatch) => {
  dispatch({ type: GET_FEE_REQUEST });
  try {
    const response = await getFeeData();
    return dispatch({
      type: GET_FEE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    return dispatch({
      type: GET_FEE_FAILURE,
      payload: error.response,
      error: true,
    });
  }
};


export const getGochiChargeHistoryCharge = (params) => async (dispatch) => {
  dispatch({ type: GET_GOCHI_CHARGE_HISTORY_CHARGE_REQUEST });
  try {
    const response = await getGochiChargeHistoryChargeData(params);
    return dispatch({
      type: GET_GOCHI_CHARGE_HISTORY_CHARGE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    return dispatch({
      type: GET_GOCHI_CHARGE_HISTORY_CHARGE_FAILURE,
      payload: error.response,
      error: true,
    });
  }
};

export const getGochiChargeHistoryUsed = (params) => async (dispatch) => {
  dispatch({ type: GET_GOCHI_CHARGE_HISTORY_USED_REQUEST });
  try {
    const response = await getGochiChargeHistoryUsedData(params);
    return dispatch({
      type: GET_GOCHI_CHARGE_HISTORY_USED_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    return dispatch({
      type: GET_GOCHI_CHARGE_HISTORY_USED_FAILURE,
      payload: error.response,
      error: true,
    });
  }
};