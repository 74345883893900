import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import "../../stylesheets/Gochirareru.css";
import { utilizeGochis, createAuthenCodeForShop } from "../../actions/Gochis";
import LoadingOverlay from "./LoadingOverlay";
import QuantitySelect from "../common/QuantitySelect";
import queryString from "query-string";
import history from "../history";
import { Modal } from "react-bootstrap";
import QrReader from "react-qr-reader";
import { checkShopIdScanQrCode } from "../../libs/apis/MenuApi";

class Gochirareru extends React.Component {
  constructor(props) {
    super(props);
    const { dispatch } = props;

    this.boundActionCreators = bindActionCreators({
      ...utilizeGochis,
      ...createAuthenCodeForShop
    }, dispatch);

    this.state = {
      confirmIsOpen: false,
      useIsOpen: false,
      validateMessage: "",
      validated: false,
      selectIsOpen: false,
      quantity: 1,
      error: false,
      errorMessage: this.props.t("An error has occurred"),
      disabled: false,
      page: 1,
      limit: 10,
      gochiUseError: false,
      gochiUseErrorMess: "",
      crfsToken: "",
      confirmError: "",
	  flagQrCode: true,
    };
    this.openUse = this.openUse.bind(this);
    this.afterOpenUse = this.afterOpenUse.bind(this);
    this.closeUse = this.closeUse.bind(this);

    this.openSelect = this.openSelect.bind(this);
    this.afterOpenSelect = this.afterOpenSelect.bind(this);
    this.closeSelect = this.closeSelect.bind(this);
    this.closeErrorPopup = this.closeErrorPopup.bind(this);

    this.refreshPage = this.refreshPage.bind(this);
    this.increaseQuantity = this.increaseQuantity.bind(this);
    this.descreaseQuantity = this.descreaseQuantity.bind(this);
    this.setQuantity = this.setQuantity.bind(this);
	this.handleScanQRCode = this.handleScanQRCode.bind(this);
	this.handleErrorQRCode = this.handleErrorQRCode.bind(this);
  }

  refreshPage() {
    window.location.reload(false);
  }

  afterOpenConfirm() {
    // references are now sync'd and can be accessed.
  }

  increaseQuantity() {
    const invitation = this.props.invitationGochi;
    this.setState((state) => {
      if (state.quantity === "") return { quantity: 1 };
      if (state.quantity < invitation.gochiCount)
        return { quantity: state.quantity + 1 };
      return { quantity: state.quantity };
    });
  }

  setQuantity(quantity) {
    console.log("SET QUANTITY: ", quantity);
    const invitation = this.props.invitationGochi;
    if (quantity === "") {
      this.setState({ quantity: "" });
    } else if (quantity < 1) {
      this.setState({ quantity: 1 });
    } else if (quantity > invitation.gochiCount) {
      this.setState({ quantity: invitation.gochiCount });
    } else {
      this.setState({ quantity });
    }
  }

  descreaseQuantity() {
    this.setState((state) => {
      if (state.quantity === "") return { quantity: 1 };
      if (state.quantity > 1) return { quantity: state.quantity - 1 };
      return { quantity: state.quantity };
    });
  }

  openUse(event, childFoodId) {
    console.log("OPEN USE");
    const invitation = this.props.invitationGochi;
    let invitationCode = this.props.invitationCode;

    if (invitation.isAvailable === false) {
      this.setState({
        gochiUseError: true,
        gochiUseErrorMess: this.props.t(
          "Ticket unavailable"
        ),
      });
    } else
	if (invitation.menu?.isETicket === 0) {
	  this.setState({
		gochiUseError: true,
		gochiUseErrorMess: this.props.t(
		  "Prevent paper ticket"
		),
	  });
	} else
    if (invitation.canUse === false) {
      this.setState({
        gochiUseError: true,
        gochiUseErrorMess: this.props.t(
          "This ticket can only be used once a day"
        ),
      });
    } else {
      let invitation = this.props.invitationGochi;
      if (!this.state.quantity || this.state.quantity === "0") {
        this.setState({
          validateMessage: this.props.t("Please enter a number"),
        });
      } else if (
        this.state.quantity > invitation.gochiCount ||
        this.state.quantity < 0
      ) {
        this.setState({
          validateMessage: this.props.t(
            "Please enter a number less than or equal to the number of feasts"
          ),
        });
      } else {
        this.setState({
          validateMessage: "",
        });
        if (invitation.gochiBillId) {
          this.setState({
            validateMessage: "",
          });

          // this.generateAuthenCode();
          let addParams = {
            type: "USE_GOCHI",
            authenData: {
              invitationCode: invitationCode ? invitationCode : null,
              quantity: this.state.quantity,
              shopId: invitation.shopId || (invitation.shop && invitation.shop.shopId)
            },
            extraData: {
              gochiBillId: invitation.gochiBillId,
              groupId: invitation.groupId,
              menuId: invitation.menu ? invitation.menu.menuId : null,
              shopName: invitation.shopName || (invitation.shop && invitation.shop.shopName)
              // memo: {
              //   shopId: invitation.shopId
              // }
            }
          };

          let search = queryString.parse(window.location.search);
          search.quantity = this.state.quantity;
          search.shopId =
            invitation.shopId || (invitation.shop && invitation.shop.shopId);
          search.childFoodId = childFoodId;
          search.shopName = invitation.shop.shopName;
          let pathName = invitation.menuLimitTotal ? "/ticket-use-input" : "/ticket-use";
          // Close popup before go to next page to prevent popup show when back
          console.log('Close popup');
          this.closeErrorPopup();
          setTimeout(() => {
            console.log('Delay 0.1s before go to next page');
            history.push({
              pathname: pathName,
              state: {
                addParams,
                paramsMenu: {
                  showQRCode: invitation.menuLimitTotal ? false : true,
                  menuLimitTotal: invitation.menuLimitTotal,
                  remainAmount: invitation.remainAmount,
                  displayAmount: invitation.displayAmount,
                  shopName: invitation.shopName || (invitation.shop && invitation.shop.shopName),
                  menuName: invitation.menu ? invitation.menu.menuName : '',
                  gochiType: invitation.gochiType,
                  gochiBillImage: invitation?.gochiBillImage[0]?.image,
                  shopId: invitation.shopId || (invitation.shop && invitation.shop.shopId) || this.props.shopId,
                  invitation: invitation,
                  inputAmount: null,
                  taxAmountType: 'INCLUDED'
                }
              },
              search: `?${queryString.stringify(search)}`,
            });
          }, 100);
        } else {
          this.setState({
            validateMessage: this.props.t("GochiBill not found"),
          });
        }
      }
    }
  }

  afterOpenUse() {
    // references are now sync'd and can be accessed.
  }

  closeUse() {
    this.setState({ useIsOpen: false, listChildfoods: [] });
  }

  openSelect() {
    const invitation = this.props.invitationGochi;
    console.log("OPEN SELECT:", invitation.gochiBillId);
    if (invitation.isAvailable === false) {
      this.setState({
        gochiUseError: true,
        gochiUseErrorMess: this.props.t(
          "Ticket unavailable"
        ),
      });
      return;
    }
	if (invitation.menu?.isETicket === 0) {
	  this.setState({
		gochiUseError: true,
		gochiUseErrorMess: this.props.t(
		"Prevent paper ticket"
		),
	  });
	  return;
	}
    if (invitation.gochiBillId) {
      this.setState({
        validateMessage: "",
      });
      this.setState({ selectIsOpen: true });
    } else {
      this.setState({
        validateMessage: this.props.t("GochiBill not found"),
      });
    }
  }

  afterOpenSelect() {
    // references are now sync'd and can be accessed.
  }

  closeSelect(e) {
    this.setState({ selectIsOpen: false });
  }

  closeErrorPopup(e) {
    this.setState({ gochiUseError: false });
    this.props.onCloseError && this.props.onCloseError();
    // window.location.reload();
  }

  componentDidMount() {}

  async handleScanQRCode(data) {
    if (!this.state.flagQrCode || this.state.gochiUseError || this.state.selectIsOpen) {
      return;
    }

    if (data) {
      this.setState({flagQrCode: false});
      try {
        data = window.atob(data);
      }
      catch {
        this.setState({
          gochiUseError: true,
          gochiUseErrorMess: this.props.t(
            "pages.Scan the QR code error 404"
          ),
        });
        this.setState({flagQrCode: true});
        return;
      }

      let invitation = this.props.invitationGochi;
      let menuId = invitation.menu?.menuId;
      const sushiroFlag = invitation.shop?.sushiroFlag || invitation.group?.sushiroFlag;
      this.setState({loading: true});
      await checkShopIdScanQrCode(menuId, parseInt(data)).then((response) => {
        this.setState({loading: false});
        let shop = response.data;
        this.props.invitationGochi.shop = {
          shopId: shop.shopId,
          shopName: shop.shopName,
        }
        if (invitation.menu?.menuCombo === 1) {
          this.openUse()
        } else {
          if (invitation.menu?.menuChildType === 3 || (invitation.gochiCount > 1 && !sushiroFlag)) {
            this.openSelect();
          } else {
            this.openUse();
          }
        }
        this.setState({flagQrCode: true});
      }).catch((error) => {
        this.setState({loading: false});
        if (error?.response?.status === 404) {
          this.setState({
            gochiUseError: true,
            gochiUseErrorMess: this.props.t(
              "pages.Scan the QR code error 404"
            ),
          });
        } else {
          this.setState({
            gochiUseError: true,
            gochiUseErrorMess: this.props.t(
              "pages.Scan the QR code error orther"
            ),
          });
        }
        this.setState({flagQrCode: true});
      });
    }
  }

  handleErrorQRCode = (err) => {
    console.error(err);
  }

  render() {
    const { t } = this.props;
    const invitation = this.props.invitationGochi;
    const isQRScan = this.props.isQRScan;
    const sushiroFlag = invitation.shop?.sushiroFlag || invitation.group?.sushiroFlag;
    const elements = [];
    for (let index = 1; index <= invitation.gochiCount; index++) {
      elements.push(<option key={index}>{index}</option>);
    }

    return (
      <div className="flex_center flex_column">
		  {!isQRScan ? 
        (invitation.menu && invitation.menu.menuCombo === 1 ? (
          invitation.menu.menuChildType !== 4 ? (
            <div
              className="btn btn-red flex_center btn-shop"
              onClick={this.openUse}
            >
              <span>{t("pages.Using this coupon")}</span>
            </div>
          ) : (
            <div
              className={
                this.props.isShopBtn
                  ? "btn btn-red no-min-height flex_center btn-shop bg-red"
                  : "btn btn-red no-min-height flex_center btn-shop"
              }
              onClick={this.openUse}
            >
              <span>{this.props.textButton || t("pages.Use this ticket")}</span>
            </div>
          )
        ) : (invitation.gochiCount === 1 && invitation.gochiType === "PRICE") ||
          (invitation.gochiCount === 1 &&
            invitation.menu &&
            invitation.menu.menuChildType !== 3) ? (
          <div
            className={
              this.props.isShopBtn
                ? "btn btn-green-38 bg-red flex_center btn-shop"
                : "btn btn-red flex_center"
            }
            onClick={this.openUse}
          >
            <span>{this.props.textButton || t("pages.Use this ticket")}</span>
          </div>
        ) : (
          <div
            className={
              this.props.isShopBtn
                ? "btn btn-green-38 bg-red flex_center btn-shop"
                : "btn btn-green-38 bg-red flex_center btn-53"
            }
            onClick={
              (invitation.menu?.menuChildType === 3 || (invitation.gochiCount > 1 && !sushiroFlag))
                ? this.openSelect
                : this.openUse
            }
          >
            <span>{this.props.textButton || t("pages.Use this ticket")}</span>
          </div>
        )) : (
        <QrReader
          delay={300}
          onError={this.handleErrorQRCode}
          onScan={this.handleScanQRCode}
          style={{ width: "100%" }}
        />
      )}

        <QuantitySelect
          selectIsOpen={this.state.selectIsOpen}
          onCloseSelectModal={() => this.setState({ selectIsOpen: false })}
          shopId={
            invitation.shopId || (invitation.shop && invitation.shop.shopId)
          }
          openUse={this.openUse}
          quantity={this.state.quantity}
          descreaseQuantity={this.descreaseQuantity}
          increaseQuantity={this.increaseQuantity}
          setQuantity={this.setQuantity}
          invitation={invitation}
        />

        <Modal
          show={this.state.gochiUseError}
          onHide={isQRScan ? (
            () => { this.setState({ gochiUseError: false })}
          ) : (
            this.closeErrorPopup
          )}
          size="sm"
          centered
        >
          <Modal.Header className="rounded-bottom align-items-start" closeLabel={false}>
            <div className="text-error-limit">
              {this.state.gochiUseErrorMess}
            </div>
            <i onClick={isQRScan ? 
              (
                () => { this.setState({ gochiUseError: false })}
              ) : (
                this.closeErrorPopup
              )} className="btn-close">
            </i>
          </Modal.Header>
        </Modal>

        <LoadingOverlay loading={this.state.loading} />
      </div>
    );
  }
}
export default connect((state) => ({ invitation: state.gochis }))(
  withRouter(withTranslation()(Gochirareru))
);
