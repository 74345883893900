import axios from 'axios';
import publicIp from 'public-ip';

export * from './GochiApi'; 
export * from './MenuApi';
export * from './DaigasApi';
export * from './SubscriptionApi';

axios.interceptors.request.use(
  async (conf) => {
    console.log('interceptor conf', conf);
    // you can add some information before send it.
    // conf.headers['Auth'] = 'some token'
    let ipAddress = '';
    try {
      ipAddress = await publicIp.v4();
    } catch (e) {
      console.log(e);
    }
    
    const lang = localStorage.getItem('lang');

    conf.headers['ip-address'] = ipAddress;
    conf.headers['acceptLanguage'] = lang;
    conf.headers['Accept'] = 'application/json';
    return conf;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    console.log('from main response', response);
    return response;
  },
  (err) => {
    console.log('from main err', err?.response);
    if (err.response && err.response.status === 503) {
      if (err.response.data && err.response.data.errorInfo && err.response.data.errorInfo.content === "SERVER_MAINTAIN") {
        return window.location.href = '/maintain';
      }
      return window.location.href = '/server-error';
    }
    return Promise.reject(err);
  }
);