import React from 'react';
import queryString from "query-string";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import LoadingOverlay from "./LoadingOverlay";
import ReactPaginate from 'react-paginate';
import { bindActionCreators } from 'redux';
import moment from "moment";
import { connect } from 'react-redux';
import history from "../history";
import {
  getListPrefectures,
  getListCities,
  getListGenres,
  getListDaigasShops
} from "../../actions/Daigas";
import {isMobileOnly} from 'react-device-detect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { DaigasFooter } from "../common/DaigasFooter";
import { DaigasNotification } from "../common/DaigasNotification";
import '../../stylesheets/Daigas.css';

class DaigasShops extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listShops: [],
      totalShops: 0,
      keyword: "",
      currentPage: 0,
      totalPages: 0,
      pageRangeDisplay: 0,
      prefectures: [],
      cities: [],
      filteredCities: [],
      genres: [],
      prefectureId: "",
      prefectureName: "",
      cityId: "",
      cityName: "",
      genreId: "",
      limit: 9,
      giftCode: "",
      currDate: moment().local().format('YYYY-MM-DD'),
      prizeDeadline: "2021-11-30",
      NO_IMAGE: '/images/gochi_noimg-ver4.jpg',
      loading: false,
      titleCustomCss: true,
      maxUsage: 5

      // messageError: this.props.t('There is no matching gochis'),
    };

    const { dispatch } = props;

    this.boundActionCreators = bindActionCreators({
      ...getListPrefectures,
      ...getListCities,
      ...getListGenres,
      ...getListDaigasShops
    }, dispatch);

    this.getPrefectures = this.getPrefectures.bind(this);
    this.getCities = this.getCities.bind(this);
    this.getGenres = this.getGenres.bind(this);
    this.handleChangePrefecture = this.handleChangePrefecture.bind(this);
    this.handleChangeCity = this.handleChangeCity.bind(this);
    this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
    this.handleOnPageChange = this.handleOnPageChange.bind(this);
    this.handleSearchShop = this.handleSearchShop.bind(this);
    this.fetchListShops = this.fetchListShops.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleImgOnError = this.handleImgOnError.bind(this);
  }

  getPrefectures() {
    let { dispatch } = this.props;
    try {
      dispatch(getListPrefectures()).then((response) => {
        console.log('res prefectures = ', response);
        if (response.type === "GET_LIST_PREFECTURES_SUCCESS") {
          this.setState({
            prefectures: response.payload
          });
        }
      });
    } catch(e) {
      console.log(e);
      this.setState({
        prefectures: []
      });
    }
  }

  getCities() {
    let { dispatch } = this.props;
    try {
      dispatch(getListCities()).then((response) => {
        console.log('res cities = ', response);
        if (response.type === "GET_LIST_CITIES_SUCCESS") {
          this.setState({
            cities: response.payload
          });
        }
      });
    } catch(e) {
      console.log(e);
      this.setState({
        cities: []
      });
    }
  }

  getGenres() {
    let { dispatch } = this.props;
    try {
      dispatch(getListGenres()).then((response) => {
        console.log('res genres = ', response);
        if (response.type === "GET_LIST_GENRES_SUCCESS") {
          this.setState({
            genres: response.payload
          });
        }
      });
    } catch(e) {
      console.log(e);
      this.setState({
        genres: []
      });
    }
  }

  handleChangePrefecture(e) {
    let prefId = e.target.value;
    let prefName = "";
    var index = e.nativeEvent.target.selectedIndex;
    if (index) {
      prefName = e.nativeEvent.target[index].text;
    }
    var tmpCities = this.state.cities.filter(item => parseInt(item.prefCode) === parseInt(prefId));
    this.setState({
      prefectureId: parseInt(prefId),
      prefectureName: prefName,
      cityId: "",
      cityName: "",
      filteredCities: tmpCities
    });
  }

  handleChangeCity(e) {
    let cId = e.target.value;
    let cName = "";
    var index = e.nativeEvent.target.selectedIndex;
    if (index) {
      cName = e.nativeEvent.target[index].text;
    }
    this.setState({
      cityId: parseInt(cId),
      cityName: cName
    });
  }

  handleChangeKeyword(value) {
    this.setState({
      keyword: value
    });
  }

  handleSearchShop() {
    this.setState({
      currentPage: 0
    });
    this.fetchListShops(
      this.state.prefectureName,
      this.state.cityName,
      this.state.genreId,
      this.state.keyword.trim(),
      1,
      this.state.limit,
      this.state.giftCode
    );
  }

  handleOnPageChange(data) {
    console.log('page ===', data.selected);
    let page = parseInt(data.selected);
    this.setState({
      currentPage: page
    });
    this.fetchListShops(
      this.state.prefectureName,
      this.state.cityName,
      this.state.genreId,
      this.state.keyword.trim(),
      page + 1,
      this.state.limit,
      this.state.giftCode
    );

    var element = document.getElementById("scrollToElementId");
    element.scrollIntoView();
  }

  fetchListShops(prefectureName, cityName, genreId, keyword, page, limit, giftCode) {
    let { dispatch } = this.props;
    let list = [];
    let total = 0;
    let totalPage = 0;
    let cPage = page;

    this.setState({
      loading: true
    });

    try {
      let actionGetListShops = getListDaigasShops(prefectureName, cityName, genreId, keyword, page, limit, giftCode);
      dispatch(actionGetListShops).then((response) => {
        console.log('res shops = ', response);
        if (response.type === "GET_LIST_DAIGAS_SHOPS_SUCCESS") {
          if (response.payload) {
            list = response.payload.list;
            total = response.payload.totalCount;
            totalPage = (response.payload.totalCount / this.state.limit);
          }
        } else if (response.type === "GET_LIST_DAIGAS_SHOPS_FAILURE") {
          if (response.payload && response.payload.status === 409) {
            window.location.href = '/daigas/input-complete';
          } else {
            list = [];
            total = 0;
            totalPage = 0;
            cPage = 0;
          }
        }
        this.setState({
          listShops: list,
          totalShops: total,
          totalPages: totalPage,
          currentPage: cPage,
          loading: false
        });
      })
    } catch(e) {
      console.log(e);
      this.setState({
        listShops: [],
        totalShops: 0,
        totalPages: 0,
        currentPage: 0,
        loading: false
      });
    }
  }

  handleImgOnError(e) {
    e.target.onerror = null;
    e.target.src = this.state.NO_IMAGE;
  }

  handleRegister(shopId) {
    // window.location.href = `/daigas/input?shopId=${shopId}&giftCode=${this.state.giftCode}`;
    history.push({
      pathname: "/daigas/input",
      search: `shopId=${shopId}&giftCode=${this.state.giftCode}`,
      data: this.state.currentPage
    });
  }

  resize() {
    if (window.innerWidth <= 576) {
      this.setState({
        pageRangeDisplay: 2,
        titleCustomCss: false
      });
    } else if (window.innerWidth <= 730) {
      this.setState({
        pageRangeDisplay: 5,
        titleCustomCss: false
      });
    } else if (window.innerWidth <= 800) {
      this.setState({
        pageRangeDisplay: 5,
        titleCustomCss: false
      });
    } else if (window.innerWidth <= 900) {
      this.setState({
        pageRangeDisplay: 6,
        titleCustomCss: true
      });
    } else {
      this.setState({
        pageRangeDisplay: 10,
        titleCustomCss: true
      });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    this.getPrefectures();
    this.getCities();
    this.getGenres();

    const params = queryString.parse(window.location.search);
    let giftCode = params.giftCode;
    if (!giftCode) {
      giftCode = "";
    }
    this.setState({
      giftCode: giftCode
    });

    let page = this.state.currentPage;
    if (this.props.location.data) {
      page = this.props.location.data - 1;
      this.setState({
        currentPage: page
      });
    }

    this.fetchListShops(
      this.state.prefectureName,
      this.state.cityName,
      this.state.genreId,
      this.state.keyword.trim(),
      page + 1,
      this.state.limit,
      giftCode
    );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {
    // console.log('props', this.props);

    return (
      <>
        {this.state.currDate <= this.state.prizeDeadline ? (
          <div className="daigas">
            <div className="content-header align-center">
              <div className="banner align-center">
                <img
                  className="banner-image"
                  src={isMobileOnly ? "/images/daigas/Group307.svg" : "/images/daigas/Group256.svg"}
                  alt=""
                />
              </div>
            </div>
    
            <div className="content-body">
              <div className="text-explain">
                当選者の皆さまが商品を選べるサイトです。<br />
                以下の手順に従ってチケットを交換してください。<br />
                受け取り期間は2021年11月30日まで <br />
                <span className="clr-CE0707 font-weight-600">チケットのご利用期限は受け取り日に関わらず2021年12月31日まで</span>です。<br />
                表示されている店舗は休業や閉店によって実際にはご利用できない店舗もございます。選択いただいた店舗以 <br />
                外での交換や返金は原則承りませんので、ご容赦ください。<br />
              </div>
    
              <div className="contact-div align-center">
                <div className="contact-us">
                  <a
                    href="https://share.hsforms.com/7433685/54ab23fb-98a0-4f52-a220-7023160b3e64"
                    className="link-contact-us font-weight-600"
                  >
                    お問い合わせ
                  </a>
                  <FontAwesomeIcon
                    icon={faCaretRight}
                    className="mr-2 fs-24"
                    style={{color: '#465184'}}
                  />
                </div>
              </div>
    
              <div className="row steps-guide">
                <div className="col-3">
                  <img
                    className="img-step"
                    src="/images/daigas/no1.svg"
                    alt="STEP 1"
                  />
                </div>
                <div className="col-3">
                <img
                    className="img-step"
                    src="/images/daigas/no2.svg"
                    alt="STEP 2"
                  />
                </div>
                <div className="col-3">
                <img
                    className="img-step"
                    src="/images/daigas/no3.svg"
                    alt="STEP 3"
                  />
                </div>
                <div className="col-3">
                <img
                    className="img-step"
                    src="/images/daigas/no4.svg"
                    alt="STEP 4"
                  />
                </div>
              </div>
    
              <div className="search-area" id="scrollToElementId">
                <div className="search-wrapper">
                  <div className="search-top align-center">
                    <img
                      className="icon-search"
                      src="/images/daigas/search-icon-2x.png"
                      alt=""
                    />
                    <div className="title-search ml-2 font-weight-600">チケットが使えるお店を検索</div>
                  </div>
                  <form
                    action=""
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.handleSearchShop();
                    }}
                  >
                    <div className="row inputs-search">
                      <select
                        className="form-controls col-2" 
                        value={this.state.prefectureId}
                        onChange={(e) => this.handleChangePrefecture(e)}
                      >
                        <option value="0">府・県を選ぶ</option>
                        {this.state.prefectures.length > 0 && this.state.prefectures.map((item, index) => {
                          return (
                            <option key={index} value={item.prefectureId}>{item.prefectureName}</option>
                          );
                        })}
                      </select>
                      <select
                        className="form-controls col-3" 
                        value={this.state.cityId}
                        onChange={(e) => this.handleChangeCity(e)}
                      >
                        <option value="">全ての市区町村</option>
                        {this.state.filteredCities.length > 0 && this.state.filteredCities.map((item, index) => {
                          return (
                            <option key={index} value={item.cityId}>{item.cityName}</option>
                          );
                        })}
                      </select>
                      <select
                        className="form-controls col-3" 
                        value={this.state.genreId}
                        onChange={(e) => {
                          this.setState({
                            genreId: e.target.value
                          });
                        }}
                      >
                        <option value="">全てのジャンル</option>
                        {this.state.genres.length > 0 && this.state.genres.map((item, index) => {
                          return (
                            <option key={index} value={item.genreId}>{item.genreName}</option>
                          );
                        })}
                      </select>
                      <input
                        className="form-controls col-3"
                        type="text"
                        name=""
                        placeholder="キーワード"
                        value={this.state.keyword}
                        onChange={(e) => this.handleChangeKeyword(e.target.value)}
                      />
                    </div>
                  </form>
                  <div className="align-center">
                    <button
                      className="btn btn-search font-weight-600"
                      onClick={this.handleSearchShop}
                    >
                      検索する
                    </button>
                  </div>
                </div>
              </div>
    
              <div className="list-shop-header">
                <div className="font-weight-600">選べるお店</div>
                <div className="shop-count ml-auto">
                  <span className="fs-35 ml-2 mr-2 font-weight-800">{this.state.totalShops}</span>
                  <span className="fs-20 ml-2 font-weight-600">件</span>
                </div>
              </div>
    
              <div className="list-shop-wrapper">
                <div className="row">
                  {this.state.listShops && this.state.listShops.length > 0 && this.state.listShops.map((item, index) => {
                    var availableUsage = this.state.maxUsage - (item.daigasUserRegisterCount || 0);
                    if (availableUsage < 0) {
                      availableUsage = 0;
                    }
                    return (
                      <div className="col-4" key={index}>
                        <div className="img-cover">
                          <div className="img-wrapper">
                            {/* <img
                              className="img-shop"
                              src={(item.shopImage && item.shopImage.length > 0 && item.shopImage[0].image) || this.state.NO_IMAGE}
                              alt=""
                              onError={(e) => this.handleImgOnError(e)}
                            /> */}
                            <LazyLoadImage
                              className="img-shop"
                              alt=""
                              effect="blur"
                              src={(item.shopImage && item.shopImage.length > 0 && item.shopImage[0].image) || this.state.NO_IMAGE}
                              onError={(e) => this.handleImgOnError(e)}
                            />
                            <div className="ticket-remain-wrapper font-weight-600">
                              <span className="ticket-remain-label">チケット残数</span>
                              <span className="ticket-remain-count ml-auto">{availableUsage}</span>
                            </div>
                          </div>
                          <div className="cover">
                            <div className={`daigas-shop-name ${this.state.titleCustomCss ? 'title-custom' : ''}`}>
                              {item.shopName}
                            </div>
                            <div className="location-shop-wrapper">
                              <img
                                className="icon-location-daigas"
                                src="/images/daigas/map-marker.svg"
                                alt=""
                              />
                              <div className={`ml-2 daigas-location-text ${this.state.titleCustomCss ? 'title-custom' : ''}`}>
                                {item.prefectures || ''}{` `}
                                {item.city || ''}{` `}
                                {item.address || ''}{` `}
                                {item.building || ''}
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          className={`btn btn-get-ticket font-weight-600 ${availableUsage < 1 ? 'btn-grey' : ''}`}
                          disabled={availableUsage < 1}
                          onClick={() => this.handleRegister(item.shopId)}
                        >
                          {availableUsage < 1 ? '売り切れました' : 'この店舗のチケットをもらう'}
                        </button>
                      </div>
                    );
                  })}
                </div>
                <LoadingOverlay loading={this.state.loading} />
              </div>
              {this.state.totalPages > 0 && (
                <ReactPaginate
                  previousLabel={'←Prev'}
                  nextLabel={'Next→'}
                  breakLabel={'...'}
                  breakClassName={'page-item'}
                  previousClassName={'page-item'}
                  nextClassName={'page-item'}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  // activeLinkClassName={'page-link'}
                  previousLinkClassName={'page-link'}
                  nextLinkClassName={'page-link'}
                  pageCount={this.state.totalPages}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={isMobileOnly ? 2 : this.state.pageRangeDisplay}
                  onPageChange={this.handleOnPageChange}
                  forcePage={this.state.currentPage - 1}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                />
              )}
              
            </div>
            <DaigasFooter />
          </div>
        ) : (
          <DaigasNotification
            title="受け取り期間は終了しました<br/>たくさんのご利用ありがとうございました"
            message=""
          />
        )}
      </>
    );
  }
}
export default connect()(DaigasShops);
