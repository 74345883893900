import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux"; 
import {
  PAYMENT_TYPE_ONEPAY_ALIPAY,
  PAYMENT_TYPE_ONEPAY_LINEPAY,
  PAYMENT_TYPE_ONEPAY_MERUPAY,
  PAYMENT_TYPE_PAYPAY,
  PAYMENT_TYPE_STRIPE,
  PAYMENT_TYPE_WECHATPAY,
} from "../../constants";
import { getShopDatas } from "../../actions/Gochis";
// import { createSession } from "../../libs/apis";
import "../../stylesheets/AmountInput.css";

// const customStyles = {
//   overlay: {
//     background: "#000000",
//     opacity: 0.5,
//     padding: 15,
//   },
//   content: {},
// };

const re = /^[0-9\b]+$/;
function AmountInput(props) {
  const { t } = props;
  const { dispatch } = props;
  const remainAmount = props.paramsMenu?.remainAmount || 0;
  let paypay;
  let alipay;
  let wechatPay;
  let linepay;
  let merupay;
  const [paypaySupport, setPaypaySupport] = useState(false);
  const [alipaySupport, setAlipaySupport] = useState(false);
  const [wechatPaySupport, setWechatPaySupport] = useState(false);
  const [linepaySupport, setLinepaySupport] = useState(false);
  const [merupaySupport, setMerupaySupport] = useState(false); 
  const [differenceAmount, setDifferenceAmount] = useState(0); 
  const taxRate = props.taxRate;
  const feeRate = props.feeRate;

  function onChangePaymentMethod(e) {
    props.setPaymentMethod(Number(e.target.value));
  }

  function onChangeTaxAmountType(e) {
    props.setTaxAmountType(e.target.value);
    onChangeInputAmount(props.inputAmount, e.target.value);
  }

  function onChangeInputAmount(inputAmount, taxType) {
    props.setInputAmount(inputAmount);
    const useAmount =
      taxType === "INCLUDED"
        ? inputAmount
        : Math.round(inputAmount * (1 + Number(taxRate)));
    const added =
    (props.paramsMenu?.invitation?.menu?.menuChildType === 5 ? props.paramsMenu?.invitation?.menuChildType5?.amountCanUse : remainAmount) >= useAmount
      ? 0
      : Math.round((useAmount - (props.paramsMenu?.invitation?.menu?.menuChildType === 5 ? props.paramsMenu?.invitation?.menuChildType5?.amountCanUse : remainAmount)) * (1 + Number(feeRate))); 
      console.log('taxType useAmount', taxType, useAmount);

    setDifferenceAmount(useAmount - remainAmount);
    if (inputAmount <=0) {
      props.setErrorMessage(t('pages.Please enter the amount correctly'));
    } else {
      if (added > 0 && process.env.REACT_APP_ENABLED_EXCEEDED_AMOUNT_PAYMENT === 'false') {
        props.setErrorMessage(t('pages.Please enter the amount within the balance')); 
      } else {
        props.setErrorMessage('');
      } 
    }
    props.setUseAmount(useAmount);
    props.setAddedAmount(added);  
  }

  
  function getShopPaymentTypes(shopId) {
    dispatch(getShopDatas(shopId)).then((response) => {
      if (response.type === "GET_SHOP_INFO_SUCCESS") {
        const paymentTypes = response.payload?.paymentTyptes;
        if (paymentTypes?.length > 0) {
          paypay = paymentTypes.find(el => el.paymentType === PAYMENT_TYPE_PAYPAY)
            && paymentTypes.find(el => el.paymentType === PAYMENT_TYPE_PAYPAY).status === 1 
            ? true : false;
		
          alipay = paymentTypes.find(el => el.paymentType === PAYMENT_TYPE_ONEPAY_ALIPAY)
            && paymentTypes.find(el => el.paymentType === PAYMENT_TYPE_ONEPAY_ALIPAY).status === 1 
            ? true : false;
          
          wechatPay = paymentTypes.find(el => el.paymentType === PAYMENT_TYPE_WECHATPAY)
            && paymentTypes.find(el => el.paymentType === PAYMENT_TYPE_WECHATPAY).status === 1 
            ? true : false;

          linepay = paymentTypes.find(el => el.paymentType === PAYMENT_TYPE_ONEPAY_LINEPAY)
            && paymentTypes.find(el => el.paymentType === PAYMENT_TYPE_ONEPAY_LINEPAY).status === 1 
            ? true : false;

          merupay = paymentTypes.find(el => el.paymentType === PAYMENT_TYPE_ONEPAY_MERUPAY)
            && paymentTypes.find(el => el.paymentType === PAYMENT_TYPE_ONEPAY_MERUPAY).status === 1 
            ? true : false;

          setPaypaySupport(paypay);
          setAlipaySupport(alipay);
          setWechatPaySupport(wechatPay);
          setLinepaySupport(linepay);
          setMerupaySupport(merupay);
        }
      } 
    });
  }

  useEffect(() => {
    getShopPaymentTypes(props.paramsMenu?.shopId);
    console.log('props.paramsMenu', props.paramsMenu);
    // comment code because don't need check validate input null when into screen
    // onChangeInputAmount(props.paramsMenu?.inputAmount, props.paramsMenu?.taxAmountType);
  }, []); 

  return (
    <div className="amount-input">
      <div className="input-field-set">
        <div className="required-dot mb-3">
          <div className="dot-red"></div>
          <div className="dot-text-label fs-18">
            {/* {t("pages.amount_to_use")} */}
            {t("pages.amount_to_use_include_tax")}
          </div>
        </div>
        {/* Hide tax when is menu limit total */}
        {/* <div className="d-flex">
          <label className="radio-custom fs-14">
            <input
              type="radio"
              name="paymentType"
              value="INCLUDED"
              onChange = {onChangeTaxAmountType}
              checked={props.taxAmountType === 'INCLUDED'}
            />
            <span className="checkmark"></span>
            <span>{t("pages.tax_included_amount")}</span>
          </label>
          <label className="radio-custom fs-14 ml-2">
            <input
              type="radio"
              name="paymentType"
              value="EXCLUDED"
              onChange = {onChangeTaxAmountType}
              checked={props.taxAmountType === 'EXCLUDED'}
            />
            <span className="checkmark"></span>
            <span>{t("pages.tax_excluded_amount")}</span>
          </label>
        </div> */}
        <div className="d-flex w-100 align-items-center mt-3">
          <input
            onChange={(e) => {
              if (e.target.value === "" || re.test(e.target.value)) {
                onChangeInputAmount(e.target.value, props.taxAmountType);
              }
            }}
            onWheel={(e) => e.target.blur()}
            type="number"
            min="0"
            inputmode="numeric"
            pattern="[0-9]*"
            className="amount-text-input form-control mr-2 text-right"
            value={props.inputAmount}
          />
          <span className="fs-18">円</span>
        </div>
        
        {differenceAmount > 0 && process.env.REACT_APP_ENABLED_EXCEEDED_AMOUNT_PAYMENT === 'true' && (
          <div className="diff-amount">
            <div className="diff-num">
              <span className="fs-15 text-black">{t('pages.difference')}</span>
              <span className="fs-44 diff-no">{differenceAmount?.toLocaleString()}</span>
              <span>円</span>
            </div>
          </div>
        )}
        {props.errorMessage && (
          <div className="text-red mt-1">
            {props.errorMessage}
          </div>
        )}

        {props.addedAmount > 0 && process.env.REACT_APP_ENABLED_EXCEEDED_AMOUNT_PAYMENT === 'true' && (
          <div className="grey-box">
            <div className="bill-amount">
              <span className="fs-18">{t("Bill amount")}</span>
              <span className="fs-16" style={{ lineHeight: 1 }}>
                <span className="fs-30 font-weight-bold">
                  {props.addedAmount.toLocaleString()}
                </span>
                円
              </span>
            </div>
            <div className="pm-box">
              <div className="required-dot mb-3">
                <div className="dot-red"></div>
                <div className="dot-text-label fs-18">{t("Payment method")}</div>
                <div></div>
              </div>
              <div className="d-flex flex-column">
                {paypaySupport && (
                  <label className="radio-custom fs-14">
                    <input
                      type="radio"
                      name="paymentMethod"
                      value={PAYMENT_TYPE_PAYPAY}
                      checked={props.paymentMethod === PAYMENT_TYPE_PAYPAY}
                      onChange={onChangePaymentMethod}
                    />
                    <span className="checkmark"></span>
                    <span>{t("payment paypay")}</span>
                  </label>
                )}
                {alipaySupport && (
                  <label className="radio-custom fs-14">
                    <input
                      type="radio"
                      name="paymentMethod"
                      value={PAYMENT_TYPE_ONEPAY_ALIPAY}
                      checked={(props.paymentMethod || props.paramsMenu?.paymentMethod) === PAYMENT_TYPE_ONEPAY_ALIPAY}
                      onChange={onChangePaymentMethod}
                    />
                    <span className="checkmark"></span>
                    <span>{t("payment alipay")}</span>
                  </label>
                )}
                {props.paymentMethod === PAYMENT_TYPE_ONEPAY_ALIPAY && (
                  <div className="payment-warning-message">
                    {t("alipay warning")}
                  </div>
                )}
                {wechatPaySupport && (
                  <label className="radio-custom fs-14">
                    <input
                      type="radio"
                      name="paymentMethod"
                      value={PAYMENT_TYPE_WECHATPAY}
                      checked={(props.paymentMethod || props.paramsMenu?.paymentMethod) === PAYMENT_TYPE_WECHATPAY}
                      onChange={onChangePaymentMethod}
                    />
                    <span className="checkmark"></span>
                    <span>{t("payment wechatpay")}</span>
                  </label>
                )}
                {props.paymentMethod === PAYMENT_TYPE_WECHATPAY && (
                  <div className="payment-warning-message">
                    {t("wechatpay warning")}
                  </div>
                )}
                {linepaySupport && (
                  <label className="radio-custom fs-14">
                    <input
                      type="radio"
                      name="paymentMethod"
                      value={PAYMENT_TYPE_ONEPAY_LINEPAY}
                      checked={(props.paymentMethod || props.paramsMenu?.paymentMethod) === PAYMENT_TYPE_ONEPAY_LINEPAY}
                      onChange={onChangePaymentMethod}
                    />
                    <span className="checkmark"></span>
                    <span>{t("payment linepay")}</span>
                  </label>
                )}
                {props.paymentMethod === PAYMENT_TYPE_ONEPAY_LINEPAY && (
                  <div className="payment-warning-message">
                    {t("linepay warning")}
                  </div>
                )}
                {merupaySupport && (
                  <label className="radio-custom fs-14">
                    <input
                      type="radio"
                      name="paymentMethod"
                      value={PAYMENT_TYPE_ONEPAY_MERUPAY}
                      checked={(props.paymentMethod || props.paramsMenu?.paymentMethod) === PAYMENT_TYPE_ONEPAY_MERUPAY}
                      onChange={onChangePaymentMethod}
                    />
                    <span className="checkmark"></span>
                    <span>{t("merupay linepay")}</span>
                  </label>
                )}
                {props.paymentMethod === PAYMENT_TYPE_ONEPAY_MERUPAY && (
                  <div className="payment-warning-message">
                    {t("merupay warning")}
                  </div>
                )}
                <label className="radio-custom fs-14">
                  <input
                    type="radio"
                    name="paymentMethod"
                    value={PAYMENT_TYPE_STRIPE}
                    checked={(props.paymentMethod || props.paramsMenu?.paymentMethod) === PAYMENT_TYPE_STRIPE}
                    onChange={onChangePaymentMethod}
                  />
                  <span className="checkmark"></span>
                  <span>{t("payment other")}</span>
                </label>
              </div>
            </div>
          </div>
        )}
      </div>

      <div onClick={props.onSubmitInput} className="btn btn-green-38 flex_center btn-open-booking mt-2">
        {t("pages.proceed_to_order")}
      </div>

      <div className="text-center">
        <button 
          className="btn btn-link btn-underline" 
          onClick={() => {
            window.location.href = `/gift?code=${props.invitationCode}`;
          }} 
        >
          {t("pages.Cancel")}
        </button>
      </div> 
    </div>
  );
}

const mapStateToProps = (state) => ({ invitation: state.gochis });

export default connect(mapStateToProps)(withTranslation()(AmountInput));
