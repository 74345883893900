import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import "../../../stylesheets/GochiBooking.css";

class GochiBooking extends Component {
  openOpenBookingPopup() {
    this.props.handleOpenBookingPopup();
  }
  render() {
    const { t } = this.props;
    const invitation = this.props.invitationGochi;
    return (
      invitation && invitation.bookingEnabled === 1 && 
      (
        <div
          className="btn btn-green-38 flex_center btn-open-booking mt-2"
          onClick={() => this.openOpenBookingPopup()}
        >
          <span>{t("pages.Booking_ticket")}</span>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  invitation: state.gochis,
});

export default connect(mapStateToProps)(
  withRouter(withTranslation()(GochiBooking))
);
