import React from "react";
import HamburgerMenu from "../pages/HamburgerMenu";

function Header(props) {
  return (
    <div className="title-new title-with-buger-new">
      {/* {props.hasBack && (
        <button
          type="button"
          className="icon-btn-back icon-vertical"
          onClick={() => props.backAction? props.backAction () : window.history.back()}
        >
          <i className="arrow left width-15"></i>
        </button>
      )} */}
      <i className="logo-service" />
      <span className="cut-text ml-2 fs-12">{props.title}</span>
      {props.showHamburgerMenu && (
        <HamburgerMenu />
      )}
    </div>
  );
}

export default Header;
