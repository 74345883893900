import React from 'react';
import { withTranslation } from "react-i18next";
import '../../stylesheets/CommingSoon.css'

const bgStyle = {
  backgroundColor: "#ffffff"
}

class CommingSoon extends React.Component {
 
  render() {
    const { t } = this.props; 
    return(
      <div className="comming-soon-box" style={bgStyle}>
        <div className="gochimeshi-logo"></div>
        <div>
          <span className="message-text">{t('pages.Preparation')}</span>
        </div>
        {/* <div>
          <a href="https://www.gochimeshi.com/">ごちめしとは？</a>
        </div> */}

      </div>
    );
  }
}
export default (withTranslation()(CommingSoon));
