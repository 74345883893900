import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { getInvitations } from "../../../actions/Gochis";
import GochiNotFound from "./../GochiNotFound";
import { getListShops, getListShopsBooking } from "../../../actions/Menus";
import ListShop from "./ListShop";
import { numberFormatter } from "../../../libs/CommonUtil";
import "react-datepicker/dist/react-datepicker.css";
import "../../../stylesheets/GochiBooking.css";
import MenuInfo from "../../common/MenuInfo";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import LoadingOverlay from "../LoadingOverlay";
registerLocale("ja", ja);

const phoneRegex = new RegExp("^[0-9]{9,11}$");

const emailRegex = new RegExp(
  /^(("[\w+-\s]+")|([\w+-]+(?:\.[\w+-]+)*)|("[\w+-\s]+")([\w+-]+(?:\.[\w+-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);

class BookingTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCalendar: false,
      listShop: [],
      shopCount: 0,
      showListShopPopup: false,
      shopId: this.props.invitation.shopId,
      shopName: this.props.invitation.shopName,
      bookingDate: undefined,
      phoneNumber: "",
      email: "",
      message: "",
      bookingName: "",
      bookingDateError: "",
      keyword: null,
      shopError: "",
      phoneError: "",
      emailError: "",
      messageError: "",
      bookingNameError: "",
    };
    const { dispatch } = props;

    this.boundActionCreators = bindActionCreators(
      { ...getInvitations, ...getListShops },
      dispatch
    );
    this.refreshPage = this.refreshPage.bind(this);
    this.loadMoreShop = this.loadMoreShop.bind(this);
    this.handleCloseListShopPopup = this.handleCloseListShopPopup.bind(this);
    this.handleOpenListShopPopup = this.handleOpenListShopPopup.bind(this);
    this.descreaseQuantity = this.descreaseQuantity.bind(this);
    this.changeQuantity = this.changeQuantity.bind(this);
    this.increaseQuantity = this.increaseQuantity.bind(this);
    this.changeBookingDate = this.changeBookingDate.bind(this);
    this.changePhoneNumber = this.changePhoneNumber.bind(this);
    this.changeMessage = this.changeMessage.bind(this);
    this.openConfirm = this.openConfirm.bind(this);
    this.changeBookingTime = this.changeBookingTime.bind(this);
    this.changeBookingName = this.changeBookingName.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.validBookingName = this.validBookingName.bind(this);
    this.validBookingTime = this.validBookingTime.bind(this);
    this.validEmail = this.validEmail.bind(this);
    this.validMessage = this.validMessage.bind(this);
    this.validPhone = this.validPhone.bind(this);
    this.validShop = this.validShop.bind(this);
    this.setKeyword = this.setKeyword.bind(this);
    this.searchKeyword = this.searchKeyword.bind(this);
    this.handleSelectShop = this.handleSelectShop.bind(this);
  }

  refreshPage() {
    window.location.reload(false);
  }

  getListShopsArr = ({ menuId, latitude, longitude, sort, keyword, page }) => {
    this.setState({
      loading: true,
    });
    let { dispatch } = this.props;
    try {
      dispatch(
        getListShopsBooking({
          menuId,
          latitude,
          longitude,
          sort,
          keyword,
          page,
        })
      ).then((res) => {
        console.log(res);
        if (res.type === "GET_BOOKING_SHOPS_SUCCESS") {
          this.setState({
            loading: false,
          });
          const listShop = this.state.listShop;
          if (res.payload.list) {
            this.setState({
              listShop: [...listShop, ...res.payload.list],
              shopCount: res.payload.count,
              page,
              hasMore:
                listShop.length + res.payload.list.length < res.payload.count,
            });
          }
        }
      });
    } catch (error) {
      console.log("error getListShops", error);
      this.setState({
        loading: false,
      });
    }
  };

  loadMoreShop() {
    try {
      this.getListShopsArr({
        menuId: this.props.invitation.menuId,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        sort: this.state.sort,
        keyword: this.state.keyword,
        page: this.state.page + 1,
      });
      this.setState({
        page: this.state.page + 1,
      });
    } catch (error) {
      console.log("error loadMoreShop", error);
    }
  }

  async searchKeyword() {
    await this.setState({
      page: 1,
    });
    await this.setState({
      listShop: []
    });
    try {
      this.getListShopsArr({
        menuId: this.props.invitation.menuId,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        sort: this.state.sort,
        keyword: this.state.keyword,
        page: this.state.page,
      });
    } catch (error) {
      console.log("error loadMoreShop", error);
    }
  }

  setKeyword(event) {
    var value = event.target.value;
    console.log(value);
    this.setState({ keyword: value });
  }

  handleCloseListShopPopup(shop) {
    this.setState({
      showListShopPopup: false,
    });
  }

  handleSelectShop(shop) {
    this.setState({
      showListShopPopup: false,
      shopId: shop.shopId,
      shopName: shop.shopName,
    });
  }

  handleOpenListShopPopup() {
    this.setState({
      showListShopPopup: true,
    });
  }

  increaseQuantity() {
    const invitation = this.props.invitation;
    this.setState((state) => {
      if (state.quantity === "") return { quantity: 1 };
      if (state.quantity < invitation.gochiCount)
        return { quantity: state.quantity + 1 };
      return { quantity: state.quantity };
    });
  }

  changeQuantity(quantity) {
    const invitation = this.props.invitation;
    if (quantity === "") {
      this.setState({ quantity: "" });
    } else if (quantity < 1) {
      this.setState({ quantity: 1 });
    } else if (quantity > invitation.gochiCount) {
      this.setState({ quantity: invitation.gochiCount });
    } else {
      this.setState({ quantity });
    }
  }

  descreaseQuantity() {
    this.setState((state) => {
      if (state.quantity === "") return { quantity: 1 };
      if (state.quantity > 1) return { quantity: state.quantity - 1 };
      return { quantity: state.quantity };
    });
  }

  changeBookingDate(inputDate) {
    console.log("changeBookingDate", inputDate);
    if (!inputDate) {
      this.setState({
        bookingDate: undefined,
      });
      return;
    }
    const date = new Date(inputDate);
    const newVal = new Date(this.state.bookingDate);
    newVal.setFullYear(date.getFullYear());
    newVal.setMonth(date.getMonth());
    newVal.setDate(date.getDate());
    this.setState({ bookingDate: newVal });
    this.setState({ openCalendar: false });
  }

  changeBookingTime(e) {
    let hour, min;
    if (!e.target.value) {
      hour = "00";
      min = "00";
    } else {
      hour = Number(e.target.value.split(":")[0]);
      min = Number(e.target.value.split(":")[1]);
    }
    const newVal = this.state.bookingDate
      ? new Date(this.state.bookingDate)
      : new Date();
    newVal.setHours(hour);
    newVal.setMinutes(min);
    console.log("set new time: ", newVal);
    this.setState({ bookingDate: newVal });
  }

  changePhoneNumber(phoneNumber) {
    this.setState({
      phoneNumber: phoneNumber,
    });
  }

  changeBookingName(name) {
    this.setState({
      bookingName: name,
    });
  }

  changeEmail(email) {
    this.setState({
      email: email,
    });
  }

  changeMessage(message) {
    this.setState({
      message: message,
    });
  }

  resetForm() {
    this.setState({
      openCalendar: false,
      showListShopPopup: false,
      // quantity: oldInputData === undefined ? 1 : oldInputData.quantity,
      shopId: this.props.invitation.shopId || null,
      shopName: this.props.invitation.shopName || null,
      bookingDate: undefined,
      phoneNumber: "",
      email: "",
      message: "",
      bookingName: "",
      bookingDateError: "",
      shopError: "",
      phoneError: "",
      emailError: "",
      messageError: "",
      bookingNameError: "",
    });
  }

  validBookingTime() {
    const current = new Date();
    let isValid = true;
    if (!this.props.invitation.gochiExpired || !this.state.bookingDate) {
      this.setState({ bookingDateError: "" });
      return true;
    }

    const expiredDate = new Date(this.props.invitation.gochiExpired);
    const startDate = new Date(this.props.invitation.gochiStartDate);
    if (this.state.bookingDate > expiredDate || this.state.bookingDate < startDate) {
      this.setState({
        bookingDateError: this.props.t("pages.booking_time_validation_message"),
      });
      isValid = false;
    } else if (this.state.bookingDate <= current) {
      isValid = false;
      this.setState({
        bookingDateError: "正しい日時を入力してください",
      });
    }
    if (isValid) {
      this.setState({ bookingDateError: "" });
    }

    return isValid;
  }

  validPhone() {
    if (!this.state.phoneNumber) {
      this.setState({
        phoneError: "この項目は必須です",
      });
      return false;
    }
    if (!phoneRegex.test(this.state.phoneNumber)) {
      this.setState({
        phoneError: "形式が正しくありません",
      });
      return false;
    }
    this.setState({ phoneError: "" });
    return true;
  }

  validEmail() {
    if (!this.state.email) {
      this.setState({
        emailError: "この項目は必須です",
      });
      return false;
    }
    if (!emailRegex.test(this.state.email)) {
      this.setState({
        emailError: "形式が正しくありません",
      });
      return false;
    }
    this.setState({ emailError: "" });
    return true;
  }

  validBookingName() {
    const isValid = this.state.bookingName.length > 0;
    if (isValid) {
      this.setState({ bookingNameError: "" });
    } else {
      this.setState({
        bookingNameError: "この項目は必須です",
      });
    }
    return isValid;
  }

  validShop() {
    if (this.state.shopId) {
      this.setState({ shopError: "" });
      return true;
    }
    this.setState({
      shopError: this.props.t("pages.booking_shop_validation_message"),
    });
    return false;
  }

  validMessage() {
    const isValid = this.state.message.length <= 200;
    if (isValid) {
      this.setState({ messageError: "" });
    } else {
      this.setState({
        messageError: this.props.t("pages.booking_message_validation_message"),
      });
    }
    return isValid;
  }

  async getCoords() {
    var options = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };

    this.setState({
      loading: true,
    });

    var p1 = new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (success) => {
          return resolve(success);
        },
        (error) => {
          console.log("error========", error);
          if (error.code === 1) {
            return resolve(null);
          } else {
            return reject(error);
          }
        },
        options
      );
      setTimeout(() => {
        resolve(null);
      }, 11000);
    });
    var result = await p1;

    this.setState({
      loading: false,
    });

    return result;
  }

  async componentDidMount() {
    if (!this.props.invitation.groupId) {
      return;
    }
    this.setState({
      loading: true,
    });

    let latitude = null;
    let longitude = null;

    if (navigator.geolocation) {
      try {
        const position = await this.getCoords();
        console.log("ggg=====", position);

        if (position && position.coords) {
          latitude = position.coords.latitude;
          longitude = position.coords.longitude;
        }
        this.setState({
          loading: false,
        });
      } catch (e) {
        this.setState({
          loading: false,
        });
        console.log("======================", e);
      }
    } else {
      this.setState({
        loading: false,
      });
    }

    this.setState({
      latitude: latitude,
      longitude: longitude,
    });

    this.getListShopsArr({
      menuId: this.props.invitation.menuId,
      page: 1,
      latitude: latitude,
      longitude: longitude,
      sort: this.state.sort,
    });
  }

  openConfirm() {
    if (!this.state.policyCheck) {
      return;
    }
    let invalidCount = 0;
    if (!this.validPhone()) {
      invalidCount++;
    }
    if (!this.validMessage()) {
      invalidCount++;
    }
    if (!this.validShop()) {
      invalidCount++;
    }
    if (!this.validBookingTime()) {
      invalidCount++;
    }
    if (!this.validEmail()) {
      invalidCount++;
    }
    if (!this.validBookingName()) {
      invalidCount++;
    }
    if (invalidCount > 0) return;
    var inputData = {
      shopId: this.state.shopId,
      shopName: this.state.shopName,
      bookingDate: this.state.bookingDate,
      phoneNumber: this.state.phoneNumber,
      email: this.state.email,
      message: this.state.message,
      bookingName: this.state.bookingName,
    };
    if (this.props.modalView) {
      this.props.changeStepBooking("CONFIRM", inputData);
      return;
    }
  }

  render() {
    let { invitation } = this.props;
    var query = new URLSearchParams(window.location.search);
    var invitationCode = query.get("code");
    if (
      this.geted &&
      (invitation.gochiBillId === undefined || invitation.gochiBillId === 0) &&
      invitation.status &&
      invitation.status.status === 404
    ) {
      return <GochiNotFound />;
    } else {
      var bookingQuantity = invitation.gochiCount
        ? numberFormatter(invitation.gochiCount)
        : 0;
      var expiredDate = new Date(invitation.gochiExpired);
      return this.props.stepBooking === "INPUT" ? (
        <>
          <div className="gochi">
            <div className="title-new title-with-buger">
              {this.props.t("pages.Booking_form")}
              {this.props.modalView && (
                <button
                  type="button"
                  className="icon-btn-back icon-vertical"
                  onClick={this.props.handleCloseBookingPopup}
                >
                  <i className="icon-close"></i>
                </button>
              )}
            </div>

            <div className="mt-3">
              <ul className="progressbar align-center">
                <li className="step-choice active">
                  <strong>STEP 1</strong>
                </li>
                <li className="step-select">
                  <strong>STEP 2</strong>
                </li>
                <li className="step-confirm">
                  <strong>STEP 3</strong>
                </li>
              </ul>
            </div>
            <div className="text-center my-3 bold fs-14">
              {this.props.t("pages.Booking_form")}
            </div>

            <MenuInfo
              noMultiLang
              showShopName
              showUsedAmount
              usedQuantity={1}
              menuLimitTotal={invitation?.menuLimitTotal === 1} 
              hideAmount
            />

            {invitation.gochiExpired && (
              <>
                <div className="fs-14 bold">
                  {this.props.t("pages.Booking_title_expiration")}
                </div>
                <div className="message-row d-flex align-items mt-2">
                  <div className="expired-date fs-22">
                    {invitation.gochiExpired &&
                      moment(invitation.gochiExpired).format(
                        "YYYY年MM月DDまで"
                      )}
                  </div>
                </div>
              </>
            )}

            <div>
              <div className="fs-17 bold text-center mt-2">
                {this.props.t("pages.Booking_title_contact")}
              </div>
              <div className="contact-guide">
                {this.props.t("pages.Booking_title_contact_guide")}
              </div>
            </div>

            <div className="booking-body">
              {this.props.invitation.groupId && (
                <div className="booking-body-fieldset">
                  <div className="text-red fs-12">
                    {this.props.t("Required")}
                  </div>
                  <div className="d-flex mt-2 align-items-center">
                    <div className="left-title">
                      {this.props.t("pages.Booking_input_shop_title")}
                    </div>
                    <div className="right-input-field">
                      <div
                        className="list-shop-booking"
                        onClick={() => {
                          this.handleOpenListShopPopup();
                        }}
                      >
                        <span className="shop-name-ellipsis">
                        {this.state.shopName ||
                          this.props.t("pages.Booking_input_shop")}
                        </span>
                      </div>
                    </div>
                  </div>
                  {this.state.shopError && (
                    <div className="booking-date-error">
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        size="lg"
                        color="#F44A4A"
                      />
                      <div style={{ marginLeft: 10, color: "#EC4A56" }}>
                        {this.state.shopError}
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="booking-body-fieldset">
                <div className="d-flex mt-2 align-items-center">
                  <div className="left-title">
                    {this.props.t("pages.Booking_input_date_title")}
                  </div>
                  <div className="right-input-field">
                    <div className="date-input-field">
                      <DatePicker
                        className="date-picker-field"
                        selected={this.state.bookingDate}
                        onChange={(date) => this.changeBookingDate(date)}
                        timeIntervals={30}
                        minDate={new Date()}
                        maxDate={expiredDate}
                        dateFormat="yyyy年MM月dd"
                        locale="ja"
                        open={this.state.openCalendar}
                        onCalendarClose={() =>
                          this.setState({ openCalendar: false })
                        }
                        onCalendarOpen={() =>
                          this.setState({ openCalendar: true })
                        }
                        onClickOutside={() =>
                          this.setState({ openCalendar: false })
                        }
                      />
                      <div
                        onClick={() => this.setState({ openCalendar: true })}
                        className="right-field-icon"
                      >
                        <FontAwesomeIcon icon={faCalendarAlt} size="sm" />
                      </div>
                    </div>
                    <div className="time-input-field">
                      <input
                        onChange={this.changeBookingTime}
                        value={
                          this.state.bookingDate
                            ? moment(this.state.bookingDate).format("HH:mm")
                            : ""
                        }
                        type="time"
                        className="time-picker-field"
                      />
                      {/* <div className="right-field-icon">
                        <FontAwesomeIcon icon={faCalendarAlt} size="sm" />
                      </div> */}
                    </div>
                  </div>
                </div>
                {this.state.bookingDateError && (
                  <div className="booking-date-error">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      size="lg"
                      color="#F44A4A"
                    />
                    <div style={{ marginLeft: 10, color: "#EC4A56" }}>
                      {this.state.bookingDateError}
                    </div>
                  </div>
                )}
              </div>
              <div className="booking-body-fieldset">
                <div className="text-red fs-12">{this.props.t("Required")}</div>
                <div className="d-flex mt-2 align-items-center">
                  <div className="left-title">
                    {this.props.t("pages.Booking_input_email_title")}
                  </div>
                  <div className="right-input-field">
                    <input
                      autoComplete="email"
                      inputMode="email"
                      className="booking-input"
                      type="text"
                      value={this.state.email}
                      onChange={(e) => {
                        this.changeEmail(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="booking-email-guide">
                  {this.props.t("pages.Booking_email_guide")}
                </div>
                {this.state.emailError && (
                  <div className="booking-date-error">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      size="lg"
                      color="#F44A4A"
                    />
                    <div style={{ marginLeft: 10, color: "#EC4A56" }}>
                      {this.state.emailError}
                    </div>
                  </div>
                )}
              </div>
              <div className="booking-body-fieldset">
                <div className="text-red fs-12">{this.props.t("Required")}</div>
                <div className="d-flex mt-2 align-items-center">
                  <div className="left-title">
                    {this.props.t("pages.Booking_input_phone_title")}
                  </div>
                  <div className="right-input-field">
                    <input
                      autoComplete="tel"
                      className="booking-input"
                      inputMode="tel"
                      type="text"
                      maxLength={11}
                      value={this.state.phoneNumber}
                      onChange={(e) => {
                        this.changePhoneNumber(e.target.value);
                      }}
                    />
                  </div>
                </div>
                {this.state.phoneError && (
                  <div className="booking-date-error">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      size="lg"
                      color="#F44A4A"
                    />
                    <div style={{ marginLeft: 10, color: "#EC4A56" }}>
                      {this.state.phoneError}
                    </div>
                  </div>
                )}
              </div>
              <div className="booking-body-fieldset">
                <div className="text-red fs-12">{this.props.t("Required")}</div>
                <div className="d-flex mt-2 align-items-center">
                  <div className="left-title">
                    {this.props.t("pages.Booking_username_title")}
                  </div>
                  <div className="right-input-field">
                    <input
                      inputMode="text"
                      autoComplete="name"
                      className="booking-input"
                      type="text"
                      value={this.state.bookingName}
                      maxLength={25}
                      onChange={(e) => {
                        this.changeBookingName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                {this.state.bookingNameError && (
                  <div className="booking-date-error">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      size="lg"
                      color="#F44A4A"
                    />
                    <div style={{ marginLeft: 10, color: "#EC4A56" }}>
                      {this.state.bookingNameError}
                    </div>
                  </div>
                )}
              </div>
              <div className="booking-body-fieldset no-border-bottom">
                <div className="d-inline-block">
                  <div className="title">
                    {this.props.t("pages.Booking_input_message_title")}
                  </div>
                </div>
                <div className="fieldset-body">
                  <textarea
                    className="booking-text-box"
                    value={this.state.message}
                    rows={3}
                    maxLength={200}
                    onChange={(e) => {
                      this.changeMessage(e.target.value);
                    }}
                  />
                </div>
                {this.state.messageError && (
                  <div className="validation-alert">
                    {this.state.messageError}
                  </div>
                )}
              </div>
              
              <div className="mt-3 gochi-policy-check">
                <label class="checkbox-custom">
                    <input checked={this.state.policyCheck} onChange={e => this.setState({policyCheck: e.target.checked})} id="policy-check" type="checkbox" class="" name="question"/>
                    <span class="checkmark"></span>
                    <a href='https://gochimeshi.com/policy/terms/' target='_blank' rel='noopener noreferrer'>
												{this.props.t('pages.Gochi terms')}
											</a>
											{this.props.t('pages.and')}
											<a href='https://gochimeshi.com/policy/privacy/' target='_blank' rel='noopener noreferrer'>
												{this.props.t('pages.Gochi privacy policy')}
											</a>
											{this.props.t('pages.Agree to')} 
                </label> 
              </div>

              <div className="flex_center flex_column">
                <div
                  className={"btn btn-dark-green flex_center btn-booking-confirm" + (this.state.policyCheck ? "" : " disabled-div")}
                  onClick={this.openConfirm}
                >
                  <span>{this.props.t("pages.Booking_ticket_confirm")}</span>
                </div>
                <div
                  className="border-bt new-btn btn-white-green flex_center mt-3"
                  onClick={this.resetForm}
                >
                  <span>{this.props.t("pages.Booking_reset_form")}</span>
                </div>
              </div>
            </div>
          </div>
          <ListShop
            hasMore={this.state.hasMore}
            loadMoreShop={this.loadMoreShop}
            listShop={this.state.listShop}
            menuId={this.props.invitation.menuId}
            showListShopPopup={this.state.showListShopPopup}
            handleCloseListShopPopup={this.handleCloseListShopPopup}
            onSelect={this.handleSelectShop}
            invitation={invitation}
            setKeyword={this.setKeyword}
            keyword={this.state.keyword}
            searchKeyword={this.searchKeyword}
            invitationCode={invitationCode}
            shopCount={this.state.shopCount}
          />
          <LoadingOverlay loading={this.state.loading} />
        </>
      ) : null;
    }
  }
}
export default connect((state) => ({ invitation: state.gochis }))(
  withTranslation()(BookingTicket)
);
