import React from "react";
import { DaigasNotification } from "../common/DaigasNotification";

function DaigasFinish(props) {
  return (
    <DaigasNotification
      title="送信が完了しました"
      message="メールが届かない場合は迷惑メールフォルダを確認してください。
        <br/>24時間経ってもメールが届かない場合は<a href='https://share.hsforms.com/7433685/54ab23fb-98a0-4f52-a220-7023160b3e64'>お問い合わせフォーム</a>よりお問い合わせください。"
    />
  );
}

export default DaigasFinish;
