import React from "react";
import { withTranslation } from "react-i18next";
import Moment from "react-moment";
import { connect } from "react-redux";

function OrderButton(props) {
  const { t } = props;
  const { invitation } = props;
  const isShopUsing = window.location.pathname === '/shop/ticket-use' ? true : false;
  return (
    <>
      <div className="order-btn-container">
        <div>
          <span className="label_18 flex_center text-black">
            {t("pages.Button for shop only")}
          </span>
        </div>
        <div className="order_btn" onClick={props.openConfirm}>
          <span className="font-weight-bold fs-16 white_text">
            {t("pages.Order confirmation button")}
          </span>
          <span className="font-weight-bold fs-16 white_text text-underline">
            <Moment interval={1000} format="YYYY.MM.DD HH:mm:ss" local />
          </span>
        </div>
        {!isShopUsing && (
          <div
            className="text-red mt-2 fs-14 bold pl-5 text-center i18n text-left"
            dangerouslySetInnerHTML={{
              __html: t(
                "pages.Please be careful not to click the button except for the store staff",
                { interpolation: { escapeValue: false } }
              ),
            }}
          />
        )}
        <div className="gochiBillNo">{`${t("pages.Receipt number title")}${invitation.gochiBillNo}`}</div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({ invitation: state.gochis });

export default connect(mapStateToProps)(withTranslation()(OrderButton));
