import * as gochisActions from '../actions/Gochis';

const initialState = {
  gochiBillId: 0,
  shopId: 0,
  gochiRegisterId: 0,
  gochiType: '',
  gochiUserType: '',
  paymentId: 0,
  amount: 1234.0,
  taxRate: 0.0,
  taxAmount: 0.0,
  feeRate: 0.0,
  feeAmount: 0.0,
  gochiBillImage: [
    {
      gochiBillImageId: 0,
      gochiBillId: 0,
      image: '',
      viewOrder: 0,
      status: '',
      reportUserId: null,
      updatedAt: '1900-01-01',
      images: null,
      gochiBillIds: null
    }
  ],
  gochiBillMessage: [
    {
      gochiBillMessageId: 0,
      gochiBillId: 0,
      situation: '',
      message: '',
      status: '',
      reportUserId: null,
      gochiBillIds: null
    }
  ],
  gochiCount: 1,
  gochiExpired: '1900-06-01',
  gochiRegister: {
    userId: 9,
    accountId: '',
    displayName: '',
    email: '',
    cognitoUserId: '',
    publicType: '',
    status: '',
    userImage: null,
    userMessage: null,
    userAnniversary: null,
    gochiAddCount: 0,
    gochiUseCount: 0
  },
  shop: {
    shopId: 0,
    shopName: '',
    zipCode: '',
    countryCode: '',
    prefectures: '',
    city: '',
    address: '',
    building: null,
    phone: '',
    email: '',
    introduction: '',
    businessHours: '',
    holiday: '',
    nearestStation: null,
    priceRange: null,
    access: null,
    webPage: '',
    latitude: 0.00000000,
    longitude: 0.00000000,
    geoHash: null,
    status: '',
    inviteUserId: null,
    stealthMode: '',
    gochiRestriction: '',
    shopImage: [
      {
        shopImageId: 0,
        shopId: 0,
        shopIds: null,
        image: '',
        viewOrder: 0,
        status: '',
        reportUserId: null,
        updatedAt: '1900-01-01',
        images: null
      }
    ],
    shopTag: [

    ],
    shopAnniversary: [

    ],
    gochiCount: 0,
    distance: null
  },
  menuId: 0,
  menu: {
    menuId: 0,
    menuName: '',
    description: '',
    price: 0.0,
    taxType: '',
    reducedTaxType: '',
    viewOrder: 0,
    shopId: 0,
    gochiCount: null,
    gochiExpired: null,
    taxRate: 0.0,
    taxAmount: 0,
    includedTaxPrice: 0.0,
    excludedTaxPrice: 0.0,
    displayAmount: 0.0,
    menuImage: [
      {
        menuImageId: 0,
        menuId: 0,
        menuIds: null,
        image: '',
        viewOrder: 0,
        status: '',
        reportUserId: null,
        updatedAt: '1900-01-01'
      }
    ],
    menuTag: [

    ]
  },
  inviteUrl: '',
  createdAt: '1900-01-01',
  childFoods: null
}

const gochis = (state = initialState, action) => {
  switch (action.type) {
    case gochisActions.GET_GOCHIS_SUCCESS:
      return Object.assign({}, state.invitation, action.payload);
    case gochisActions.GET_GOCHIS_FAILURE:
      return Object.assign({}, state, {
        status: action.payload,
        error: action.error
      });
    case gochisActions.POST_USE_GOCHI_SUCCESS:
      return Object.assign({}, state, action.payload, {status: 'REGISTERED'});
    case gochisActions.POST_USE_GOCHI_FAILURE:
      return Object.assign({}, state, {
        status: action.payload,
        error: action.error
      });
    case gochisActions.POST_CREATE_GOCHI_CODE_SUCCESS:
      return Object.assign({}, state, action.payload);
    case gochisActions.POST_CREATE_GOCHI_CODE_FAILURE:
      return Object.assign({}, state, {
        status: action.payload,
        error: action.error
      });
    case gochisActions.POST_CREATE_GOCHI_URL_SUCCESS:
      return Object.assign({}, state, action.payload);
    case gochisActions.POST_CREATE_GOCHI_URL_FAILURE:
      return Object.assign({}, state, {
        status: action.payload,
        error: action.error
      });

    case gochisActions.GET_CHILDFOODS_SUCCESS:
      return Object.assign({}, state, {childFoods: action.payload});
    case gochisActions.GET_CHILDFOODS_FAILURE:
      return Object.assign({}, state, {
        status: action.payload,
        error: action.error
      });
    default:
      return state;
  }
};

export default gochis;
