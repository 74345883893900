import axios from 'axios';

const API_HOST = process.env.REACT_APP_API_BASE_PATH;

const lang = localStorage.getItem('lang');

axios.defaults.headers.get['acceptLanguage'] = lang;

export const getSubscriptionApi = (code) => {
  var url = `${API_HOST}/subscriptions/${code}`;
  return axios.get(`${url}`, { withCredentials: false });
};

export const cancelSubscriptionApi = (code) => {
  var url = `${API_HOST}/subscriptions/${code}`;
  return axios.delete(`${url}`, { withCredentials: false });
};
