import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import "../../../stylesheets/GochiBooking.css";
import moment from "moment";
import MenuInfo from "../../common/MenuInfo";
import LoadingOverlay from "../LoadingOverlay";
import { postBooking, POST_BOOKING_SUCCESS } from "../../../actions/Booking";
import queryString from "query-string";
import { Button, Modal } from "react-bootstrap";

function BookingTicketConfirm(props) {
  const { t } = props;
  const { invitation } = props;
  const [loading, setLoading] = useState(false);
  const params = queryString.parse(window.location.search);
  const [errorMessage, setErrorMessage] = useState("");
  const inputData =
    props.inputData !== undefined
      ? props.inputData
      : props.location !== undefined
      ? props.location.state.inputData
      : {};
  const dispatch = useDispatch();

  function backInput() {
    props.changeStepBooking("INPUT");
  }
  function submitForm() {
    setLoading(true);
    console.log('submit booking: ', inputData);
    dispatch(
      postBooking({
        invitationCode: params.code,
        shopId: inputData.shopId,
        bookingTime: inputData.bookingDate ? moment(inputData.bookingDate).format('YYYY-MM-DDTHH:mm:ss') : '',
        bookingUsername: inputData.bookingName,
        phoneNumber: inputData.phoneNumber,
        bookingMessage: inputData.message,
        email: inputData.email,
      })
    ).then((response) => {
      setLoading(false);
      if (response.type === POST_BOOKING_SUCCESS) {
        props.changeStepBooking("COMPLETE", inputData);
      } else {
        setErrorMessage(props.t("Unexpected error occured"));
      }
    });
  }
  function formatDate(src) {
    if (!src) return "";
    return moment(src).format("YYYY年MM月DD日 HH:mm");
  }
  return (
    props.stepBooking === "CONFIRM" && (
      <>
        <div className="gochi">
          <div className="title-new title-with-buger">
            {props.t("pages.Booking_form")}
            {props.modalView && (
              <button
                type="button"
                className="icon-btn-back icon-vertical"
                onClick={props.handleCloseBookingPopup}
              >
                <i className="icon-close"></i>
              </button>
            )}
          </div>

          <div className="mt-3">
            <ul className="progressbar align-center">
              <li className="step-choice">
                <strong>STEP 1</strong>
              </li>
              <li className="step-select active">
                <strong>STEP 2</strong>
              </li>
              <li className="step-confirm">
                <strong>STEP 3</strong>
              </li>
            </ul>
          </div>
          <div className="text-center my-3 bold fs-14">
            {props.t("pages.Booking_confirm")}
          </div>
          <MenuInfo 
            showShopName 
            showUsedAmount 
            noMultiLang 
            usedQuantity={1} 
            menuLimitTotal={invitation?.menuLimitTotal === 1} 
            hideAmount
          />
          <div className="booking-body">
            {invitation.groupId ? (
              <div className="booking-body-fieldset">
                <div className="text-red fs-12">{props.t("Required")}</div>
                <div className="d-flex mt-2 align-items-center">
                  <div className="left-title">
                    {t("pages.Booking_input_shop_title")}
                  </div>
                  <div className="right-input-field bold fs-14">
                    {inputData.shopName}
                  </div>
                </div>
              </div>
            ) : null}
            {inputData.bookingDate && (
              <div className="booking-body-fieldset">
                <div className="d-flex mt-2 align-items-center">
                  <div className="left-title">
                    {t("pages.Booking_input_date_title")}
                  </div>
                  <div className="right-input-field bold fs-14">
                    {formatDate(inputData.bookingDate)}
                  </div>
                </div>
              </div>
            )}
            <div className="booking-body-fieldset">
              <div className="text-red fs-12">{props.t("Required")}</div>
              <div className="d-flex mt-2 align-items-center">
                <div className="left-title">
                  {t("pages.Booking_input_email_title")}
                </div>
                <div className="right-input-field bold fs-14">
                  {inputData.email}
                </div>
              </div>
            </div>
            <div className="booking-body-fieldset">
              <div className="text-red fs-12">{props.t("Required")}</div>
              <div className="d-flex mt-2 align-items-center">
                <div className="left-title">
                  {t("pages.Booking_input_phone_title")}
                </div>
                <div className="right-input-field bold fs-14">
                  {inputData.phoneNumber}
                </div>
              </div>
            </div>
            <div className="booking-body-fieldset">
              <div className="text-red fs-12">{props.t("Required")}</div>
              <div className="d-flex mt-2 align-items-center">
                <div className="left-title">
                  {t("pages.Booking_username_title")}
                </div>
                <div className="right-input-field bold fs-14">
                  {inputData.bookingName}
                </div>
              </div>
            </div>
            <div className="booking-body-fieldset no-border-bottom">
              <div className="">
                <div className="">{t("pages.Booking_input_message_title")}</div>
                <div className="fs-14 bold line-break mt-2">
                  {inputData.message}
                </div>
              </div>
            </div>
            <div className="flex_center flex_column">
              <div
                className="btn btn-dark-green flex_center btn-booking-confirm"
                onClick={() => submitForm()}
              >
                <span>{t("pages.Booking_ticket_submit")}</span>
              </div>
              <div
                className="border-bt new-btn btn-white-green flex_center mt-3"
                onClick={backInput}
              >
                <span>{props.t("pages.Booking_back_to_form")}</span>
              </div>
            </div>
          </div>
          <Modal
            show={!!errorMessage}
            size="sm"
            onHide={() => setErrorMessage("")}
            centered
            contentClassName="top-modal"
            backdropClassName="top-modal-backdrop"
          >
            <Modal.Body className="alert-dialog">{errorMessage}</Modal.Body>
            <Modal.Footer>
              <Button onClick={() => setErrorMessage("")}>OK</Button>
            </Modal.Footer>
          </Modal>
        </div>
        <LoadingOverlay loading={loading} />
      </>
    )
  );
}

const mapStateToProps = (state) => ({
  invitation: state.gochis,
});

export default connect(mapStateToProps)(
  withTranslation()(BookingTicketConfirm)
);
