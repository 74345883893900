import React from 'react';
import Validator from '../../utils/validator';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createGochiCodes, createGochiUrls } from "../../actions/Gochis";
import ModalAlert from "./ModalAlert";
import { withTranslation } from "react-i18next";
import HamburgerMenu from './HamburgerMenu';

class GochiUrl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gochiBillNo: '',
      email: '',
      code: '',
      copied: false,
      errors: {},
      errorResponse: false,
      successCodeResponse: false,
      errorUrlResponse: false,
      showUrl: false,
      messageError: this.props.t('There is no matching gochis'),
      messageCode: this.props.t('I sent an email'),
      messageErrorUrl: this.props.t('The certification number is different'),
      sending: false
    };

    const { dispatch } = props;

    const rules = [
      {
        field: 'gochiBillNo',
        method: 'isEmpty',
        validWhen: false,
        message: this.props.t('Receipt number is required'),
      },
      {
        field: 'gochiBillNo',
        method: 'isLength',
        args: [{min: 5}],
        validWhen: true,
        message: this.props.t('Receipt number must be at least 12 characters'),
      },
      {
        field: 'email',
        method: 'isEmpty',
        validWhen: false,
        message: this.props.t('Email require'),
      },
      {
        field: 'email',
        method: 'isEmail',
        validWhen: true,
        message: this.props.t('The email address must be a valid email address'),
      },
    ];
    const rules2 = [
      {
        field: 'gochiBillNo',
        method: 'isEmpty',
        validWhen: false,
        message: this.props.t('Receipt number is required'),
      },
      {
        field: 'gochiBillNo',
        method: 'isLength',
        args: [{min: 5}],
        validWhen: true,
        message: this.props.t('Receipt number must be at least 12 characters'),
      },
      {
        field: 'email',
        method: 'isEmpty',
        validWhen: false,
        message: this.props.t('Email require'),
      },
      {
        field: 'email',
        method: 'isEmail',
        validWhen: true,
        message: this.props.t('The email address must be a valid email address'),
      },
      {
        field: 'code',
        method: 'isEmpty',
        validWhen: false,
        message: this.props.t('Verify code required'),
      },
    ];
    this.validator = new Validator(rules);
    this.validator2 = new Validator(rules2);
    this.onCopy = this.onCopy.bind(this);
    this.boundActionCreators = bindActionCreators({ ...createGochiCodes, ...createGochiUrls }, dispatch);
    this.closePop = this.closePop.bind(this);
  }

  closePop = () => {
    this.setState({errorResponse: false});
    this.setState({successCodeResponse: false});
    this.setState({errorUrlResponse: false});
  };

  onCopy = () => {  
    this.setState({copied: true});
    setTimeout(() => {
      this.setState({copied: false});
    }, 3000);
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmitCode = () => {
    let valid = this.validator.validate(this.state);
    this.setState({
      errors: valid,
      sending: true
    });
    if (Object.keys(valid).length === 0) {
      let { dispatch } = this.props;

      let action = createGochiCodes({
        gochiBillNo: this.state.gochiBillNo,
        email: this.state.email,
      })
      dispatch(action).then(response => {
        console.log(response.payload.status);
        if (response.payload.status && response.payload.status !== 200) {
          this.setState({
            errorResponse: true,
            showUrl: false
          });
          if (response.payload.status && response.payload.status === 404 && response.payload.data.errorInfo.code === 'exception.errors.invite.code.not.found') {
            this.setState({
              errorResponse: true,
              messageError: this.props.t('There is no corresponding URL'),
              sending: false
            });
          }
        } else {
          this.setState({
            successCodeResponse: true,
            showUrl: false,
            sending: false
          })
        }
        this.setState({
          code: ''
        });
      });
    } else {
      this.setState({
        sending: false
      })
    }
  };

  handleSubmitUrl = () => {
    let valid2 = this.validator2.validate(this.state);
    this.setState({
      errors: valid2,
      sending: true
    });
    if (Object.keys(valid2).length === 0) {
      let { dispatch } = this.props;

      let action = createGochiUrls({
        gochiBillNo: this.state.gochiBillNo,
        email: this.state.email,
        authKey: this.state.code
      })
      dispatch(action).then(response => {
        console.log(response.payload.status);
        if (response.payload.status && response.payload.status !== 200) {
          this.setState({
            errorUrlResponse: true,
            showUrl: false,
            sending: false
          });
        } else {
          this.setState({
            showUrl: true,
            sending: false
          });
        }
      });
    } else {
      this.setState({
        sending: false
      })
    }
  };


  render() {
    const { t } = this.props;
    const {errors} = this.state;
    console.log('props', this.props);
    let { inviteUrl } = this.props;

    return (
      <div id="gochiUrl">
        {this.state.errorResponse && 
          <ModalAlert message={this.state.messageError} func={this.closePop} />
        }
        {this.state.successCodeResponse && 
          <ModalAlert message={this.state.messageCode} func={this.closePop} />
        }
        {this.state.errorUrlResponse && 
          <ModalAlert message={this.state.messageErrorUrl} func={this.closePop} />
        }
        <div className="navBar-gochi-url position-relative">
          {t('pages.Ticket link reissue screen')}
          <div className="">
            <HamburgerMenu />
          </div>
        </div>
        <div className="contentBody">
          <form>
            <div 
              className="des-gochi-url" 
              dangerouslySetInnerHTML={{__html: t('pages.Enter the receipt number in the subject of the email and the email address at the time of purchase', {interpolation: {escapeValue: false}})}}
            />
            <div>
              <div>
                <div className="form-group d-inline-block">
                  <p>{t('pages.Receipt number')}<span className="text-red">*</span></p>
                  <input className={errors.gochiBillNo ? 'error-input form-input' : 'form-input'} type="text" name="gochiBillNo" value={this.state.gochiBillNo} onChange={this.handleInput}/>
                  {errors.gochiBillNo && <div className="validation" style={{display: 'block'}}>{errors.gochiBillNo}</div>}
                </div>
                <div className="form-group d-inline-block">
                  <p>{t('pages.Mail address')}<span className="text-red">*</span></p>
                  <input className={errors.email ? 'error-input form-input' : 'form-input'} type="email" name="email" value={this.state.email} onChange={this.handleInput}/>
                  {errors.email && <div className="validation" style={{display: 'block'}}>{errors.email}</div>}
                </div>
              </div>
            </div>
            <button type="button" disabled={this.state.sending} className="btn btn-green-new w-240 ml-auto mr-auto" onClick={this.handleSubmitCode}>{t('pages.Send email')}</button>
            <hr />
            <div>
              <div>
                <div className="form-group d-inline-block">
                  <p>{t('pages.Certification number')}<span className="text-red">*</span><span style={{fontSize: '12px', marginLeft: 5}}>({t('pages.valid for 5 minutes')})</span></p>
                  <input className={errors.code ? 'error-input form-input' : 'form-input'} type="text" name="code" value={this.state.code} onChange={this.handleInput}/>
                  {errors.code && <div className="validation" style={{display: 'block'}}>{errors.code}</div>}
                </div>
              </div>
            </div>
            <button type="button" disabled={this.state.sending} className="btn btn-green-new w-240 ml-auto mr-auto" onClick={this.handleSubmitUrl}>{t('pages.URL reissue')}</button>
          </form>
          {inviteUrl && this.state.showUrl &&
            <div className="invite-url">
              <div className="text-center">
                <span className="arrow arrow-down"></span>
              </div>
              <div className="form-group d-inline-block">
                {this.state.copied &&
                  <div className="copied-tag">Copied</div>
                }
                <input className="form-input" type="text" name="inviteUrl" value={inviteUrl} readOnly />
              </div>
              <CopyToClipboard onCopy={this.onCopy} text={inviteUrl}>
                <button className="btn btn-green-new w-240 ml-auto mr-auto" onCopy={this.onCopy}>
                  {t('pages.CopyURL')}
                </button>
              </CopyToClipboard>
              {/* <div 
                className="text-note-small"
                dangerouslySetInnerHTML={{__html: t('pages.For inquiries regarding operation', {interpolation: {escapeValue: false}})}}
              /> */}
              {/* <div 
                className="text-note"
                dangerouslySetInnerHTML={{__html: t('pages.If you have not received the Nagasaki Prefecture product application form, please apply from the following', {interpolation: {escapeValue: false}})}}
              /> */}
            </div>
          }
        </div>
      </div>
    );
  }
}
export default connect(state => ({ code: state.gochis.code, inviteUrl: state.gochis.inviteUrl }))(withTranslation()(GochiUrl));
