import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

function QuantityFreeDes(props) {
  const { t } = props;
  const { invitation } = props;
  return (
    <>
      {invitation.menu &&
        invitation.menu.menuComboDetail &&
        invitation.menu.menuComboDetail.quantityFreeDes &&
        invitation.menu.menuComboDetail.quantityFreeDes !== "" && (
          <div>
            <div className="arrow-up"></div>
            <div className="quantity-free-des-container fs-13">
              <div className="font-weight-bold">{t("pages.Bonus")}</div>
              {invitation.menu.menuComboDetail.quantityFreeDes}
            </div>
          </div>
        )}
    </>
  );
}

const mapStateToProps = (state) => ({ invitation: state.gochis });

export default connect(mapStateToProps)(withTranslation()(QuantityFreeDes));
