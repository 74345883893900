import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import queryString from "query-string";
import ReactModal from "react-modal";
import Header from "../common/Header";
import Moment from "react-moment";

const customStyles = {
  overlay: {
    background: "#444444",
    padding: 15,
  },
};

function ShopTicketUseComplete(props) {
  const { t } = props;
  // const { dispatch } = props;

  const location = props.location;
  const invitation = location.state && location.state.invitation;
  const shopName = location.state && location.state.shopName;
  const usedQuantity = location.state && location.state.quantity;

  const params = queryString.parse(window.location.search);
  var query = new URLSearchParams(props.location.search); 
  var useAmount = query.get("useAmount");
  var menuLimitTotal = query.get("menuLimitTotal");
  // console.log('location.state', location.state);
  const [showPaperTicketAlert, setShowPaperTicketAlert] = useState(() => (invitation.menu && invitation.menu.isETicket === 0));

  const menuComboDetail = invitation.menu && invitation.menu.menuComboDetail
  let transferForShopAmount = 0;
  let priceForOneCoupon = 0;
  if (invitation.menu?.menuCombo) {
    priceForOneCoupon = invitation.menu?.displayAmount
        ? invitation.menu?.displayAmount/menuComboDetail.quantity
        : invitation.menu &&
        invitation.menu.menuComboDetail &&
          invitation.menu.menuComboDetail.includedTaxPriceCombo;
    transferForShopAmount = priceForOneCoupon * usedQuantity;
  } else if (invitation?.menuLimitTotal) {
    transferForShopAmount = Number(useAmount);
    priceForOneCoupon = Number(useAmount);
  } else {
    transferForShopAmount = invitation.gochiType === "MENU"
    ? (invitation.menu.displayAmount
        ? invitation.menu.displayAmount
        : invitation.menu.includedTaxPrice) * usedQuantity
    : ((invitation.amount + invitation.taxAmount) /
        invitation.couponCount) *
        usedQuantity;
    priceForOneCoupon = invitation.gochiType === "MENU"
    ? (invitation.menu.displayAmount
        ? invitation.menu.displayAmount
        : invitation.menu.includedTaxPrice)
    : ((invitation.amount + invitation.taxAmount) /
        invitation.couponCount);
  }
  const isShowPrice = invitation.menu?.showPrice !== 0;

  return (
    <>
      <div className="gochi">
        <Header title="お支払い完了" />
      <div className="add-padding">
        <div className="fs-22 font-weight-bold my-5 text-center">
          お支払い完了
        </div>

        <div className="used-price-box mt-3">
            <div className="top">
              {shopName}
            </div>
            <div className="mid mt-3 mb-2">
              {isShowPrice ? (
                <>
                  <span
                    className={
                      transferForShopAmount < 1000000
                        ? 'fs-72 line-height-72'
                        : 'fs-48 line-height-48'
                    }
                  >
                    {transferForShopAmount &&
                      transferForShopAmount.toLocaleString()}
                  </span>
                  <span className="fs-20 line-height-20">円</span>
                </>
              ) : (
                <div className="fs-20 fw-bold">
                  {invitation?.menu?.menuName}
                </div>
              )}
            </div>
            <Moment
              className="bot"
              interval={1000}
              format="YYYY.MM.DD HH:mm:ss"
              local
            />
          </div>

          <div className="px-content-text">
            {isShowPrice ? (
              <>
                <div className="line-break mt-4 fs-18 font-weight-bold">
                  {invitation?.menu?.menuName}
                </div>
                {invitation?.menuLimitTotal !== 1 && <div className="fs-18 font-weight-medium mt-2 line-height-18">
                  <span>単価: </span>
                  <span className="font-weight-bold">
                    {priceForOneCoupon && priceForOneCoupon.toLocaleString()}
                  </span>
                  <span className="fs-14">円</span>
                  <span className="ml-4">個数: </span>
                  <span className="font-weight-bold">{
                    `${usedQuantity}`
                  }</span>
                  <span className="fs-14">個</span>
                </div>}
              </>
            ) : (
              invitation?.menuLimitTotal !== 1 && usedQuantity > 1 && (
                <div className="fs-18 font-weight-medium mt-4 line-height-18">
                  <span>個数: </span>
                  <span className="font-weight-bold">{
                    `${usedQuantity}`
                  }</span>
                  <span className="fs-14">個</span>
                </div>
            ))}
          </div>
        </div>
      </div>

      <ReactModal
        className="modail-content"
        size="sm"
        isOpen={showPaperTicketAlert}
        onRequestClose={() => {
          setShowPaperTicketAlert(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        style={customStyles}
      >
        <div className="fs-21 text-black text-center font-weight-normal">
          {t("pages.paper ticket message")}
        </div>

        <div className="fs-13 text-grey text-center font-weight-normal">
          {`ごちコード ${invitation.gochiBillNo}`}
        </div>
        <hr className="new-hr my-1" />

        <div
          className="btn btn-green-38 flex_center new-btn"
          onClick={() => {
            setShowPaperTicketAlert(false);
          }}
        >
          <span>確認</span>
        </div>
      </ReactModal>
    </>
  );
}

const mapStateToProps = (state) => ({
  invitation: state.gochis
});

export default connect(mapStateToProps)(
  withTranslation()(ShopTicketUseComplete)
);
