import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
// import { numberFormatter } from "../../libs/CommonUtil";
import Modal from "react-modal";
import LoadingOverlay from "../pages/LoadingOverlay";
import { getChildfoods } from "../../actions/Gochis";
import MenuInfo from "./MenuInfo";

const NO_IMAGE_URL = "/images/gochi_noimg-ver4.jpg";
const customStyles = {
  overlay: {
    background: "#808080",
  },
  content: {},
};

function QuantitySelect(props) {
  const { t } = props;
  const { invitation } = props;
  
  const [listChildfoods, setListChildFoods] = useState([]);
  const [validateMessage, setValidateMessage] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const limit = 10;
  const [shopCount, setShopCount] = useState(0);

  function closeSelect() {
    props.onCloseSelectModal();
  }

  function loadMoreChildfood() {
    try {
      getListChildfoods({ page: page + 1 });
      setPage((page) => page + 1);
    } catch (error) {
      console.log("error loadMoreChildfood", error);
    }
  }

  function getListChildfoods({ page }) {
    const shopId = props.shopId;
    const menuId = invitation.menuId;
    setLoading(true);
    let { dispatch } = props;
    try {
      let actionChildfoods = getChildfoods({ shopId, menuId, page, limit });
      dispatch(actionChildfoods).then((res) => {
        console.log(res);
        if (res.type === "GET_CHILDFOODS_SUCCESS") {
          const childfoods = listChildfoods;
          if (res.payload.list) {
            res.payload.list.map((shop) => {
              return childfoods.push(shop);
            });
            setListChildFoods(childfoods);
            setShopCount(res.payload.count);
            if (childfoods.length < res.payload.count) {
              setHasMore(true);
            } else {
              setHasMore(false);
            }
          }

          setLoading(false);
        }
      });
    } catch (error) {
      console.log("error getlistChildfoods", error);
      setLoading(false);
    }
  }

  useEffect(() => {
    setListChildFoods([]);
    if (invitation.menu && invitation.menu.menuChildType === 3) {
      getListChildfoods({ page: 1 });
    }
    // if (!invitation.gochiBillId) {
    //   setValidateMessage(t("GochiBill not found"));
    // }
  }, []);

  return (
    <>
      <Modal
        isOpen={props.selectIsOpen}
        onRequestClose={closeSelect}
        style={customStyles}
        className="modail-content"
      >
        <div id="select" className="modal-use pt-3">
          <i onClick={closeSelect} className="icon-close"></i>
          {invitation.shop && invitation.shop.status === "CLOSED" && (
            <div className="shop-closed mt-3">
              {t("pages.This shop has closed")}
            </div>
          )}
          <div className="fs-21 text-black text-center font-weight-normal">
            {t("pages.Message confirmation")}
          </div>
          <MenuInfo 
            showShopName 
            showUnitPrice
            menuLimitTotal={invitation?.menuLimitTotal === 1} 
            hideAmount={invitation?.menuLimitTotal === 1} 
          />
          <hr className="new-hr my-1" />
          <div className="box-input-num-gochi mb-4">
            <button
              onClick={props.increaseQuantity}
              className="btn btn-select-quantity font-weight-bold bg-white"
            >
              +
            </button>
            {/* <span className="mx-2 font-weight-bold fs-14">{props.quantity}</span> */}
            <input
              className="mx-2 font-weight-bold fs-16 quantity-input"
              onChange={(e) => {
                const value =
                  parseInt(e.target.value.replace(/[^\d]/, "")) || "";
                props.setQuantity(value);
              }}
              value={props.quantity}
            ></input>
            <button
              onClick={props.descreaseQuantity}
              className="btn btn-select-quantity font-weight-bold bg-white"
            >
              -
            </button>
            <span className="gochi-num-max ml-2 text-black">
              / {invitation.gochiCount}
              {t("pages.Order feast")}
            </span>
          </div>
          {validateMessage !== "" && (
            <div className="error-mess fs-12 mt-2 text-center">
              {validateMessage}
            </div>
          )}

          {listChildfoods && listChildfoods.length === 0 && (
            <div
              className="btn btn-green-38 flex_center new-btn"
              onClick={props.openUse}
            >
              <span>{t("pages.Proceed to order")}</span>
            </div>
          )}

          {listChildfoods && listChildfoods.length > 0 && (
            <div className="list-shops">
              <div className="ttl-childfood fs-16 text-center bold text-green mt-3 mb-3">
                {t("pages.Please select one of the children menus")}
              </div>

              <ul className="list-items">
                {listChildfoods.map(function (childFood, key) {
                  return (
                    <li key={childFood.childFoodId} className="shop-item">
                      <div className="media">
                        <img
                          width={66}
                          className="mr-2"
                          src={
                            childFood.childFoodImage &&
                            childFood.childFoodImage.length > 0
                              ? childFood.childFoodImage[0].image
                              : NO_IMAGE_URL
                          }
                          alt={childFood.childFoodName}
                        />
                        <div className="flex-1">
                          <h5 className="shop-name">
                            {childFood.childFoodName}
                          </h5>
                          <div className="descript">
                            {childFood.childFoodDescription}
                          </div>

                          <div className="mt-2">
                            <button
                              variant="success"
                              className="btn btn-green-38 w-100p ex-bg-green h-30"
                              onClick={(event) =>
                                props.openUse(event, childFood.childFoodId)
                              }
                            >
                              {t("pages.Proceed to order")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
              {hasMore && (
                <div className="text-center mt-3">
                  <button
                    variant="link"
                    className="btn btn-load-more"
                    onClick={loadMoreChildfood}
                  >
                    {t("pages.Load more")}
                  </button>
                </div>
              )}
            </div>
          )}
          <div className="fs-15 clr-000000 align-center mt-2">
            <button onClick={closeSelect} className="btn btn-close-popup">
              {t("pages.Cancel")}
            </button>
          </div>
        </div>
      </Modal>

      <LoadingOverlay loading={loading} />
    </>
  );
}

const mapStateToProps = (state) => ({ invitation: state.gochis });

export default connect(mapStateToProps)(withTranslation()(QuantitySelect));
