import React, { Component } from "react"; 
import { withTranslation } from 'react-i18next';
import { slide as Menu } from 'react-burger-menu';
import queryString from 'query-string';
import i18n from '../../i18n';

class HamburgerMenu extends Component {

    constructor(props) {
        super(props);
        this.onItemClicked = this.onItemClicked.bind(this);
        this.onStateChange = this.onStateChange.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);
    }

    state = {
        isOpen: false
    }
    
    onItemClicked() {
        this.setState({isOpen: false})
    }

    onStateChange(state) {
        this.setState({
            isOpen: state.isOpen
        });
    }

    changeLanguage(lng) {
        i18n.changeLanguage(lng);
        localStorage.setItem('lang', lng);
        window.location.reload();
    }

    render () {
        const { t } = this.props;

        const defaultLang = localStorage.getItem('lang'); 

        return (
            <Menu 
                right
                className='burger-menu'
                isOpen = {this.state.isOpen}
                onStateChange={this.onStateChange}>
                

                <div className="select-lang-box">
                    <div className="select-lang select-custom">
                        <i className="icon-lang-global icon-language"/>  
                        <select className="form-control" 
                            defaultValue={defaultLang} 
                            onChange={(e) => this.changeLanguage(e.target.value)}
                        >
                            <option value="">Select language</option>
                            <option value="en">English</option>
                            <option value="ja">Japanese</option>
                            <option value="zh">Chinese</option>
                        </select>
                    </div>
                </div>
        </Menu>
        );
    }
}

export default (withTranslation()(HamburgerMenu));