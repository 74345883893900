export const PAYMENT_TYPE_STRIPE = 1;
export const PAYMENT_TYPE_PAYPAY = 2;
export const PAYMENT_TYPE_ONEPAY_ALIPAY = 3;
export const PAYMENT_TYPE_ONEPAY_LINEPAY = 4;
export const PAYMENT_TYPE_ONEPAY_MERUPAY = 5;
export const PAYMENT_TYPE_WECHATPAY = 6;
export const PAYMENT_TYPE_VERITRANS = 7;
export const PAYMENT_TYPE_ATONE = 8;
export const PAYMENT_TYPE_SELF_CHECKOUT = 100;

export const TAX_RATE_10_PERCENT = 0.1;
export const TAX_RATE_8_PERCENT = 0.08;

export const USAGE_TYPE_MENU = "MENU";
export const USAGE_TYPE_VOUCHER = "VOUCHER";
export const USAGE_TYPE_COMBO = "COMBO";