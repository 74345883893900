import * as menuActions from '../actions/Menus';

const initialState = {
  
}

const menus = (state = initialState, action) => {
  switch (action.type) {
    case menuActions.GET_SHOPS_MENU_SUCCESS:
      return Object.assign({}, state.menus, action.payload);
    case menuActions.GET_SHOPS_MENU_FAILURE:
      return Object.assign({}, state, {
        status: action.payload,
        error: action.error
      }); 
    default:
      return state;
  }
};

export default menus;
