import React, { useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Gift from './pages/Gift';
import GiftComplite from './pages/GiftComplete';
import CommingSoon from './pages/CommiingSoon';
import GochiUrl from './pages/GochiUrl';
import GochiCode from './pages/GochiCode';
import Maintain from './pages/Maintain';
import TicketUse from './pages/TicketUse';
import TicketUseInput from './pages/TicketUseInput';
import TicketUseComplete from './pages/TicketUseComplete';
import ShopTicketUse from './pages/ShopTicketUse';
import ShopTicketUseConfirm from './pages/ShopTicketUseConfirm';
import ShopTicketUseComplete from './pages/ShopTicketUseComplete';
import DaigasShops from './pages/DaigasShops';
import history from './history';
import DaigasEmailInput from './pages/DaigasEmailInput';
import DaigasInputComplete from './pages/DaigasInputComplete';
import DaigasFinish from './pages/DaigasFinish';
import DaigasEndEvent from './pages/DaigasEndEvent';
import ServerError from './pages/ServerError';
import BookingTicketUse from './pages/booking/BookingTicketUse';
import BookingTicketUseConfirm from './pages/booking/BookingTicketUseConfirm';
import BookingTicketUseComplete from './pages/booking/BookingTicketUseComplete';
import GochiSubscription from './pages/GochiSubscription';
import StripeCheckout from './pages/StripeCheckout';
import AlipayQRCode from './pages/AlipayQRCode';
import WechatpayQRCode from './pages/WechatpayQRCode';

const AppRoute = () => (
  <Switch>
    <Route exact path="/gift" component={Gift} />
    <Route exact path="/giftcomplete" component={GiftComplite} />
    <Route exact path="/gochiUrl" component={GochiUrl} />
    <Route exact path="/gochiCode" component={GochiCode} />
    <Route exact path="/maintain" component={Maintain} />
    <Route exact path="/server-error" component={ServerError} />
    <Route exact path="/ticket-use" component={TicketUse} />
    <Route exact path="/ticket-use-input" component={TicketUseInput} />
    <Route exact path="/ticket-use-complete" component={TicketUseComplete} />

    <Route exact path="/subscription-cancel" component={GochiSubscription} />

    {/* Daigas event */}
    <Route exact path="/daigas/shops" component={DaigasShops} />
    <Route exact path="/daigas/input" component={DaigasEmailInput} />
    <Route exact path="/daigas/input-complete" component={DaigasInputComplete} />
    <Route exact path="/daigas/finish" component={DaigasFinish} />
    <Route exact path="/daigas/event-end" component={DaigasEndEvent} />

    {/* For shop */}
    <Route exact path="/shop/ticket-use" component={ShopTicketUse} />
    <Route exact path="/shop/ticket-use-confirm" component={ShopTicketUseConfirm} />
    <Route exact path="/shop/ticket-use-complete" component={ShopTicketUseComplete} />

    {/* Booking shop */}
    <Route exact path="/booking/ticket-use" component={BookingTicketUse} />
    <Route exact path="/booking/ticket-confirm" component={BookingTicketUseConfirm} />
    <Route exact path="/booking/ticket-complete" component={BookingTicketUseComplete} />

    {/* Checkout */}
    <Route exact path="/stripeCheckout" component={StripeCheckout} />
    <Route exact path="/alipay-qr-code" component={AlipayQRCode} />
    <Route exact path="/wechatpay-qr-code" component={WechatpayQRCode} />

    <Route path="/" component={CommingSoon} />
  </Switch>
);

const App = () => {
  useEffect(() => {
    var _paq = window._paq = window._paq || [];
    // / tracker methods like "setCustomDimension" should be called before "trackPageView" /
    _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
    _paq.push(["setCookieDomain", "*." + document.domain]);
    _paq.push(["setDomains", ["*." + document.domain]]);

    console.log('document.domain: ', document.domain);

    // you can set up to 5 custom variables for each visitor
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);

    // (function() {
    //     var u="//matomo.1steam.com/";
    //     _paq.push(['setTrackerUrl', u+'matomo.php']);
    //     _paq.push(['setSiteId', process.env.REACT_APP_MATOMO_ID]);
    //     var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    //     g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    // })(); 
  }, []);

  return (
    <Router history={history}>
      <Route component={AppRoute} />
    </Router>
  );
};
export default App;
