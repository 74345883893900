import React from "react";
import { withTranslation } from "react-i18next";

function GiftBoxButton(props) {
  return (
    <div onClick={props.onClick} className="gift-box">
      <div className="icon-container">
        <img className="gift-icon" src="/images/giftbox.svg" alt="Gift" />
      </div>
      <div className="title-container">
        <div className="top-title">
          {props.t("pages.no login")}
          <span style={{fontSize: 20}}>3</span>
          {props.t("pages.in 3 min")}
        </div>
        <div className="bottom-title">{props.t("pages.get a gift")}</div>
      </div>
    </div>
  );
}

export default withTranslation()(GiftBoxButton);
