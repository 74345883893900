import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  getInvitations,
  getAuthenCode,
  createAuthenCodeForShop
} from "../../actions/Gochis";
import LoadingOverlay from "./LoadingOverlay";
import queryString from "query-string";
import history from "../history"; 
import QRCode from "qrcode.react";
import Header from "../common/Header";
import SurveyForm from "../components/SurveyForm";
import { PAYMENT_TYPE_ONEPAY_ALIPAY, PAYMENT_TYPE_PAYPAY, PAYMENT_TYPE_STRIPE, PAYMENT_TYPE_WECHATPAY, TAX_RATE_10_PERCENT, TAX_RATE_8_PERCENT, USAGE_TYPE_COMBO, USAGE_TYPE_MENU, USAGE_TYPE_VOUCHER } from "../../constants";
import { utilizeGochis, createSession } from "../../actions/Gochis";
import { isMobile } from "react-device-detect";
import { Collapse, Modal } from "react-bootstrap";
import Moment from "react-moment";
import TicketUseConfirmModal from "../components/TicketUseConfirmModal";

function TicketUse(props) {
  const { t } = props;
  const { dispatch } = props;
  const { invitation } = props;
  const { authenCodeForShop } = props;
  const location = props.location;
  const addParams = location.state && location.state.addParams;
  const paramsMenu = location.state && location.state.paramsMenu;
  const [authenCode, setAuthenCode] = useState(null);
  const [showSurvey, setShowSurvey] = useState(false);
  const [crfsToken, setCrfsToken] = useState("");
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [overLimitError, setOverLimitError] = useState(false);
  const [overLimitErrorMess, setOverLimitErrorMess] = useState("");
  const [openTicketUseConfirm, setOpenTicketUseConfirm] = useState(false);
  const [collapseQR, setCollapseQR] = useState(true);
  const [getSurveyError, setGetSurveyError] = useState(false);
  const [taxRate, setTaxRate] = useState(TAX_RATE_10_PERCENT);

  var query = new URLSearchParams(props.location.search);
  var invitationCode = query.get("code");

  const params = queryString.parse(window.location.search);
  const quantity = params.quantity;
  const shopName = params.shopName; 

  const [ locationKeys, setLocationKeys ] = useState([])
  const history = useHistory()

  useEffect(() => {
    history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([ location.key ])
      }
      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([ _, ...keys ]) => keys)
          // Handle forward event
        } else {
          setLocationKeys((keys) => [ location.key, ...keys ])
          // Handle back event
          // window.location.href = '/gift?code=' + invitationCode;
          console.log('redirect to page detail');
          window.location.replace('/gift?code=' + invitationCode);
        }
      }
    })
  }, [ locationKeys, ])

  let urlValue = '';

  if (paramsMenu?.menuLimitTotal === 1) {
    urlValue =
      window.location.protocol +
      "//" +
      window.location.hostname +
      "/shop/ticket-use?authenCode=" +
      authenCodeForShop.authenCode +
      "&useAmount=" +
      paramsMenu?.useAmount +
      "&menuLimitTotal=true" + "&shopName=" + shopName;
    console.log('urlValue', urlValue);
  } else {
    urlValue =
      window.location.protocol +
      "//" +
      window.location.hostname +
      "/shop/ticket-use?authenCode=" +
      authenCodeForShop.authenCode; 
      console.log('urlValue', urlValue);
  }

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [seconds, setSeconds] = useState(0);
  var timerId; 

  useEffect(() => {
    getInvitationInfo(); 
    generateRandomString();
  }, []);

  useEffect(() => {
    let clearCountDownCallback;
    if (!collapseQR && (paramsMenu?.addedAmount <= 0 || paramsMenu?.menuLimitTotal !== 1)) {
      generateAuthenCode();
      if (clearCountDownCallback) {
        clearCountDownCallback();
      }
      clearCountDownCallback = countDown();
    }
    return (() => clearCountDownCallback && clearCountDownCallback());
  }, [collapseQR, taxRate]);

  useEffect(() => {
    const clearCallback = callBackCheckFinish(authenCode);
    return (() => {
      clearCallback();
    })
  }, [authenCode]);

  useEffect(() => {
    if (invitation?.menu?.usageType === USAGE_TYPE_MENU) {
      setTaxRate(invitation.taxRate);
    }
  }, [invitation.menu]);

  function countDown() {
    const timer = setInterval(() => {
      setSeconds((t) => t - 1);
    }, 1000);
    return () => clearInterval(timer);
  }

  function callBackCheckFinish(authCode) {
    function clearCallback() {
      clearInterval(timerId);
    }
    // repeat with the interval of 10 seconds
    timerId = setInterval(() => handleCheckFinish(authCode), 10 * 1000);

    // after 60 seconds stop
    setTimeout(clearCallback, 60 * 1000);
    return clearCallback;
  }

  function handleCheckFinish(authCode) {
    let invitation = loadFromSessionStorage();
    if (authCode) {
      let action = getAuthenCode(authCode);
      dispatch(action).then((response) => {
        if (response.type === "GET_AUTHEN_CODE_SUCCESS") {
          if (response.payload && response.payload.status === "FINISHED") {
            let couponId =
              response.payload.extraData &&
              response.payload.extraData.couponIds &&
              response.payload.extraData.couponIds.length > 0 &&
              response.payload.extraData.couponIds[0];
            let gochiTipUrl =
              response.payload.extraData &&
              response.payload.extraData.gochiTipUrl;
            const search = {
              code: invitationCode,
              useAmount: paramsMenu?.useAmount,
              shopName: shopName
            };
            history.replace({
              pathname: "/ticket-use-complete",
              state: {
                invitation,
                gochiTipUrl,
                couponId,
                quantity,
              },
              search: `?${queryString.stringify(search)}`,
            });
            clearTimeout(timerId);
          }
        }
      });
    }
  }

  function generateAuthenCode() {
    let message = t("pages.Gen QR code error, Try again");
    const cloneAddParams = { ...addParams };
    cloneAddParams.extraData.taxRate = taxRate;
    try {
      dispatch(createAuthenCodeForShop(cloneAddParams)).then((response) => {
        // console.log('authCode = ', response);
        if (response.type === "POST_AUTHEN_CODE_SUCCESS") {
          setAuthenCode(response.payload.authenCode);
          setSeconds(60);
          setErrorMessage(null);
        } else if (response.type === "POST_AUTHEN_CODE_FAILURE") {
          setErrorMessage(message);
        }
      });
    } catch (e) {
      console.log(e);
      setErrorMessage(message);
    }
  }

  function getInvitationInfo() {
    setLoading(true);
    try {
      let action = getInvitations({ invitationCode: invitationCode });
      dispatch(action).then((response) => {
        if (response.type === "GET_GOCHIS_SUCCESS") {
          saveToSessionStorage(response.payload);
        } else {
          sessionStorage.removeItem("store");
          window.location.href = `/gift?code=${invitationCode}`;
        }
        setLoading(false);
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
      sessionStorage.removeItem("store");
      window.location.href = `/gift?code=${invitationCode}`;
    }
  }

  function saveToSessionStorage(store) {
    try {
      const serializedStore = JSON.stringify(store);
      sessionStorage.setItem("store", serializedStore);
    } catch (e) {
      console.log(e);
    }
  }

  function loadFromSessionStorage() {
    try {
      const serializedStore = sessionStorage.getItem("store");
      if (serializedStore === null) return undefined;
      return JSON.parse(serializedStore);
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  function backUrl() {
    if (paramsMenu?.menuLimitTotal === 1) {
      // const exSearch = paramsMenu?.addedAmount <= 0 ? `&authenCode=${authenCodeForShop.authenCode}` : '';
      history.replace({
        pathname: "/ticket-use-input",
        state: {
          addParams,
          paramsMenu
        },
        search:
          window.location.search,
      }); 
    } else {
      history.replace({
        pathname: "/gift",
        state: {
          addParams,
          paramsMenu
        },
        search:
          window.location.search ,
      }); 
    }
  }

  function generateRandomString(length = 32) {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    setCrfsToken(text);
    return text;
  }

  function confirmAction() {
    if (paramsMenu?.addedAmount > 0) {
      if (!disabledBtn)
      createSessionPayment();
    } else {
      handleUseGochi();
    }
  }

  function createSessionPayment() {
    setLoading(true);
    setDisabledBtn(true);
    // const crfs = generateRandomString();
    const couponMaster = invitation?.coupons?.find((item) => item.useDate === null);
    const paymentInfo = {
      couponId: couponMaster.couponId,
      premiumAmount: couponMaster?.premiumAmount,
      useAmount: paramsMenu?.useAmount,
      inputAmount: paramsMenu?.inputAmount,
      paymentType: paramsMenu?.paymentMethod,
      taxAmountType: paramsMenu?.taxAmountType,
      platformType: 'WEB',
      invitationCode: params?.code,
      userAgent: navigator.userAgent,
      domainUrl: process.env.REACT_APP_DOMAIN_URL,
      useForShopId: Number(params.shopId),
      memo: {
        shopId: invitation.shopId || Number(params.shopId),
      },
      childFoodId: Number(params.shopId)
    };
    
		let windowReference;

    try {
      dispatch(createSession(paymentInfo, crfsToken)).then((response) => {  
        if (!paramsMenu?.paymentMethod || paramsMenu?.paymentMethod === PAYMENT_TYPE_STRIPE) {
          if (response.type === "POST_PAYMENT_SESSION_SUCCESS") {
            let urlStripe = "/stripeCheckout?sessionId=" + response?.payload?.stripeSessionId;
            let urlRedirectStrip = `/ticket-use-complete?code=${params?.code}&useAmount=${paramsMenu?.useAmount || 0}&merchantPaymentId=${response?.payload?.merchantPaymentId}&shopName=${shopName}`;
            setRedirectUrl(urlRedirectStrip)
            if (isMobile) {
              window.location.href = urlStripe;
            } else {
              window.open(urlStripe, '_blank');
            }
          } else if (response.payload.status === 406) { 
						setErrorMessage(t('pages.Error create gochi'));
						setLoading(false);
            setDisabledBtn(false); 
					} else if (response.payload.status === 402) {
						let messageErr = t('pages.error create gochi using stripe incase other charging');
						if (response.payload.data) {
							if (response.payload.data.stripe_code === 'expired_card') {
								messageErr = t('pages.error create gochi using stripe incase expired card');
							} else if (response.payload.data.stripe_code === 'amount_too_small') {
								messageErr = t('pages.error create gochi using stripe incase amount to small');
							} else if (response.payload.data.stripe_code === 'insufficient_funds') {
								messageErr = t('pages.error create gochi using stripe incase insufficient funds');
							} else if (response.payload.data.stripe_code === 'card_velocity_exceeded') {
								messageErr = t('pages.error create gochi using stripe incase card velocity exceeded');
							}
						} 
						setErrorMessage(messageErr);
						setLoading(false);
						setDisabledBtn(false); 
					} else if (response.payload.status === 404 || response.payload.status === 400 || response.payload.status === 500) {
						setErrorMessage(t('pages.error create gochi user'));
						setLoading(false);
						setDisabledBtn(false); 
					} else {
						setErrorMessage(t('pages.error create gochi with guest'));
						setLoading(false);
						setDisabledBtn(false); 
					}
          setLoading(false);
        } else {
          if (paramsMenu?.paymentMethod !== PAYMENT_TYPE_PAYPAY) {
            windowReference = window.open();
          }

          if (response.type === "POST_PAYMENT_SESSION_SUCCESS") {
            setLoading(false);
            let redirectUrlSuccess = `/ticket-use-complete?code=${params?.code}&useAmount=${paramsMenu?.useAmount || 0}&merchantPaymentId=${response?.payload?.merchantPaymentId}&shopName=${shopName}`;
            setRedirectUrl(redirectUrlSuccess);
            if (paramsMenu?.paymentMethod === PAYMENT_TYPE_PAYPAY) { 
							if (isMobile) {
								window.location.href = response.payload.redirectUrl;
							} else {
								window.open(response.payload.redirectUrl, '_blank');
							}
						} else {
							if (paramsMenu?.paymentMethod === PAYMENT_TYPE_ONEPAY_ALIPAY && !isMobile) {
								// Show QR code
								windowReference.location = `/alipay-qr-code?value=${encodeURIComponent(response.payload.redirectUrl)}&ts=${Date.now()}`;
								window.location.href = redirectUrlSuccess;
							} else {
								windowReference.location = response.payload.redirectUrl;
								setTimeout(() => {
									try {
										const refHostname = windowReference.location.hostname;
										if (refHostname !== 'mobile.alipay.com')
											windowReference.close();
									} catch (e) {}
									window.location.href = redirectUrlSuccess;
								}, 5000);
							} 
						}

            // Payment type = WechatPay
            if (paramsMenu?.paymentMethod === PAYMENT_TYPE_WECHATPAY) {
              if (!isMobile) {
                // Show QR code
                windowReference.location = `/wechatpay-qr-code?value=${encodeURIComponent(response.payload.redirectUrl)}&ts=${Date.now()}`;
                window.location.href = redirectUrlSuccess;
              } else {
                window.location.href = response.payload.redirectUrl;
              }
            }
						return null;
          } else if (response.payload.status === 406) { 
						setErrorMessage(t('pages.Error create gochi'));
						setLoading(false);
						if (windowReference) {
							windowReference.close();
						}
						setDisabledBtn(false); 
					} else if (response.payload.status === 404 || response.payload.status === 400 || response.payload.status === 500) {
						setErrorMessage(t('pages.error create gochi user'));
						setLoading(false);
						if (windowReference) {
							windowReference.close();
						}
						setDisabledBtn(false); 
					} else {
						setErrorMessage(t('pages.error create gochi with guest'));
						setLoading(false);
						if (windowReference) {
							windowReference.close();
						}
						setDisabledBtn(false); 
					} 
        }
      });
    } catch (e) {
      setErrorMessage('Payment error');
      setLoading(false);
      console.log(e);
      setErrorMessage();
    }
  }

  function closeErrorPopup() {
    setOverLimitError(false);
  }

  function handleUseGochi() {
    setLoading(true);
    let action = utilizeGochis(
      {
        shopId: invitation.shopId || params.shopId,
        menuId: invitation.menu ? invitation.menu.menuId : null,
        quantity: quantity,
        gochiBillId: invitation.gochiBillId,
        memo: {
          shopId: invitation.shopId,
        },
        childFoodId: params.childFoodId,
        groupId: invitation.groupId,
        invitationCode: params.code,
        useAmount: paramsMenu?.useAmount,
        useForShopId: params.shopId,
        taxRate
      },
      crfsToken
    );
    dispatch(action).then((response) => {
      if (!response.error && response.type === "POST_USE_GOCHI_SUCCESS") {
        const search = {
          code: params.code,
          useAmount: paramsMenu?.useAmount || 0,
          shopName: shopName
        }
        let invitation_new = { ... invitation, status: 'REGISTERED' };
        history.replace({
          pathname: "/ticket-use-complete",
          state: {
            invitation: invitation_new,
            gochiTipUrl: response.payload.gochiTipUrl,
            couponId: response.payload.list[0].couponId,
            sushiroQrImage: response.payload.list[0].sushiroQrIssue?.qrImageBase64
              ? 'data:image/png;base64,' + response.payload.list[0].sushiroQrIssue?.qrImageBase64
              : null,
            quantity,
            barCodeYoshinoya: response.payload.barCodeYoshinoya,
          },
          search: `?${queryString.stringify(search)}`,
        });
      } else {
        if (
          response.payload &&
          response.payload.data &&
          response.payload.data.errorInfo &&
          response.payload.data.errorInfo.code ===
            "validation.errors.menuCombo.used.over.limit"
        ) {
          generateRandomString();
          setOverLimitError(true);
          setOverLimitErrorMess(t("This ticket can only be used once a day"));
        } else if (
          response.payload &&
          response.payload.data &&
          response.payload.data.errorInfo &&
          response.payload.data.errorInfo.code ===
            "exception.errors.security.has.csrf.token"
        ) {
          generateRandomString();
          setOverLimitError(true);
          setOverLimitErrorMess(
            t("Please wait for a while as it is being processed")
          );
        } else if (
          response.payload &&
          response.payload.data &&
          response.payload.data.errorInfo &&
          response.payload.data.errorInfo.code ===
            "exception.errors.external.coupon.exception"
        ) {
          generateRandomString();
          setOverLimitError(true);
          setOverLimitErrorMess(
            t("external coupon exception")
          );
        } else {
          generateRandomString();
          setOverLimitError(true);
          setOverLimitErrorMess(response.payload?.data?.errorInfo?.content || t("An error has occurred"));
        }
      }
      setLoading(false);
    });
  }

  function toggleCollapse() {
    setCollapseQR((old) => !old);
  }

  const menuComboDetail = invitation.menu && invitation.menu.menuComboDetail
  let transferForShopAmount = 0;
  let priceForOneCoupon = 0;
  if (invitation.menu?.menuCombo) {
    priceForOneCoupon = invitation.menu?.displayAmount
        ? invitation.menu?.displayAmount/menuComboDetail.quantity
        : invitation.menu &&
        invitation.menu.menuComboDetail &&
          invitation.menu.menuComboDetail.includedTaxPriceCombo;
    transferForShopAmount = priceForOneCoupon * quantity;
  } else if (paramsMenu?.menuLimitTotal) {
    transferForShopAmount = Number(paramsMenu?.useAmount);
    priceForOneCoupon = Number(paramsMenu?.useAmount);
  } else {
    transferForShopAmount = invitation.gochiType === "MENU"
    ? (invitation.menu.displayAmount
        ? invitation.menu.displayAmount
        : invitation.menu.includedTaxPrice) * quantity
    : ((invitation.amount + invitation.taxAmount) /
        invitation.couponCount) *
        quantity;
    priceForOneCoupon = invitation.gochiType === "MENU"
    ? (invitation.menu.displayAmount
        ? invitation.menu.displayAmount
        : invitation.menu.includedTaxPrice)
    : ((invitation.amount + invitation.taxAmount) /
        invitation.couponCount);
  }

  const sushiroFlag = invitation.shop?.sushiroFlag || invitation.group?.sushiroFlag || paramsMenu?.invitation?.shop?.sushiroFlag;
  const isShowPrice = invitation.menu?.showPrice !== 0;

  return (
    <>
      <div className="gochi">
        <Header title={t("pages.Use this ticket")} hasBack backAction={backUrl} />

        {!!props.invitation.menuId && !!props.invitation.groupId && !!props.invitation.menuLimitTotal && (
          <SurveyForm surveyType={2} shopId={params.shopId} menuId={props.invitation.menuId}
            gochiBillId={props.invitation.gochiBillId}
            onInitSuccess={() => setShowSurvey(true)}
            onSubmitSuccess={() => setShowSurvey(false)}
            onGetSurveyError={() => setGetSurveyError(true)}
            onInitFailed={() => setShowSurvey(false)}/>
        )}

        {!showSurvey && !getSurveyError && <>
          <div className="add-padding">
          <div className="fs-22 font-weight-bold my-5 text-center">
            ご注文内容の確認
          </div>

          <div className="used-price-box mt-3">
            <div className="top">
              {shopName}
            </div>
            <div className="mid mt-3 mb-2">
              {isShowPrice ? (
              <>
                <span
                  className={
                    transferForShopAmount < 1000000
                      ? 'fs-72 line-height-72'
                      : 'fs-48 line-height-48'
                  }
                >
                  {transferForShopAmount &&
                    transferForShopAmount.toLocaleString()}
                </span>
                <span className="fs-20 line-height-20">円</span>
              </>
              ) : (
                <div className="fs-20 fw-bold">
                  {invitation?.menu?.menuName}
                </div>
              )}
            </div>
            <Moment
              className="bot"
              interval={1000}
              format="YYYY.MM.DD HH:mm:ss"
              local
            />
          </div>

          <div className="px-content-text">
            {isShowPrice ? (
              <>
                <div className="line-break mt-4 fs-18 font-weight-bold">
                  {invitation?.menu?.menuName}
                </div>
                {paramsMenu.menuLimitTotal !== 1 && <div className="fs-18 font-weight-medium mt-2 line-height-18">
                  <span>単価: </span>
                  <span className="font-weight-bold">
                    {priceForOneCoupon && priceForOneCoupon.toLocaleString()}
                  </span>
                  <span className="fs-14">円</span>
                  <span className="ml-4">個数: </span>
                  <span className="font-weight-bold">{
                    `${quantity}`
                  }</span>
                  <span className="fs-14">個</span>
                </div>}
              </>
            ) : (
              paramsMenu.menuLimitTotal !== 1 && quantity > 1 && (
                <div className="fs-18 mt-4 font-weight-medium line-height-18">
                  <span>個数: </span>
                  <span className="font-weight-bold">{
                    `${quantity}`
                  }</span>
                  <span className="fs-14">個</span>
                </div>
            ))}

            {(invitation?.menu?.usageType === USAGE_TYPE_VOUCHER || invitation?.menu?.usageType === USAGE_TYPE_COMBO || !invitation?.menu?.menuId) && (
              <div className="tax-rate-box">
                <div className="required-dot mb-3">
                  <div className="dot-red"></div>
                  <div className="dot-text-label fs-16 fw-bolder">消費税区分</div>
                  <div></div>
                </div>
                <div className="d-flex">
                  <label className="radio-custom fs-15 mb-0">
                    <input
                      type="radio"
                      name="taxRate"
                      value={TAX_RATE_10_PERCENT}
                      checked={taxRate === TAX_RATE_10_PERCENT}
                      onChange={() => setTaxRate(TAX_RATE_10_PERCENT)}
                    />
                    <span className="checkmark"></span>
                    <span>10%対象</span>
                  </label>
                  <label className="radio-custom fs-15 ml-40 mb-0">
                    <input
                      type="radio"
                      name="taxRate"
                      value={TAX_RATE_8_PERCENT}
                      checked={taxRate === TAX_RATE_8_PERCENT}
                      onChange={() => setTaxRate(TAX_RATE_8_PERCENT)}
                    />
                    <span className="checkmark"></span>
                    <span>8%対象</span>
                  </label>
                </div>
              </div>
            )}

            <div className="mt-5 clr-f44a4a font-weight-bold font-size-16">
              ※オーダー確認ボタンはお店の方の指示に従うか、お客様ご自身でタップ下さい。
            </div>
          </div>

          <div
            className="btn btn-use-55 bg-red mt-3"
            onClick={() => setOpenTicketUseConfirm(true)}
          >オーダー確認</div>
          <div
            onClick={backUrl}
            className="btn border-btn mt-3"
          >
            戻る
          </div>
         
        {paramsMenu?.showQRCode && !getSurveyError && !sushiroFlag && (
          <>
            <div
              onClick={toggleCollapse}
              className="mt-5 fs-18 text-center font-weight-bold cursor-pointer"
            >
              {collapseQR
                ? 'QRコードを表示する ▼'
                : 'QRコードを表示する ▲'}
            </div>
            <Collapse in={!collapseQR}>
              <div>
                <div className="align-center font-weight-bold mt-2">
                  {t("pages.QR code for shop")}
                </div>
                <div className="mt-3 mb-3 align-center">
                  {errorMessage ? (
                    <div className="qr-code-error">{errorMessage}</div>
                  ) : (
                    <QRCode
                      renderAs="svg"
                      value={urlValue}
                      className="qr-code"
                      style={{ width: "60%", height: "auto" }}
                    />
                  )}
                </div>
                <div className="wrapper-qr-code mb-3">
                  {!errorMessage && (
                    <div className="font-weight-bold" style={{ color: "red" }}>
                      {t("pages.QR code valid in 1 minute")}
                    </div>
                  )}
                  {seconds > 0 && <div className="font-weight-bold">{seconds}</div>}
                  {seconds <= 0 && (
                    <div
                      className="btn-gen-qr font-weight-bold"
                      onClick={() => {
                        generateAuthenCode();
                        // callBackCheckFinish();
                      }}
                    >
                      {t("pages.QR code re-generation")}
                    </div>
                  )}
                </div>
              </div>
            </Collapse>
          </>
        )}
        <div className="gochi-stamp-combo mt-4">
          <div className="gochi-stamp-combo-info border-radius-0">
            <div>
              <div className="clr-000000 fs-18 font-weight-bold">領収書番号 : {invitation.gochiBillNo}</div>
              <div><span className="fs-12 clr-000000">チケット利用問合先 : </span><a href="tel:050-5536-3317" className="fs-12 text-red clr-000000">050-5536-3317</a></div>
              <div className="text-red">※サポートセンターへの問い合わせ電話です。ご予約は、直接店舗様にお願いいたします。</div>
              <div className="fs-12 clr-000000">平日（月～金） 10:00～18:00／土曜日 10:00～15:00</div>
              <div className="fs-12 clr-000000">※お休み：日曜日・祝日 ・長期休暇・お盆休み・年末年始</div>
            </div>
          </div>
        </div>
        </div>
        </>}
      </div>

      <TicketUseConfirmModal 
        show={openTicketUseConfirm} 
        onCancel={() => setOpenTicketUseConfirm(false)}
        disabledSubmitButton={disabledBtn}
        shopName={shopName}
        transferForShopAmount={transferForShopAmount}
        onSubmit={confirmAction}
        isShowPrice={isShowPrice}
        menuName={invitation?.menu?.menuName}
      />

      <Modal
          show={overLimitError}
          onHide={closeErrorPopup}
      >
        <Modal.Header
          className="rounded-bottom align-items-start"
          closeLabel={false}
        >
          <div className="text-error-limit">
            {overLimitErrorMess}
          </div>
          <i onClick={closeErrorPopup} className="btn-close"></i>
        </Modal.Header>
      </Modal>

      <LoadingOverlay loading={loading} />
    </>
  );
}

const mapStateToProps = (state) => ({
  invitation: state.gochis,
  authenCodeForShop: state.authenCodeForShop,
});

export default connect(mapStateToProps)(withTranslation()(TicketUse));
