import React from 'react';
import { withTranslation } from "react-i18next";
import '../../stylesheets/CommingSoon.css'

class ModalAlert extends React.Component {
  constructor(props) {
    super(props);
    };
  render() {
    const { t } = this.props;
    return(
      <div className="loading-overlay">
        <div className="popup-noti">
          <div>
            {this.props.message}
          </div>
          <button className="btn btn-green-38 btn-reload" onClick={this.props.func}>
            {t('pages.Close')}
          </button>
        </div>
      </div>
    );
  }
}
export default (withTranslation()(ModalAlert));