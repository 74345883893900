import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string' 

function StripeCheckout(props) { 
  let url = window.location.search
  let params = queryString.parse(url)

  const [sessionId, setSessionId] = useState(params.sessionId);
  // const [errorMessage, setErrorMessage] = useState(null);
  // const [stripe, setStripe] = useState(null);
  useEffect(() => { 
    if (window.Stripe) {
      const stripeWin = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
      // setStripe(stripeWin);
      stripeWin.redirectToCheckout({ sessionId: sessionId }).then(function (data) {
        console.log(data);
        if(data.error) { 
          // setErrorMessage(data.error.message);
          return data.error.message;
        }
      }); 
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        const stripeWinJs = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
        // Create Stripe instance once Stripe.js loads
        // setStripe(stripeWinJs);

        stripeWinJs.redirectToCheckout({ sessionId: sessionId }).then(function (data) {
          console.log(data);
          if(data.error) { 
            // setErrorMessage(data.error.message);
            return data.error.message;
          }
        }); 
      });
    } 
    // if (stripeWin) {
    //   stripeWin.redirectToCheckout({ sessionId: sessionId }).then(function (data) {
    //     console.log(data);
    //     if(data.error) { 
    //       setErrorMessage(data.error.message);
    //       return data.error.message;
    //     }
    //   }); 
    // }
  }, [sessionId]); 
    
  return null 
} 

const mapStateToProps = (state) => ({
    invitation: state.gochis
});

export default connect(mapStateToProps)(
withTranslation()(StripeCheckout)
);