import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import queryString from "query-string";
import {useLocation} from "react-router-dom";
import history from "../history";
import moment from "moment";
import { getDetailDaigasShops, createDaigasBill } from "../../actions/Daigas";
import { Button, FormControl, FormLabel, FormCheck } from "react-bootstrap";
import { DaigasNotification } from "../common/DaigasNotification";
import { DaigasHeader } from "../common/DaigasHeader";
import { DaigasFooter } from "../common/DaigasFooter";
import LoadingOverlay from "./LoadingOverlay";
import '../../stylesheets/DaigasInput.css';

function DaigasEmailInput(props) {
  const { dispatch } = props;
  const location = useLocation();

  const NO_IMAGE = '/images/gochi_noimg-ver4.jpg';
  const [shopId, setShopId] = useState(null);
  const [giftCode, setGiftCode] = useState(null);
  const [email, setEmail] = useState('');
  const [detailShop, setDetailShop] = useState(null);
  const [checkbox, setCheckbox] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const currDate = useState(moment().local().format('YYYY-MM-DD'));
  const prizeDeadline = useState('2021-11-30');

  function getShopDetail(shopId, giftCode){
    setLoading(true);
    try {
      dispatch(getDetailDaigasShops(shopId, giftCode)).then((response) => {
        console.log('res shop detail = ', response);
        if (response.type === "GET_DETAIL_DAIGAS_SHOPS_SUCCESS") {
          if (response.payload) {
            setDetailShop(response.payload);
            if (response.payload.daigasUserRegisterCount >= 5) {
              handleBack(giftCode);
            }
          }
        } else if (response.type === "GET_DETAIL_DAIGAS_SHOPS_FAILURE") {
          if (response.payload && response.payload.status === 409) {
            window.location.href = '/daigas/input-complete';
          } else {
            alert("エラーが発生しました。他の店舗でもう一度試してください。");
          }
          setDetailShop(null);
        }
        setLoading(false);
      });
    } catch(e) {
      console.log(e);
      setDetailShop(null);
      setLoading(false);
    }
  }

  function handleCheckBox(e) {
    setCheckbox(e.target.checked);
  }

  function validEmail(email) {
    var pattern = new RegExp(
      /^(("[\w+-\s]+")|([\w+-]+(?:\.[\w+-]+)*)|("[\w+-\s]+")([\w+-]+(?:\.[\w+-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return pattern.test(email);
  }

  function handleBack(giftCode) {
    // window.location.href = `/daigas/shops?giftCode=${giftCode}`;
    history.push({
      pathname: "/daigas/shops",
      search: `giftCode=${giftCode}`,
      data: page
    });
  }

  function handleSubmit() {
    if (validEmail(email)) {
      if (checkbox) {
        setLoading(true);
        setErrorMessage(null);
        try {
          dispatch(createDaigasBill(shopId, giftCode, email)).then((response) => {
            console.log('res shop detail = ', response);
            if (response.type === "CREATE_BILL_SUCCESS") {
              window.location.href = "/daigas/finish";
            } else if (response.type === "CREATE_BILL_FAILURE") {
              if (response.payload) {
                if (response.payload.status === 409) {
                  window.location.href = '/daigas/input-complete';
                } else if (response.payload.status === 410) {
                  alert("本店舗のチケット残数が切れました。他の店舗でもう一度試してください。");
                } else if (response.payload.status === 504) {
                  alert("エラーが発生しました。しばらくしてからメールでご確認ください。メールが届かない場合、再度お試しください。");
                } else if (response.payload.status === 406) {
                  history.push({
                    pathname: "/daigas/event-end"
                  });
                } else {
                  alert("エラーが発生しました。他の店舗でもう一度試してください。");
                }
              }
            }
            setLoading(false);
          });
        } catch(e) {
          console.log(e);
          setLoading(false);
        }
      } else {
        setErrorMessage("ユーザー利用規約に同意してください。");
      }
    } else {
      alert("メールアドレスの形式が正しくありません");
    }
  }

  useEffect(() => {
    window.scrollTo(0,0);
    const params = queryString.parse(window.location.search);
    const shopId = params.shopId;
    const giftCode = params.giftCode;
    if (shopId) {
      setShopId(parseInt(shopId));
    }
    if (giftCode) {
      setGiftCode(giftCode);
    }

    if(location.data) {
      setPage(location.data);
    }

    getShopDetail(shopId, giftCode);
  }, []);

  return (
    <>
      {currDate <= prizeDeadline ? (
        <>
          <DaigasHeader />
          <div className="daigas-content">
            <div className="daigas-info-container">
              <Button className="button-receive-ticket">この店舗のチケットをもらう</Button>
              <img
                className="daigas-menu-img"
                src={(detailShop && detailShop.shopImage && detailShop.shopImage.length > 0 && detailShop.shopImage[0].image) || NO_IMAGE}
                alt="Daigas shop"
              />
              <div className="mt-4 daigas-info-text-box daigas-primary-color fw-600">
                {(detailShop && detailShop.shopName) || ''}
              </div>
              <div className="address-container">
                <img className="daigas-marker" src="/images/daigas/map-marker.svg" alt="marker" />
                <div className="daigas-primary-color daigas-address-text fw-600">
                  {(detailShop && detailShop.prefectures) || ''}{` `}
                  {(detailShop && detailShop.city) || ''}{` `}
                  {(detailShop && detailShop.address) || ''}{` `}
                  {(detailShop && detailShop.building) || ''}
                </div>
              </div>
              <hr className="daigas-hr" />
              <div className="daigas-email-input-container">
                <FormLabel className="daigas-primary-color mr-2 daigas-title-text fw-600">メールアドレス</FormLabel>
                <FormControl
                  className="daigas-email-input"
                  type="text"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="daigas-email-input-container fs-14">
                <FormLabel className="daigas-primary-color mr-2 daigas-title-text fw-600"></FormLabel>
                <FormCheck
                  type="checkbox"
                  className="mb-3 daigas-checkbox"
                  label={(
                    <span>
                      <a
                        href="https://gochimeshi.com/policy/terms/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ごちめしユーザー利用規約
                      </a>に同意する
                    </span>
                  )}
                  onChange={(e) => handleCheckBox(e)}
                >
                </FormCheck>
              </div>
              {errorMessage && (
                <div className="daigas-email-input-container mt-0 daigas-caution-text">
                  <FormLabel className="daigas-primary-color mr-2 daigas-title-text fw-600"></FormLabel>
                  {errorMessage}
                </div>
              )}
              <div className="daigas-button-container">
                <Button
                  className="daigas-outlined-button mr-2"
                  onClick={() => handleBack(giftCode)}
                >
                  戻ってお店を選ぶ
                </Button>
                <Button
                  className="daigas-solid-button"
                  onClick={() => handleSubmit()}
                >
                  決定する
                </Button>
              </div>
            </div>
            <div className="daigas-caution-text-box">
              <div className="daigas-caution-title">注意事項</div>
              <div className="daigas-caution-text">
                {`＊さきめしチケットのご利用期限は2021年12月31日までです。
                ＊お店が閉店・閉業された場合、本チケットはお使いになれません。
                ＊転売することはできません。
                ＊決定後のキャンセルや返金は受け付けておりません。
                ＊チケットメールが届かない場合は事務局までお問い合わせください`}
              </div>
            </div>
            <LoadingOverlay loading={loading} />
          </div>
          <DaigasFooter />
        </>
      ) : (
        <DaigasNotification
          title="受け取り期間は終了しました<br/>たくさんのご利用ありがとうございました"
          message=""
        />
      )}
      

      
    </>
  );
}

export default connect()(withTranslation()(DaigasEmailInput));
