import React from "react";
import { DaigasNotification } from "../common/DaigasNotification";

function DaigasInputComplete(props) {
  return (
    <DaigasNotification
      title="すでにご応募いただいています"
      message="このURLのお渡しは完了しております。"
    />
  );
}

export default DaigasInputComplete;
