import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { numberFormatter } from "../../libs/CommonUtil";

const NO_IMAGE_URL = "/images/gochi_noimg-ver4.jpg"; 

function MenuInfo(props) {
  const { t } = props;
  const invitation = props.completePage ? props.invitationReload : props.invitation;

  console.log('props.invitation reload', invitation);

  const menuComboDetail = invitation.menu && invitation.menu.menuComboDetail;
  const isMenuCombo = invitation.menu && invitation.menu.menuCombo === 1; 
  const isShowPrice = invitation.menu?.showPrice !== 0;
  return (
    <>
    <div className="menu-box align-items-start">
      {props.hideMenuImg ? null : <div className="gif-box-combo-img">
        <figure className="margin0">
          <img
            src={
              invitation.gochiBillImage
                ? invitation.gochiBillImage[0].image !== ""
                  ? invitation.gochiBillImage[0].image
                  : NO_IMAGE_URL
                : NO_IMAGE_URL
            }
            className="rounded-menu-img"
            width="111"
            height="111"
            alt=""
          />
        </figure>
        {isMenuCombo && <div className="div-multi-used-menu">
          <span className="label-multi-used-menu">
            {props.noMultiLang ? "回数券" : t("pages.Coupon")}
          </span>
        </div>}
        {isMenuCombo && invitation.menu.menuComboDetail.quantityFree > 0 && (
          <div className="bonus-label-container">
            {isShowPrice ? (
              <span className="bonus-label">
                {`+`}{props.noMultiLang 
                ? `${invitation.menu.menuComboDetail.quantityFree}枚お得(合計${invitation.menu.menuComboDetail.quantityFree +
                  invitation.menu.menuComboDetail.quantity}枚)` 
                : t('pages.Advantageous (total)', { 
                    freeTicketCount: invitation.menu.menuComboDetail.quantityFree, 
                    totalCoupon: invitation.menu.menuComboDetail.quantityFree +
                    invitation.menu.menuComboDetail.quantity})}
              </span>
            ) : (
              <span className="bonus-label w-100">
                合計{invitation.menu.menuComboDetail.quantityFree + invitation.menu.menuComboDetail.quantity}枚
              </span>
            )}
          </div>
        )}
      </div>}
      <div className="d-flex ml-2 mr-2 flex-column media-detail">
        <div className="text-left text-black">
          {props.showShopName && (
            <div className={props.menuLimitTotal ? 'shopName-total' : 'new-menu-name text-grey'}>
              <span className={props.bigUseAmountStyle ? "fs-24" : ""}>{invitation?.shop?.shopName || props.shopName}</span>
            </div>
          )}
          <div className="new-menu-name">
            <span className={props.bigUseAmountStyle ? "fs-24" : ""}>
              {(invitation.gochiType === "MENU" || (props.paramsMenu && props.paramsMenu.gochiType === "MENU") || invitation.gochiType === "LOCAL_WALLET")
                ? (invitation.menu.menuName || (props.paramsMenu && props.paramsMenu.menuName))
                : props.noMultiLang
                ? "お値段ごち"
                : t("pages.GochiPRICE")}
            </span>
          </div>
          {isMenuCombo && !props.bigUseAmountStyle && (
            <>
              <div className={props.bigUseAmountStyle ? "new-menu-name text-center" : "new-menu-name"}>
                {isShowPrice && (
                  <>
                    <span className={props.bigUseAmountStyle ? "gochi-num text-green" : ""}>
                      {invitation.menu?.displayAmount ? numberFormatter(invitation.menu?.displayAmount/menuComboDetail.quantity) : numberFormatter(
                      menuComboDetail.includedTaxPriceCombo
                    )}
                    </span>
                    <span className={props.bigUseAmountStyle ? "text-green" : ""}>円ｘ</span>
                  </>
                )}
                <span className={props.bigUseAmountStyle ? "gochi-num text-green" : ""}>
                  {menuComboDetail.quantity + menuComboDetail.quantityFree}
                </span>
                <span className={props.bigUseAmountStyle ? "text-green" : ""}>{props.noMultiLang ? "枚" : t("pages.sheet")}</span>

                {menuComboDetail.quantityFree > 0 && isShowPrice && (
                  <>
                    <span className="new-menu-name">
                      {`(${
                        props.noMultiLang
                          ? ""
                          : t("pages.include free prefix")
                      }${menuComboDetail.quantityFree}${
                        props.noMultiLang
                          ? "枚お得"
                          : t("pages.include free postfix")
                      })`}
                    </span>
                  </>
                )}
              </div>
            </>
          )}
          {!props.menuLimitTotal && !isMenuCombo && props.showPrice && <hr className="new-hr mt-1" />}
          {!props.menuLimitTotal && !isMenuCombo && props.showPrice && (
            isShowPrice ? (
              <div>
                {invitation.amount && (
                  <div className="d-flex justify-content-between">
                    <div className="new-menu-name">
                      {props.noMultiLang ? "単価" : t("pages.unit price")}
                    </div>
                    <div className="new-menu-name">
                      {numberFormatter(
                        invitation.gochiType === "MENU"
                          ? invitation.menu.displayAmount
                            ? invitation.menu.displayAmount
                            : invitation.menu.includedTaxPrice
                          : (invitation.amount + invitation.taxAmount) /
                              invitation.couponCount
                      )}
                      円
                    </div>
                  </div>
                )}
                
                <div className="d-flex justify-content-between align-items-center">
                  <div className="new-menu-name">
                    {props.noMultiLang ? "個数" : t("pages.quantity")}
                  </div>
                  <div className="new-menu-name">
                    <span className="fs-23">
                      {invitation.gochiCount
                        ? numberFormatter(invitation.gochiCount)
                        : 0}
                    </span>
                    /
                    <span>
                      {invitation.couponCount
                        ? numberFormatter(invitation.couponCount)
                        : 0}
                      {props.noMultiLang ? "個" : t("pages.pieces")}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              invitation.couponCount > 1 && (
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="new-menu-name">
                      {props.noMultiLang ? "個数" : t("pages.quantity")}
                    </div>
                    <div className="new-menu-name">
                      <span className="fs-23">
                        {invitation.gochiCount
                          ? numberFormatter(invitation.gochiCount)
                          : 0}
                      </span>
                      /
                      <span>
                        {invitation.couponCount
                          ? numberFormatter(invitation.couponCount)
                          : 0}
                        {props.noMultiLang ? "個" : t("pages.pieces")}
                      </span>
                    </div>
                  </div>
                </div>
              )
            )
          )}
          {props.menuLimitTotal & !props.bigUseAmountStyle ? (
            <div className="line-height-normal mt-1">
              {props.useAmount !== 0 && props.showUseAmount ? (
                <div className="text-center number-red">
                  <span className="fs-16">{t('pages.total_count')}</span>
                  <span className="fs-46 mx-1 fw-bolder">
                    {Number(props.useAmount).toLocaleString()}
                  </span>
                  <span className="fs-16">円</span>
                </div>
              ) : (
                <div className="text-center">
                  <span className="fs-16">{t('pages.total_count')}</span>
                  <span className="fs-46 mx-1 fw-bolder">
                    {invitation?.menu?.menuChildType === 5 ? invitation?.menuChildType5.amountCanUse?.toLocaleString() : invitation?.remainAmount?.toLocaleString()}
                  </span>
                  <span className="fs-16">円</span>
                </div>
              )}
              
              {props.remainAmountAfterUse !== undefined && (
                <div className="after-use-balance text-center">
                  <span>{t('pages.Balance after use')}</span>
                  <span>
                    <span className="fs-22 font-weight-normal">{props.remainAmountAfterUse?.toLocaleString()}</span>
                    円
                  </span>
                </div>
              )}
            </div>
          ) : null }
          {props.showUnitPrice && !isMenuCombo && (
            <hr className="new-hr mt-1" />
          )}
          {props.showUnitPrice && !isMenuCombo && isShowPrice && (
            <div className="d-flex align-items-center">
              <div className="new-menu-name">
                1{props.noMultiLang ? "個" : t("pages.pieces")}
              </div>
              {}
              {invitation.amount && (
                <div className="new-menu-name ml-2">
                  {numberFormatter(
                    invitation.gochiType === "MENU"
                      ? invitation.menu.displayAmount
                        ? invitation.menu.displayAmount
                        : invitation.menu.includedTaxPrice
                      : (invitation.amount + invitation.taxAmount) /
                          invitation.couponCount
                  )}
                  円
                </div>
              )}
            </div>
          )}
          {(props.showUsedAmount && !isMenuCombo && !props.menuLimitTotal && !props.bigUseAmountStyle) ? (
            <div className={props.bigUseAmountStyle ? "d-flex align-items-center justify-content-center" : "d-flex align-items-center"}>
              <div className="new-menu-name">
                <span className={props.bigUseAmountStyle ? "gochi-num text-green" : ""}>{invitation.couponCount
                  ? numberFormatter(props.usedQuantity)
                  : 0}</span>
                <span className={props.bigUseAmountStyle ? "text-green" : ""}>{props.noMultiLang ? "個" : t("pages.pieces")}</span>
              </div>
              
              {invitation.amount && (
                <div className="new-menu-name ml-2">
                  <span className={props.bigUseAmountStyle ? "gochi-price text-green" : ""}>{numberFormatter(
                    invitation.gochiType === "MENU"
                      ? invitation.menu.displayAmount
                        ? invitation.menu.displayAmount * props.usedQuantity
                        : invitation.menu.includedTaxPrice * props.usedQuantity
                      : ((invitation.amount + invitation.taxAmount) *
                          props.usedQuantity) /
                          invitation.couponCount
                  )}</span>
                  <span className={props.bigUseAmountStyle ? "text-green" : ""}>円</span>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>

        {/* COMPLETE SCREEN */}

        {isMenuCombo && props.bigUseAmountStyle && (
            <>
              <div className={"new-menu-name text-green text-center"}>                
                <span className="gochi-num">1</span>
                <span>{t("pages.sheet")}</span>
                <span className="ml-1">{t("pages.total sheet")}</span>
                <span className="gochi-price">
                  {invitation.menu?.displayAmount ? 
                    numberFormatter(invitation.menu?.displayAmount/menuComboDetail.quantity) : 
                      numberFormatter(invitation.menu &&
                        invitation.menu.menuComboDetail &&
                          invitation.menu.menuComboDetail.includedTaxPriceCombo)}
                </span>
                <span>円</span>
              </div>
            </>
          )}

          {props.menuLimitTotal & props.bigUseAmountStyle ? (
            <div className="line-height-normal mt-1">
              {props.useAmount !== 0 && props.showUseAmount ? (
                <div className="text-center number-red">
                  <span className="fs-16">{t('pages.total_count')}</span>
                  <span className="fs-46 mx-1 fw-bolder">
                    {Number(props.useAmount).toLocaleString()}
                  </span>
                  <span className="fs-16">円</span>
                </div>
              ) : (
                <div className="text-center">
                  <span className="fs-16">{t('pages.total_count')}</span>
                  <span className="fs-46 mx-1 fw-bolder">
                    {invitation?.remainAmount?.toLocaleString()}
                  </span>
                  <span className="fs-16">円</span>
                </div>
              )}
              
              {props.remainAmountAfterUse !== undefined && (
                <div className="after-use-balance text-center">
                  <span>{t('pages.Balance after use')}</span>
                  <span>
                    <span className="fs-22 font-weight-normal">{props.remainAmountAfterUse?.toLocaleString()}</span>
                    円
                  </span>
                </div>
              )}
            </div>
          ) : null }

          {props.menuChildType5 ? (
            <div className="line-height-normal mt-1">
              <div className="text-center number-red">
                <span className="fs-16">{t('pages.total_count')}</span>
                <span className="fs-46 mx-1 fw-bolder">
                  {Number(props.menuChildType5.amountCanUse).toLocaleString()}
                </span>
                <span className="fs-16">円</span>
              </div>
            </div>
          ) : null }

          {(props.showUsedAmount && !isMenuCombo && !props.menuLimitTotal && props.bigUseAmountStyle) ? (
            <div className={props.bigUseAmountStyle ? "d-flex align-items-center justify-content-center" : "d-flex align-items-center"}>
              <div className="new-menu-name">
                <span className={props.bigUseAmountStyle ? "gochi-num text-green" : ""}>{invitation.couponCount
                  ? numberFormatter(props.usedQuantity)
                  : 0}</span>
                <span className={props.bigUseAmountStyle ? "text-green" : ""}>{props.noMultiLang ? "個" : t("pages.pieces")}</span>
              </div>
              
              {invitation.amount && (
                <div className="new-menu-name ml-2">
                  <span className={props.bigUseAmountStyle ? "gochi-price text-green" : ""}>{numberFormatter(
                    invitation.gochiType === "MENU"
                      ? invitation.menu.displayAmount
                        ? invitation.menu.displayAmount * props.usedQuantity
                        : invitation.menu.includedTaxPrice * props.usedQuantity
                      : ((invitation.amount + invitation.taxAmount) *
                          props.usedQuantity) /
                          invitation.couponCount
                  )}</span>
                  <span className={props.bigUseAmountStyle ? "text-green" : ""}>円</span>
                </div>
              )}
            </div>
          ) : null}

    </>
  );
}

const mapStateToProps = (state) => ({ invitation: state.gochis });

export default connect(mapStateToProps)(withTranslation()(MenuInfo));
