import {
  getSubscriptionApi,
  cancelSubscriptionApi
} from "../libs/apis/SubscriptionApi";

export const GET_SUBSCRIPTION_DETAIL_REQUEST = 'GET_SUBSCRIPTION_DETAIL_REQUEST';
export const GET_SUBSCRIPTION_DETAIL_SUCCESS = 'GET_SUBSCRIPTION_DETAIL_SUCCESS';
export const GET_SUBSCRIPTION_DETAIL_FAILURE = 'GET_SUBSCRIPTION_DETAIL_FAILURE';

export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE';

export const getSubscriptionDetail = (code) => dispatch => {
  dispatch({ type: GET_SUBSCRIPTION_DETAIL_REQUEST });
  return getSubscriptionApi(code)
    .then(response =>
      dispatch({
        type: GET_SUBSCRIPTION_DETAIL_SUCCESS,
        payload: response.data
      })
    )
    .catch(error =>
      dispatch({
        type: GET_SUBSCRIPTION_DETAIL_FAILURE,
        payload: error.response,
        error: true
      })
    );
};

export const cancelSubscription = (code) => dispatch => {
  dispatch({ type: CANCEL_SUBSCRIPTION_REQUEST });
  return cancelSubscriptionApi(code)
    .then(response =>
      dispatch({
        type: CANCEL_SUBSCRIPTION_SUCCESS,
        payload: response.data
      })
    )
    .catch(error =>
      dispatch({
        type: CANCEL_SUBSCRIPTION_FAILURE,
        payload: error.response,
        error: true
      })
    );
};


