import React from 'react';
import { DaigasLogo } from './DaigasLogo';

export function DaigasHeader(props) {
    return (
        <div className="daigas-header">
            <div className="daigas-content header-content">
                <DaigasLogo />
                <a
                    className="text-white text-underline contact-us-text fw-600"
                    href="https://share.hsforms.com/7433685/54ab23fb-98a0-4f52-a220-7023160b3e64"
                >
                    お問い合わせ
                </a>
            </div>
        </div>
    );
}