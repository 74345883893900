import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import gochis from './Gochis';
import menus from './Menus';
import authenCodeForShop from './AuthenCode';

const reducer = combineReducers({
  routing: routerReducer,
  gochis,
  menus,
  authenCodeForShop
});

export default reducer;
