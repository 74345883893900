import axios from "axios";

const API_HOST = process.env.REACT_APP_API_BASE_PATH;
const lang = localStorage.getItem("lang");

axios.defaults.headers.get["acceptLanguage"] = lang;

export const createGochiCode = (params) => {
  return axios.post(`${API_HOST}/gochi/resend-gochi/send-mail`, params, {
    withCredentials: true,
  });
};

export const getInvitation = (params) => {
  return axios.get(`${API_HOST}/gochi/invitations`, {
    params,
    withCredentials: true,
  });
};

export const utilizeGochi = (params, crfsToken) => {
  return axios.put(
    `${API_HOST}/v3/gochi`,
    params,
    { headers: { csrf_token: crfsToken } },
    { withCredentials: true }
  );
};

export const createGochiUrl = (params) => {
  return axios.post(`${API_HOST}/gochi/resend-gochi/authen`, params, {
    withCredentials: true,
  });
};

export const getChildfoodsData = (params) => {
  return axios.get(`${API_HOST}/childShop/foods`, {
    params,
    withCredentials: true,
  });
};

export const getGroupData = (groupId) => {
  return axios.get(`${API_HOST}/childShop/groups/${groupId}`, {
    withCredentials: true,
  });
};

export const getShopData = (menuId) => {
  return axios.get(`${API_HOST}/v2/childShop/shops/${menuId}`, {
    withCredentials: true,
  });
};

export const fetchInviteUrlData = (params) => {
  return axios.post(`${API_HOST}/gochi/inviteUrl/fetch`, params, {
    withCredentials: true,
  });
};

export const postThankYouMessageData = (params) => {
  return axios.post(`${API_HOST}/coupons/userRegister/message`, params, {
    withCredentials: true,
  });
};

export const postReviewMessageData = (couponId, shopMessage) => {
  return axios.post(
    `${API_HOST}/coupons/shop/message`,
    {
      couponId,
      shopMessage,
    },
    { withCredentials: true }
  );
};

export const getReviewMessageData = (couponId) => {
  return axios.get(
    `${API_HOST}/coupons/shop/message/${couponId}`,
    { withCredentials: true }
  );
};

export const getAuthenCodeData = (authenCode) => {
  return axios.get(
    `${API_HOST}/authen/code/${authenCode}`,
    { withCredentials: true }
  );
};

export const createAuthenCode = (params) => {
  return axios.post(`${API_HOST}/authen/code`, params, {
    withCredentials: true,
  });
};

export const createSessionId = (params, crfsToken) => {
  return axios.post(`${API_HOST}/addPayment/session/create`, 
    params, 
    { headers: { csrf_token: crfsToken } }, 
    { withCredentials: true });
};

export const getCompleteSessionData = (merchantPaymentId) => {
  return axios.get(`${API_HOST}/addPayment/session/complete/${merchantPaymentId}`, {
    withCredentials: true,
  });
};

export const getTaxData = () => {
  return axios.get(`${API_HOST}/tax`, {
    withCredentials: true,
  });
};

export const getFeeData = () => {
  return axios.get(`${API_HOST}/fee`, {
    withCredentials: true,
  });
};

export const getGochiChargeHistoryChargeData = (params) => {
  return axios.get(`${API_HOST}/gochi-charge/history-charged`, {
    params,
    withCredentials: true,
  });
};

export const getGochiChargeHistoryUsedData = (params) => {
  return axios.get(`${API_HOST}/gochi-charge/history-used`, {
    params,
    withCredentials: true,
  });
};