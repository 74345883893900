import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getInvitations, utilizeGochis, getAuthenCode } from "../../../actions/Gochis";
import MenuInfo from "../../common/MenuInfo";
import OrderConfirmButton from "../../common/OrderConfirmButton";
import QuantityFreeDes from "../../common/QuantityFreeDes";
import UseQuantity from "../../common/UseQuantity";
import HamburgerMenu from "./../HamburgerMenu";
import queryString from "query-string";
import history from "../../history";
import Modal from "react-modal";
import LoadingOverlay from "./../LoadingOverlay";

const customStyles = {
  overlay: {
    background: "#808080",
    padding: 15,
  },
  content: {},
};

function BookingTicketUseConfirm(props) {
  const { t } = props;
  const { dispatch } = props;
  // const { invitation } = props;
  
  var query = new URLSearchParams(props.location.search);
  var invitationCode = query.get("code");

  // const action = getInvitations({ invitationCode: invitationCode });

  useEffect(() => {
    // dispatch(action);
    getInvitationInfo();
    generateRandomString();
    callBackCheckFinish(authenCode);
    window.scrollTo(0,0);
  }, []);
  
  const params = queryString.parse(window.location.search);
  const shopName = params.shopName;
  const quantity = params.quantity;
  const authenCode = params.authenCode;

  const [invitation, setInvitation] = useState(props.invitation);
  const [crfsToken, setCrfsToken] = useState("");
  const [overLimitError, setOverLimitError] = useState(false);
  const [overLimitErrorMess, setOverLimitErrorMess] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const [loading, setLoading] = useState(false);
  var timerId;

  function cancelAction() {
    history.goBack();
  }

  function generateRandomString(length = 32) {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    setCrfsToken(text);
    console.log("crfs token", text);
  }

  function confirmAction() {
    setLoading(true);
    let action = utilizeGochis(
      {
        shopId: invitation.shopId || params.shopId,
        menuId: invitation.menu ? invitation.menu.menuId : null,
        quantity: quantity,
        gochiBillId: invitation.gochiBillId,
        memo: {
          shopId: invitation.shopId,
        },
        childFoodId: params.childFoodId,
        groupId: invitation.groupId,
        invitationCode: params.code,
      },
      crfsToken
    );
    dispatch(action).then((response) => {
      if (!response.error && response.type === "POST_USE_GOCHI_SUCCESS") {
        let invitation_new = { ... invitation, status: 'REGISTERED' };
        history.push({
          pathname: "/ticket-use-complete",
          state: {
            invitation: invitation_new,
            couponId: response.payload.list[0].couponId,
            quantity
          },
          search: `?code=${params.code}`,
        });
      } else {
        if (
          response.payload &&
          response.payload.data &&
          response.payload.data.errorInfo &&
          response.payload.data.errorInfo.code ===
            "validation.errors.menuCombo.used.over.limit"
        ) {
          setOverLimitError(true);
          setOverLimitErrorMess(t("This ticket can only be used once a day"));
        } else if (
          response.payload &&
          response.payload.data &&
          response.payload.data.errorInfo &&
          response.payload.data.errorInfo.code ===
            "exception.errors.security.has.csrf.token"
        ) {
          setConfirmError(
            t("Please wait for a while as it is being processed")
          );
        }
      }
      setLoading(false);
    });
  }

  function closeErrorPopup() {
    setOverLimitError(false);
  }

  function callBackCheckFinish(authCode) {
    // repeat with the interval of 10 seconds
    timerId = setInterval(() => handleCheckFinish(authCode), 10*1000);

    // after 60 seconds stop
    setTimeout(() => { clearInterval(timerId); return; }, 60*1000);
  }

  function handleCheckFinish(authCode){
    let invitation = loadFromSessionStorage();
    if (authCode) {
      let action = getAuthenCode(authCode);
      dispatch(action).then((response) => {
        if (response.type === "GET_AUTHEN_CODE_SUCCESS") {
          if (response.payload && response.payload.status === 'FINISHED') {
            let couponId = response.payload.extraData && response.payload.extraData.couponIds 
                            && response.payload.extraData.couponIds.length > 0 
                            && response.payload.extraData.couponIds[0];
            history.push({
              pathname: "/ticket-use-complete",
              state: {
                invitation,
                couponId,
                quantity
              },
              search: `?code=${invitationCode}`,
            });
            clearTimeout(timerId);
          }
        }
      });
    }
  }

  function getInvitationInfo() {
    try {
      let action = getInvitations({ invitationCode: invitationCode });
      dispatch(action).then((response) => {
        if (response.type === 'GET_GOCHIS_SUCCESS') {
          saveToSessionStorage(response.payload);
          setInvitation(response.payload);
        } else {
          sessionStorage.removeItem('store');
          window.location.href = `/gift?code=${invitationCode}`;
        }
      });
    } catch (e) {
      console.log(e);
      sessionStorage.removeItem('store');
      window.location.href = `/gift?code=${invitationCode}`;
    }
  }

  function saveToSessionStorage(store) {
    try {
      const serializedStore = JSON.stringify(store);
      sessionStorage.setItem('store', serializedStore);
    } catch(e) {
      console.log(e);
    }
  }

  function loadFromSessionStorage() {
    try {
      const serializedStore = sessionStorage.getItem('store');
      if (serializedStore === null) return undefined;
      return JSON.parse(serializedStore);
    } catch(e) {
      console.log(e);
      return undefined;
    }
  }

  return (
    <>
      <div className="gochi">
        <div className="title-new title-with-buger">
          <button
            type="button"
            className="icon-btn-back icon-vertical"
            onClick={() => window.history.back()}
          >
            <i className="arrow left width-15"></i>
          </button>
          {t("pages.Confirm")}
          <HamburgerMenu />
        </div>
        <div className="mt-3">
          <ul className="progressbar align-center">
            <li className="step-choice">
              <strong>STEP 1</strong>
            </li>
            <li className="step-select active">
              <strong>STEP 2</strong>
            </li>
            <li className="step-confirm">
              <strong>STEP 3</strong>
            </li>
          </ul>
        </div>
        <div className="red-message-box">{t("pages.Screen only for shop")}</div>
        {confirmError && (
          <div className="text-red bold mt-2">{confirmError}</div>
        )}

        <MenuInfo 
          showShopName 
          shopName={shopName} 
          menuLimitTotal={invitation?.menuLimitTotal === 1} 
          hideAmount
        />

        <QuantityFreeDes />

        <UseQuantity quantity={quantity} />

        <OrderConfirmButton
          confirmAction={confirmAction}
          cancelAction={cancelAction}
        />

        <Modal
          isOpen={overLimitError}
          onRequestClose={closeErrorPopup}
          style={customStyles}
          className="modail-content"
        >
          <div className="modal-use pt-0">
            <i onClick={closeErrorPopup} className="icon-close"></i>

            <div className="text-error-limit">{overLimitErrorMess}</div>
          </div>
        </Modal>
      </div>

      <LoadingOverlay loading={loading} />
    </>
  );
}

const mapStateToProps = (state) => ({
  invitation: state.gochis
});

export default connect(mapStateToProps)(
  withTranslation()(BookingTicketUseConfirm)
);
