import axios from 'axios';

const API_HOST = process.env.REACT_APP_API_BASE_PATH;
const API_SAKIMESHI = process.env.REACT_APP_SAKIMESHI_API;

const lang = localStorage.getItem('lang');

axios.defaults.headers.get['acceptLanguage'] = lang;

export const getPrefecturesApi = () => {
  var url = `${API_SAKIMESHI}/prefecture/list`;
  return axios.get(`${url}`, { withCredentials: false });
};

export const getCitiesApi = () => {
  var url = `${API_SAKIMESHI}/city/list`;
  return axios.get(`${url}`, { withCredentials: false });
};

export const getGenresApi = () => {
  var url = `${API_SAKIMESHI}/genre/list`;
  return axios.get(`${url}`, { withCredentials: false });
};

export const getShopsApi = (prefectureName, cityName, genreId, keyword, page, limit, giftCode) => {
  let params = [];
  params.push(`giftCode=${giftCode}`);
  params.push(`prefectures=${prefectureName}`);
  params.push(`city=${cityName}`);
  params.push(`genre=${genreId}`);
  params.push(`keyword=${keyword}`);
  params.push(`page=${page}`);
  params.push(`limit=${limit}`);
  var url = `${API_HOST}/daigas/shops?groupId=575&${params.join("&")}`;
  return axios.get(`${url}`, { withCredentials: true });
};

export const getShopDetailApi = (shopId, giftCode) => {
  var url = `${API_HOST}/daigas/${shopId}/detail?giftCode=${giftCode}`;
  return axios.get(`${url}`, { withCredentials: true });
};

export const createBillApi = (shopId, giftCode, email) => {
  var url = `${API_HOST}/daigas/createBill`;
  return axios.post(
    url,
    {
      shopId,
      giftCode,
      emailAddress: email
    },
    { withCredentials: true }
  );
};
