import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import MenuInfo from "../../common/MenuInfo";
import HamburgerMenu from "./../HamburgerMenu";
import ReactModal from "react-modal";
import queryString from "query-string";
import {
  postReviewMessage,
  getReviewMessage,
  POST_REVIEW_MESSAGE_SUCCESS,
  GET_REVIEW_MESSAGE_SUCCESS,
  // getInvitations
} from "../../../actions/Gochis";
import history from "../../history";

const customStyles = {
  overlay: {
    background: "#444444",
    padding: 15,
  },
};

function BookingTicketUseComplete(props) {
  const { t } = props;
  const { dispatch } = props;

  useEffect(() => {
    // dispatch(action);
    getReviewedMessage();
    window.scrollTo(0,0);
  }, []);

  const location = props.location;
  const params = queryString.parse(window.location.search);
  const invitation = location.state && location.state.invitation;
  // const invitation = props.invitation;

  const shopName = invitation && invitation.shopName;
  const couponId = location.state && location.state.couponId;
  const usedQuantity = location.state && location.state.quantity;
  const [showReviewMessage, setShowReviewMessage] = useState(true);
  const [sentMessage, setSentMessage] = useState("");
  const [showSendMessageConfirm, setShowSendMessageConfirm] = useState(false);
  const [message, setMessage] = useState("");
  

  // const action = getInvitations({ invitationCode: params.code });

  function onOpenSendMessageConfirm() {
    if (message) setShowSendMessageConfirm(true);
  }

  function viewTicket() {
    history.push({
      pathname: "/gift",
      search: `?code=${params.code}`,
    });
  }

  function sendReviewMessage() {
    setShowSendMessageConfirm(false);
    dispatch(postReviewMessage(couponId, message)).then((response) => {
      console.log("data ===", response);
      if (response.type === POST_REVIEW_MESSAGE_SUCCESS) {
        setShowReviewMessage(false);
        setSentMessage(message);
      } else {
      }
    });
  }

  function getReviewedMessage() {
    dispatch(getReviewMessage(couponId)).then((response) => {
      console.log("data ===", response);
      if (response.type === GET_REVIEW_MESSAGE_SUCCESS) {
        if (response.payload.shopMessage) {
          setSentMessage(response.payload.shopMessage);
          setShowReviewMessage(false);
        }
      } else {
      }
    });
  }

  const couponLeft = invitation && invitation.gochiCount - usedQuantity;

  return (
    <>
      <audio autoPlay src="/audio/gochi_jingle_master_final.mp3" />
      <div className="gochi">
        <div className="title-new title-with-buger">
          {t("pages.Complete")}
          <HamburgerMenu />
        </div>
        <div className="mt-3">
          <ul className="progressbar align-center">
            <li className="step-choice">
              <strong>STEP 1</strong>
            </li>
            <li className="step-select">
              <strong>STEP 2</strong>
            </li>
            <li className="step-confirm active">
              <strong>STEP 3</strong>
            </li>
          </ul>
        </div>

        <img src="/images/THANK_YOU.png" alt="Thank You" width="100%" />

        <MenuInfo
          showShopName
          shopName={shopName}
          showUsedAmount
          usedQuantity={usedQuantity}
          cachedInvitation={invitation}
          menuLimitTotal={invitation?.menuLimitTotal === 1} 
          hideAmount
        />

        {showReviewMessage ? (
          <>
            <div className="text-black font-weight-bold fs-13">
              {t(
                "pages.Would you like to send a support message / review to the store"
              )}
            </div>
            <textarea
              maxLength={300}
              className="send-message-text-box"
              placeholder={t("pages.Message placeholder")}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
            <div
              className="btn btn-green-38 flex_center new-btn"
              style={{opacity: message ? '100%' : '50%'}}
              onClick={onOpenSendMessageConfirm}
            >
              <span>{t("pages.Send a message review")}</span>
            </div>
          </>
        ) : (
          <>
            <div className="text-black font-weight-bold fs-13 mt-4 mb-2">
              {t("pages.I send a review message")}
            </div>
            <div className="sent-thank-message-container">
              <div className="fs-35 font-weight-bold">MESSAGE</div>
              <div className="fs-35 font-weight-bold">THANK YOU</div>
              <div className="fs-18 line-break">{sentMessage}</div>
            </div>
          </>
        )}

        <hr className="new-hr my-2" />

        {couponLeft > 0 && (
          <div
            className="btn btn-green-38 flex_center new-btn"
            onClick={viewTicket}
          >
            <span>{t("pages.View ticket")}</span>
          </div>
        )}

        <div className="fs-13 font-weight-bold text-black mt-3">
          {t(
            "pages.Why dont you use the feast with a reward for the person you feasted on and a gratitude for your loved one"
          )}
        </div>

        <a
          href="https://gochi.online/"
          className="border-bt new-btn btn-white-green flex_center mt-3"
        >
          {t("pages.Go to the feast website")}
        </a>
      </div>

      <ReactModal
        className="modail-content"
        size="sm"
        isOpen={showSendMessageConfirm}
        onRequestClose={() => {
          setShowSendMessageConfirm(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        style={customStyles}
      >
        <div className="fs-21 text-black text-center font-weight-normal">
          {t("pages.Message confirmation")}
        </div>

        <div className="fs-13 text-grey text-center font-weight-normal">
          {t("pages.The following thank you message will be sent")}
        </div>
        <hr className="new-hr my-1" />
        <div className="message-confirm text-grey line-break">{message}</div>
        <hr className="new-hr my-1" />
        <div
          className="btn btn-green-38 flex_center new-btn"
          onClick={sendReviewMessage}
        >
          <span>{t("pages.Send a message review")}</span>
        </div>
        <div className="fs-15 clr-000000 align-center mt-2">
          <button
            onClick={() => {
              setShowSendMessageConfirm(false);
            }}
            className="btn btn-close-popup"
          >
            {t("pages.Cancel")}
          </button>
        </div>
      </ReactModal>
    </>
  );
}

export default connect()(
  withTranslation()(BookingTicketUseComplete)
);
