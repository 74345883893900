import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import queryString from "query-string";
import { getInvitations, getAuthenCode, utilizeGochis } from "../../actions/Gochis";
import LoadingOverlay from "./LoadingOverlay";
import history from "../history";
import Header from "../common/Header";
import Moment from "react-moment";
import TicketUseConfirmModal from "../components/TicketUseConfirmModal";
import { Modal } from "react-bootstrap";
import { TAX_RATE_10_PERCENT } from "../../constants";

function ShopTicketUse(props) {
  const { t } = props;
  const { dispatch, invitation } = props;
  const params = queryString.parse(window.location.search);

  const [isValidCode, setIsValidCode] = useState(true);
  const [isValidInvitation, setIsValidInvitation] = useState(true);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [shopName, setShopName] = useState('');
  const [taxRate, setTaxRate] = useState(TAX_RATE_10_PERCENT);

  const [crfsToken, setCrfsToken] = useState("");
  const [overLimitError, setOverLimitError] = useState(false);
  const [overLimitErrorMess, setOverLimitErrorMess] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const [isValidUsingGochi, setIsValidUsingGochi] = useState(true);
  const [shopId, setShopId] = useState(null);
  const [openTicketUseConfirm, setOpenTicketUseConfirm] = useState(false);

  var query = new URLSearchParams(props.location.search);
  var authenCode = query.get("authenCode");
  var useAmount = query.get("useAmount");
  var menuLimitTotal = query.get("menuLimitTotal");

  function getAuthenCodeInfo() {
    let action = getAuthenCode(authenCode);
    dispatch(action).then((response) => {
      if (response.type === "GET_AUTHEN_CODE_SUCCESS") {
        setIsValidCode(true);
        if (response.payload) {
          setShopId(response.payload.authenData && response.payload.authenData.shopId);
          setShopName(response.payload.extraData && response.payload.extraData.shopName);
          setQuantity(response.payload.authenData && response.payload.authenData.quantity);
          setTaxRate(response.payload.extraData && response.payload.extraData.taxRate);
        }
      } else {
        setIsValidCode(false);
      }
    });
  }

  function getInvitationInfo() {
    setLoading(true);
    try {
      let action = getInvitations({ authenCode: authenCode });
      dispatch(action).then((response) => {
        if (response.type === 'GET_GOCHIS_SUCCESS') {
          setIsValidInvitation(true);
        } else {
          setIsValidInvitation(false);
        }
        setLoading(false);
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  useEffect(() => {
    getAuthenCodeInfo();
    getInvitationInfo();
    generateRandomString();
  }, []);

  function generateRandomString(length = 32) {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    setCrfsToken(text);
    console.log("crfs token", text);
  }

  function confirmAction() {
    setLoading(true);
    let urlComplete = '';
    if (menuLimitTotal === 'true') {
      urlComplete = `?authenCode=${authenCode}&menuLimitTotal=true&useAmount=${useAmount}&shopName=${shopName || params.shopName}`;
    } else {
      urlComplete = `?authenCode=${authenCode}`;
    }
    let action = utilizeGochis(
      {
        shopId: invitation.shopId || shopId,
        menuId: invitation.menu ? invitation.menu.menuId : null,
        quantity: quantity,
        gochiBillId: invitation.gochiBillId,
        memo: {
          shopId: invitation.shopId,
        },
        // childFoodId: params.childFoodId,
        groupId: invitation.groupId,
        authenCode: authenCode,
        useAmount: useAmount ? Number(useAmount) : null,
        taxRate
      },
      crfsToken
    );
    dispatch(action).then((response) => {
      if (!response.error && response.type === "POST_USE_GOCHI_SUCCESS") {
        setIsValidUsingGochi(true);
        let invitation_new = { ... invitation, status: 'REGISTERED' };
        history.push({
          pathname: "/shop/ticket-use-complete",
          state: {
            invitation: invitation_new,
            shopName,
            // couponId: response.payload.list[0].couponId,
            quantity,
          },
          search: urlComplete,
        });
      } else {
        if (
          response.payload &&
          response.payload.data &&
          response.payload.data.errorInfo &&
          response.payload.data.errorInfo.code ===
            "validation.errors.menuCombo.used.over.limit"
        ) {
          generateRandomString();
          setOverLimitError(true);
          setOverLimitErrorMess(t("This ticket can only be used once a day"));
        } else if (
          response.payload &&
          response.payload.data &&
          response.payload.data.errorInfo &&
          response.payload.data.errorInfo.code ===
            "exception.errors.security.has.csrf.token"
        ) {
          generateRandomString();
          setOverLimitError(true);
          setOverLimitErrorMess(
            t("Please wait for a while as it is being processed")
          );
        } else if (
          response.payload &&
          response.payload.data &&
          response.payload.data.errorInfo &&
          response.payload.data.errorInfo.code ===
            "exception.errors.external.coupon.exception"
        ) {
          generateRandomString();
          setOverLimitError(true);
          setOverLimitErrorMess(
            t("external coupon exception")
          );
        } else if (
          response.payload &&
          response.payload.data &&
          response.payload.data.errorInfo &&
          response.payload.data.errorInfo.code ===
            "authen.code.invalid.use"
        ) {
          setIsValidUsingGochi(false);
          setOpenTicketUseConfirm(false);
        } else {
          generateRandomString();
          setOverLimitError(true);
          setOverLimitErrorMess(response.payload?.data?.errorInfo?.content || t("An error has occurred"));
        }
      }
      setLoading(false);
    });
  }

  function closeErrorPopup() {
    setOverLimitError(false);
  }

  const menuComboDetail = invitation.menu && invitation.menu.menuComboDetail
  let transferForShopAmount = 0;
  let priceForOneCoupon = 0;
  if (invitation.menu?.menuCombo) {
    priceForOneCoupon = invitation.menu?.displayAmount
        ? invitation.menu?.displayAmount/menuComboDetail.quantity
        : invitation.menu &&
        invitation.menu.menuComboDetail &&
          invitation.menu.menuComboDetail.includedTaxPriceCombo;
    transferForShopAmount = priceForOneCoupon * quantity;
  } else if (menuLimitTotal) {
    transferForShopAmount = Number(useAmount);
    priceForOneCoupon = Number(useAmount);
  } else {
    transferForShopAmount = invitation.gochiType === "MENU"
    ? (invitation.menu.displayAmount
        ? invitation.menu.displayAmount
        : invitation.menu.includedTaxPrice) * quantity
    : ((invitation.amount + invitation.taxAmount) /
        invitation.couponCount) *
        quantity;
    priceForOneCoupon = invitation.gochiType === "MENU"
    ? (invitation.menu.displayAmount
        ? invitation.menu.displayAmount
        : invitation.menu.includedTaxPrice)
    : ((invitation.amount + invitation.taxAmount) /
        invitation.couponCount);
  }
  const isShowPrice = invitation.menu?.showPrice !== 0;

  return (
    <>
      <div className="gochi">
        <Header title={t("pages.Use this ticket")} />
        <div className="add-padding">
        <div className="fs-22 font-weight-bold my-5 text-center">
          ご注文内容の確認
        </div>

        {confirmError && (
          <div className="text-red bold mt-2">{confirmError}</div>
        )}
    
        {isValidCode && isValidInvitation && isValidUsingGochi ? (
          <>

        <div className="used-price-box mt-3">
            <div className="top">
              {shopName}
            </div>
            <div className="mid mt-3 mb-2">
              {isShowPrice ? (
                <>
                  <span
                    className={
                      transferForShopAmount < 1000000
                        ? 'fs-72 line-height-72'
                        : 'fs-48 line-height-48'
                    }
                  >
                    {transferForShopAmount &&
                      transferForShopAmount.toLocaleString()}
                  </span>
                  <span className="fs-20 line-height-20">円</span>
                </>
              ) : (
                <div className="fs-20 fw-bold">
                  {invitation?.menu?.menuName}
                </div>
              )}
            </div>
            <Moment
              className="bot"
              interval={1000}
              format="YYYY.MM.DD HH:mm:ss"
              local
            />
          </div>

          <div className="px-content-text">
            {isShowPrice ? (
              <>
                <div className="line-break mt-4 fs-18 font-weight-bold">
                  {invitation?.menu?.menuName}
                </div>
                {menuLimitTotal !== 'true' && <div className="fs-18 font-weight-medium mt-2 line-height-18">
                  <span>単価: </span>
                  <span className="font-weight-bold">
                    {priceForOneCoupon && priceForOneCoupon.toLocaleString()}
                  </span>
                  <span className="fs-14">円</span>
                  <span className="ml-4">個数: </span>
                  <span className="font-weight-bold">{
                    `${quantity}`
                  }</span>
                  <span className="fs-14">個</span>
                </div>}
              </>
            ) : (
              menuLimitTotal !== 'true' && quantity > 1 && (
              <div className="fs-18 mt-4 font-weight-medium line-height-18">
                <span>個数: </span>
                <span className="font-weight-bold">{
                  `${quantity}`
                }</span>
                <span className="fs-14">個</span>
              </div>
            ))}

            <div className="mt-5 clr-f44a4a font-weight-bold font-size-16">
              ※オーダー確認ボタンはお店の方の指示に従うか、お客様ご自身でタップ下さい。
            </div>
          </div>

          <div className="mt-3">
            <div
              className="btn btn-use-55 bg-red"
              onClick={() => setOpenTicketUseConfirm(true)}
            >オーダー確認</div>
          </div>
          </>
        ) : (
          <div
            className="align-center font-weight-bold fs-20"
            style={{
              color:'red',
              marginTop: '40px'
            }}
          >
            QRコードが無効になりました。
          </div>
        )}

        <div className="gochi-stamp-combo mt-4">
          <div className="gochi-stamp-combo-info border-radius-0">
            <div>
              <div className="clr-000000 fs-18 font-weight-bold">領収書番号 : {invitation.gochiBillNo}</div>
              <div><span className="fs-12 clr-000000">チケット利用問合先 : </span><a href="tel:050-5536-3317" className="fs-12 text-red clr-000000">050-5536-3317</a></div>
              <div className="text-red">※サポートセンターへの問い合わせ電話です。ご予約は、直接店舗様にお願いいたします。</div>
              <div className="fs-12 clr-000000">平日（月～金） 10:00～18:00／土曜日 10:00～15:00</div>
              <div className="fs-12 clr-000000">※お休み：日曜日・祝日 ・長期休暇・お盆休み・年末年始</div>
            </div>
          </div>
        </div>
        </div>
      </div>

      <TicketUseConfirmModal 
        show={openTicketUseConfirm} 
        onCancel={() => setOpenTicketUseConfirm(false)}
        shopName={shopName}
        transferForShopAmount={transferForShopAmount}
        onSubmit={confirmAction}
        menuName={invitation?.menu?.menuName}
        isShowPrice={isShowPrice}
      />

      <Modal
          show={overLimitError}
          onHide={closeErrorPopup}
      >
        <Modal.Header
          className="rounded-bottom align-items-start"
          closeLabel={false}
        >
          <div className="text-error-limit">
            {overLimitErrorMess}
          </div>
          <i onClick={closeErrorPopup} className="btn-close"></i>
        </Modal.Header>
      </Modal>

      <LoadingOverlay loading={loading} />
    </>
  );
}

const mapStateToProps = (state) => ({
  invitation: state.gochis
});

export default connect(mapStateToProps)(
  withTranslation()(ShopTicketUse)
);
