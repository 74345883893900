import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import "../../../stylesheets/GochiBooking.css";

class BookingTicketComplete extends Component {
  viewTopPage() {
    window.location.href = process.env.REACT_APP_GIFT_SITE_URL;
  }

  render() {
    const { t } = this.props;

    return (
      this.props.stepBooking === "COMPLETE" && (
        <>
          <div className="gochi">
            <div className="title-new title-with-buger">
              {this.props.t("pages.Booking_form")}
              <button
                type="button"
                className="icon-btn-back icon-vertical"
                onClick={this.props.handleCloseBookingPopup}
              >
                <i className="icon-close"></i>
              </button>
            </div>

            <div className="mt-3">
              <ul className="progressbar align-center">
                <li className="step-choice">
                  <strong>STEP 1</strong>
                </li>
                <li className="step-select">
                  <strong>STEP 2</strong>
                </li>
                <li className="step-confirm active">
                  <strong>STEP 3</strong>
                </li>
              </ul>
            </div>
            <div className="booking-body">
              <div className="fs-14 title-new">
                {t("pages.Booking_ticket_done")}
              </div>
              <div className="fs-22 font-weight-bold clr-34A99E text-center mt-5">
                {t("pages.Booking_ticket_done_user")}
              </div>
              <div className="fs-14 line-break mt-3">
                {t("pages.Booking_ticket_done_shop")}
              </div>
              <div className="flex_center flex_column mt-5">
                <div
                  className="border-bt new-btn btn-white-green flex_center mt-3"
                  onClick={() => this.viewTopPage()}
                >
                  <span>{t("pages.Booking_view_my_page")}</span>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  invitation: state.gochis,
});

export default connect(mapStateToProps)(
  withTranslation()(BookingTicketComplete)
);
