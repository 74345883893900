import React from "react";
import { Modal } from "react-bootstrap";
import Moment from "react-moment";

function TicketUseConfirmModal(props) {
  const { transferForShopAmount, shopName, isShowPrice, menuName } = props;
  return (
    <Modal
      show={props.show}
      centered
      onHide={props.onCancel}
      className="modal-ticket"
      dialogClassName="ticket-use-dialog"
    >
      <Modal.Header>
        <div className="header-cnt w-100">
          <Modal.Title className="fs-22 font-weight-bold">
            お支払いの確定
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="fs-26 font-weight-bold text-break">
          {shopName}
        </div>

        <div className="mt-4 d-flex flex-column align-item-center text-center">
          <div className="mt-3 mb-2">
            {isShowPrice ? (
              <>
                <span
                  className={
                    transferForShopAmount < 1000000
                      ? 'fs-72 line-height-72 font-weight-bold'
                      : 'fs-48 line-height-48 font-weight-bold'
                  }
                >
                  {transferForShopAmount && transferForShopAmount.toLocaleString()}
                </span>
                <span className="fs-20 line-height-20">円</span>
              </>
            ) : (
              <div className="fs-20 fw-bold">
                {menuName}
              </div>
            )}
          </div>
          <Moment
            className="fs-24 font-weight-medium"
            interval={1000}
            format="YYYY.MM.DD HH:mm:ss"
            local
          />
        </div>

        <div className="mt-3">
          <button
            className="btn btn-use-55 bg-red"
            onClick={props.onSubmit}
            disabled={props.disabledSubmitButton}
          >
            お支払いを確定
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default TicketUseConfirmModal;
