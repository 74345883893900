// // the translations
import translationEN from './en.json';
import translationJP from './ja.json';
import translationZH from './zh.json'

export const resources = {
  en: {
    translation: translationEN
  },
  ja: {
    translation: translationJP
  },
  zh: {
    translation: translationZH
  }
};
