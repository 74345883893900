import { postBookingData } from "../libs/apis/BookingApi";

export const POST_BOOKING_REQUEST = 'POST_BOOKING_REQUEST';
export const POST_BOOKING_SUCCESS = 'POST_BOOKING_SUCCESS';
export const POST_BOOKING_FAILED = 'POST_BOOKING_FAILED';

export const postBooking = params => dispatch => {
    dispatch({ type: POST_BOOKING_REQUEST });
    return postBookingData(params)
      .then(response =>
        dispatch({
          type: POST_BOOKING_SUCCESS,
          payload: response.data
        })
      )
      .catch(error =>
        dispatch({
          type: POST_BOOKING_FAILED,
          payload: error.response,
          error: true
        })
      );
  }; 