import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { numberFormatter } from "../../libs/CommonUtil";
import {
  getSubscriptionDetail,
  cancelSubscription,
} from "../../actions/Subscription";
import { Modal, Button, ModalBody } from "react-bootstrap";
import LoadingOverlay from "./LoadingOverlay";
import { withTranslation } from "react-i18next";
import moment from "moment";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Header from "../common/Header";

function GochiSubscription(props) {
  const NO_IMAGE_URL = "/images/gochi_noimg-ver4.jpg";
  const { t } = props;
  const { dispatch } = props;

  const [subscription, setSubscription] = useState(null);
  const [isMenuCombo, setIsMenuCombo] = useState(false);
  const [menuComboDetail, setMenuComboDetail] = useState({});
  const [expirationEndDate, setExpirationEndDate] = useState(null);
  const [openPopupConfirm, setOpenPopupConfirm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  function onFinish() {
    window.location.href = process.env.REACT_APP_GIFT_SITE_URL;
    setSuccessMessage("");
  }

  function handleSubmit(code) {
    setLoading(true);
    try {
      dispatch(cancelSubscription(code)).then((response) => {
        if (response.type === "CANCEL_SUBSCRIPTION_SUCCESS") {
          setOpenPopupConfirm(false);
          setSuccessMessage("サブスクリプションをキャンセルされました。");
        } else if (response.type === "CANCEL_SUBSCRIPTION_FAILURE") {
          setOpenPopupConfirm(false);
          setErrorMessage("システムに問題が発生しました。");
        }
        setLoading(false);
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  function getDetailInfo(code) {
    setLoading(true);
    try {
      dispatch(getSubscriptionDetail(code)).then((response) => {
        console.log("response ===", response);
        if (response.type === "GET_SUBSCRIPTION_DETAIL_SUCCESS") {
          if (!!response.payload.deletedAt) {
            setLoading(false);
            return;
          }
          if (response.payload) {
            setSubscription(response.payload);
            if (
              response.payload.menu &&
              response.payload.menu.menuCombo === 1
            ) {
              setIsMenuCombo(true);
            }
            if (
              response.payload.menu &&
              response.payload.menu.menuComboDetail
            ) {
              setMenuComboDetail(response.payload.menu.menuComboDetail);
            }
            if (
              response.payload.menu &&
              response.payload.menu.menuComboDetail &&
              response.payload.menu.menuComboDetail.expirationEndDate
            ) {
              let date = new Date(
                response.payload.menu.menuComboDetail.expirationEndDate
              );
              setExpirationEndDate(date);
            }
          }
        } else if (response.type === "GET_SUBSCRIPTION_DETAIL_FAILURE") {
          setErrorMessage("システムに問題が発生しました。");
        }
        setLoading(false);
      });
    } catch (e) {
      console.log(e);
      setSubscription(null);
      setLoading(false);
    }
  }

  useEffect(() => {
    const params = queryString.parse(window.location.search);
    const subscriptionCode = params.code;

    getDetailInfo(subscriptionCode);
  }, []);

  return (
    <>
      {subscription ? (
        <div className="gochi">
          <div id="menu">
            <Header title="サブスクリプション" />

            <div className="menu-box align-items-start">
              <div className="gif-box-combo-img">
                <figure className="margin0">
                  <img
                    src={
                      subscription.menuImage && subscription.menuImage[0]
                        ? subscription.menuImage[0].image !== ""
                          ? subscription.menuImage[0].image
                          : NO_IMAGE_URL
                        : NO_IMAGE_URL
                    }
                    className="rounded-menu-img"
                    width="111"
                    height="111"
                    alt=""
                  />
                </figure>
                {isMenuCombo && (
                  <div className="div-multi-used-menu">
                    <span className="label-multi-used-menu">回数券</span>
                  </div>
                )}
                {isMenuCombo && menuComboDetail.quantityFree > 0 && (
                  <div className="bonus-label-container">
                    <span className="bonus-label">
                      +{menuComboDetail.quantityFree}
                      枚無料(合計
                      {menuComboDetail.quantityFree + menuComboDetail.quantity}
                      枚)
                    </span>
                  </div>
                )}
              </div>
              <div className="d-flex ml-2 mr-2 flex-column media-detail">
                <div className="text-left text-black">
                  {/* {props.showShopName && (
                    <div className="new-menu-name text-grey">
                      {invitation.shop ? invitation.shop.shopName : props.shopName}
                    </div>
                  )} */}
                  <div className="new-menu-name">
                    {subscription.menu.menuName}
                  </div>
                  {/* {isMenuCombo && (
                    <>
                      <div className="new-menu-name">
                        {`${numberFormatter(
                          menuComboDetail.includedTaxPriceCombo
                        )}円ｘ${
                          menuComboDetail.quantity + menuComboDetail.quantityFree
                        }枚`}

                        {menuComboDetail.quantityFree > 0 && (
                          <>
                            <span className="new-menu-name">
                              {`(おまけ${menuComboDetail.quantityFree}枚含)`}
                            </span>
                          </>
                        )}
                      </div>
                    </>
                  )}
                  {!isMenuCombo && (
                    <>
                      <hr className="new-hr mt-1" />
                      {subscription.amount && (
                        <div className="d-flex justify-content-between">
                          <div className="new-menu-name">
                            単価
                          </div>
                          <div className="new-menu-name">
                            {numberFormatter(
                              subscription.menu.displayAmount ? subscription.menu.displayAmount : subscription.menu.includedTaxPrice
                            )}
                            円
                          </div>
                        </div>
                      )}
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="new-menu-name">
                          個数
                        </div>
                        <div className="new-menu-name">
                          <span className="fs-23">
                            {subscription.gochiCount
                              ? numberFormatter(subscription.couponCount)
                              : 0}
                          </span>
                          /
                          <span>
                            {subscription.couponCount
                              ? numberFormatter(subscription.couponCount)
                              : 0}個
                          </span>
                        </div>
                      </div>
                    </>
                  )} */}

                  <hr className="new-hr mt-1" />
                  {subscription.amount && (
                    <div className="d-flex justify-content-between">
                      <div className="new-menu-name">ご請求額</div>
                      <div className="new-menu-name">
                        {numberFormatter(subscription.chargeAmount)}円
                      </div>
                    </div>
                  )}
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="new-menu-name">個数</div>
                    <div className="new-menu-name">
                      <span>
                        {subscription.couponCount
                          ? numberFormatter(subscription.couponCount)
                          : 0}
                        個
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {subscription.menu &&
              subscription.menu.description &&
              subscription.menu.description !== "" && (
                <div className="menu-description-combo fs-13">
                  {subscription.menu.description}
                </div>
              )}

            {isMenuCombo &&
              menuComboDetail &&
              menuComboDetail.quantityFreeDes &&
              menuComboDetail.quantityFreeDes !== "" && (
                <div>
                  <div className="arrow-up"></div>
                  <div className="quantity-free-des-container fs-13">
                    <div className="font-weight-bold">おまけ</div>
                    {menuComboDetail.quantityFreeDes}
                  </div>
                </div>
              )}

            {isMenuCombo &&
              menuComboDetail &&
              menuComboDetail.quantityFree > 0 && (
                <span className="big-free-ticket-label">
                  +{menuComboDetail.quantityFree}
                  枚無料(合計
                  {menuComboDetail.quantityFree + menuComboDetail.quantity}
                  枚)
                </span>
              )}

            {isMenuCombo && menuComboDetail && menuComboDetail.expiration && (
              <div className="mt-2">
                <span className="fs-12 clr-000000">利用期間</span>
                <span className="text-red fs-13 mt-2 font-weight-bold ml-2">
                  {menuComboDetail.expiration}日間利用可能
                </span>
              </div>
            )}

            {isMenuCombo && expirationEndDate && (
              <div className="mt-2">
                <span className="fs-12 clr-000000">利用期間</span>
                <span className="text-red fs-13 mt-2 font-weight-bold ml-2">
                  {`${expirationEndDate.getFullYear()}/${
                    expirationEndDate.getMonth() + 1 < 10 ? "0" : ""
                  }${
                    expirationEndDate.getMonth() + 1
                  }/${expirationEndDate.getDate()}`}
                  まで利用可能
                </span>
              </div>
            )}

            {menuComboDetail && menuComboDetail.numberUsePerDay === 1 && (
              <div className="mt-2">
                <span className="fs-12 clr-000000 font-weight-bold">
                  利用制限
                </span>
                <span className="text-red fs-13 font-weight-bold ml-2">
                  1日1枚
                </span>
              </div>
            )}

            {subscription.shopDto && (
              <>
                <hr className="new-hr mt-1 mb-3" />
                <div className="shop-name local-shop pl-0 pb-0">
                  {/* <i className="icon-location"></i>  */}
                  {subscription.shopDto.shopName}
                </div>
                <div className="fs-14 shop-address mt-1">
                  {subscription.shopDto.prefectures
                    ? subscription.shopDto.prefectures + " "
                    : ""}
                  {subscription.shopDto.city ? subscription.shopDto.city + " " : ""}
                  {subscription.shopDto.address
                    ? subscription.shopDto.address + " "
                    : ""}
                  {subscription.shopDto.building}
                </div>
                <div className="fs-14 text-green mt-2">
                  {subscription.shopDto.showPhone === 1 && subscription.shopDto.phone}
                </div>
              </>
            )}

            <div className="mt-3">
              <span className="font-weight-bold">
                サブスクリプション開始日：
              </span>
              <span>{moment(subscription.createdAt).format("YYYY.MM.DD")}</span>
              <br />
              <span className="font-weight-bold">購入頻度：</span>
              <span>{`${subscription.subscriptionIntervalCount}${
                subscription.subscriptionInterval === "WEEK" ? "週間" : "ヶ月"
              }1回`}</span>
              <br />
              <span className="font-weight-bold">次の請求日：</span>
              <span>
                {moment(subscription.currentPeriodEnd).format("YYYY.MM.DD")}
              </span>
            </div>

            <div
              className="list-shop-btn new-btn p-1"
              onClick={() => {
                setOpenPopupConfirm(true);
              }}
            >
              サブスクキャンセルする
            </div>

            <Modal
              show={openPopupConfirm}
              size="sm"
              centered
              dialogClassName="subscription-modal"
              onHide={() => setOpenPopupConfirm(false)}
            >
              <Modal.Body className="alert-dialog">
                サブスクリプションをキャンセルします。
                <br />
                よろしいでしょうか。
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setOpenPopupConfirm(false)}>
                  いいえ
                </Button>
                <Button
                  variant="primary"
                  onClick={() => handleSubmit(subscription.subscriptionCode)}
                >
                  はい
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      ) : (
        !loading && (
          <div className="gochi">
            <h3 className="mt-5">
              このサブスクリプションはキャンセルされました。
            </h3>
          </div>
        )
      )}

      <Modal
        show={!!errorMessage}
        size="sm"
        onHide={() => setErrorMessage("")}
        centered
        contentClassName="top-modal"
        backdropClassName="top-modal-backdrop"
        dialogClassName="subscription-modal"
      >
        <Modal.Body className="alert-dialog">{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setErrorMessage("")}>OK</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={!!successMessage}
        size="sm"
        onHide={() => onFinish()}
        centered
        contentClassName="top-modal"
        backdropClassName="top-modal-backdrop"
        dialogClassName="subscription-modal"
      >
        <Modal.Body className="alert-dialog">{successMessage}</Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button onClick={() => onFinish()}>
            ごちめしサイトへ
          </Button>
        </Modal.Footer>
      </Modal>

      <LoadingOverlay loading={loading} />
    </>
  );
}

export default connect()(withTranslation()(GochiSubscription));
