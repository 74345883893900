import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getInvitations, getAuthenCode, createAuthenCodeForShop } from "../../../actions/Gochis";
import MenuInfo from "../../common/MenuInfo";
import OrderButton from "../../common/OrderButton";
import QuantityFreeDes from "../../common/QuantityFreeDes";
import UseQuantity from "../../common/UseQuantity";
import HamburgerMenu from "../HamburgerMenu";
import LoadingOverlay from "../LoadingOverlay";
import queryString from "query-string";
import history from "../../history";
import QRCode from 'qrcode.react';

function BookingTicketUse(props) {
  const { t } = props;
  const { dispatch } = props;
  const { invitation } = props;
  const { authenCodeForShop } = props;
  const location = props.location;
  const addParams = location.state && location.state.addParams;

  var query = new URLSearchParams(props.location.search);
  var invitationCode = query.get("code");
  // const action = getInvitations({ invitationCode: invitationCode });

  const params = queryString.parse(window.location.search);
  const quantity = params.quantity;
  const shopName = params.shopName;

  const urlValue = window.location.protocol + '//' + window.location.hostname + '/shop/ticket-use?authenCode=' + authenCodeForShop.authenCode;

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [seconds, setSeconds] = useState(0);
  var timerId;

  useEffect(() => {
    // dispatch(action);
    getInvitationInfo();
    generateAuthenCode();
    countDown();
  }, []);

  function countDown() {
    const timer = setInterval(() => {
      setSeconds((t) => t - 1);
    }, 1000);
    return () => clearInterval(timer);
  }

  function callBackCheckFinish(authCode) {
    // repeat with the interval of 10 seconds
    timerId = setInterval(() => handleCheckFinish(authCode), 10*1000);

    // after 60 seconds stop
    setTimeout(() => { clearInterval(timerId); return; }, 60*1000);
  }

  function handleCheckFinish(authCode){
    let invitation = loadFromSessionStorage();
    if (authCode) {
      let action = getAuthenCode(authCode);
      dispatch(action).then((response) => {
        if (response.type === "GET_AUTHEN_CODE_SUCCESS") {
          if (response.payload && response.payload.status === 'FINISHED') {
            let couponId = response.payload.extraData && response.payload.extraData.couponIds 
                            && response.payload.extraData.couponIds.length > 0 
                            && response.payload.extraData.couponIds[0];
            history.push({
              pathname: "/ticket-use-complete",
              state: {
                invitation,
                couponId,
                quantity
              },
              search: `?code=${invitationCode}`,
            });
            clearTimeout(timerId);
          }
        }
      });
    }
  }

  function generateAuthenCode() {
    let message = t("pages.Gen QR code error, Try again");
    try {
      dispatch(createAuthenCodeForShop(addParams)).then((response) => {
        // console.log('authCode = ', response);
        if (response.type === "POST_AUTHEN_CODE_SUCCESS") {
          callBackCheckFinish(response.payload.authenCode);
          setSeconds(60);
          setErrorMessage(null);
        } else if (response.type === 'POST_AUTHEN_CODE_FAILURE'){
          setErrorMessage(message);
        }
      });
    } catch (e) {
      console.log(e);
      setErrorMessage(message);
    }
  }

  function openConfirm() {
    history.push({
      pathname: "/ticket-use-confirm",
      search: window.location.search + `&authenCode=${authenCodeForShop.authenCode}`,
    });
    // let pathname = "/ticket-use-confirm";
    // let search = window.location.search;
    // window.location.href = pathname + search + `&authenCode=${authenCodeForShop.authenCode}`;
  }

  function getInvitationInfo() {
    setLoading(true);
    try {
      let action = getInvitations({ invitationCode: invitationCode });
      dispatch(action).then((response) => {
        if (response.type === 'GET_GOCHIS_SUCCESS') {
          saveToSessionStorage(response.payload);
        } else {
          sessionStorage.removeItem('store');
          window.location.href = `/gift?code=${invitationCode}`;
        }
        setLoading(false);
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
      sessionStorage.removeItem('store');
      window.location.href = `/gift?code=${invitationCode}`;
    }
  }

  function saveToSessionStorage(store) {
    try {
      const serializedStore = JSON.stringify(store);
      sessionStorage.setItem('store', serializedStore);
    } catch(e) {
      console.log(e);
    }
  }

  function loadFromSessionStorage() {
    try {
      const serializedStore = sessionStorage.getItem('store');
      if (serializedStore === null) return undefined;
      return JSON.parse(serializedStore);
    } catch(e) {
      console.log(e);
      return undefined;
    }
  }

  return (
    <>
      <div className="gochi">
        <div className="title-new title-with-buger">
          <button
            type="button"
            className="icon-btn-back icon-vertical"
            // onClick={() => window.history.back()}
            onClick={() => window.location.href=`/gift?code=${invitationCode}`}
          >
            <i className="arrow left width-15"></i>
          </button>
          {t("pages.Use this ticket")}
          <HamburgerMenu />
        </div>
        <div className="mt-3">
          <ul className="progressbar align-center">
            <li className="step-choice active">
              <strong>STEP 1</strong>
            </li>
            <li className="step-select">
              <strong>STEP 2</strong>
            </li>
            <li className="step-confirm">
              <strong>STEP 3</strong>
            </li>
          </ul>
        </div>
        <div className="black-message-box">
          {t("pages.Please show this screen to the staff of the store")}
        </div>

        <MenuInfo 
          showShopName 
          shopName={shopName} 
          menuLimitTotal={invitation?.menuLimitTotal === 1} 
          hideAmount
        />

        <QuantityFreeDes />

        <UseQuantity quantity={quantity} />

        <div
          className="align-center font-weight-bold"
        >
          {t("pages.QR code for shop")}
        </div>
        <div className="mt-3 mb-3 align-center">
          {errorMessage ? (
            <div className="qr-code-error">
              {errorMessage}
            </div>
          ) : (
            <QRCode
              renderAs="svg"
              value={urlValue}
              className="qr-code"
              style={{ width: '60%', height: 'auto' }}
            />
          )}
          
        </div>
        <div
          className="wrapper-qr-code mb-3"
        >
          {!errorMessage && (
            <div
              className="font-weight-bold"
              style={{color: 'red'}}
            >
              {t("pages.QR code valid in 1 minute")}
            </div>
          )}
          {seconds > 0 && (
            <div
            className="font-weight-bold"
          >
            {seconds}
          </div>
          )}
          {seconds <= 0 && (
            <div
              className="btn-gen-qr font-weight-bold"
              onClick={() => {
                generateAuthenCode();
                callBackCheckFinish();
              }}
            >
              {t("pages.QR code re-generation")}
            </div>
          )}
        </div>
        
        <OrderButton openConfirm={openConfirm} />
      </div>

      <LoadingOverlay loading={loading} />
    </>
  );
}

const mapStateToProps = (state) => ({
  invitation: state.gochis,
  authenCodeForShop: state.authenCodeForShop
});

export default connect(mapStateToProps)(
  withTranslation()(BookingTicketUse)
);
