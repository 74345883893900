import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
// import Modal from "react-modal";
import BookingTicket from "./BookingTicket";
import BookingTicketConfirm from "./BookingTicketConfirm";
import BookingTicketComplete from "./BookingTicketComplete";
import { Modal, ModalBody } from "react-bootstrap";

class BookingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepBooking: "INPUT",
      inputData: null,
    };
    this.changeStepBooking = this.changeStepBooking.bind(this);
    this.handleCloseBookingPopup = this.handleCloseBookingPopup.bind(this);
  }

  componentDidMount() {}

  changeStepBooking(step, inputData) {
    this.setState({
      stepBooking: step,
      inputData,
    });
  }

  handleCloseBookingPopup() {
    this.setState({
      stepBooking: "INPUT",
    });
    this.props.handleCloseBookingPopup();
  }

  render() {
    return (
      <>
        <Modal
          show={this.props.showBooking}
          dialogClassName="custom-modal"
          // className="custom-modal-container"
          onHide={this.handleCloseBookingPopup}
          centered
        >
          <ModalBody>
            <>
              <BookingTicket
                modalView={true}
                handleCloseBookingPopup={() => this.handleCloseBookingPopup()}
                changeStepBooking={this.changeStepBooking}
                stepBooking={this.state.stepBooking}
              />
              <BookingTicketConfirm
                modalView={true}
                handleCloseBookingPopup={() => this.handleCloseBookingPopup()}
                changeStepBooking={this.changeStepBooking}
                inputData={this.state.inputData}
                stepBooking={this.state.stepBooking}
              />
              <BookingTicketComplete
                modalView={true}
                handleCloseBookingPopup={() => this.handleCloseBookingPopup()}
                changeStepBooking={this.changeStepBooking}
                inputData={this.state.inputData}
                stepBooking={this.state.stepBooking}
              />
            </>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(
  mapStateToProps
  //   mapDispatchToProps
)(withTranslation()(BookingModal));
