import React from "react";

export function DaigasLogo() {
  return (
    <div className="daigas-logo">
      <img className="daigas-logo-img" src="/images/daigas/DaigasLogo@2x.png" alt="Daigas" />
      <div className="daigas-primary-color font-weight-bold fs-14 text-center">受け取り期間　2021年11月30日まで</div>
    </div>
  );
}
