import React from 'react';
import '../../stylesheets/CommingSoon.css'

const bgStyle = {
  backgroundColor: "#ffffff"
}
const imageMaintain = {
  width: 300,
  height: 300,
  objectFit: 'cover',
  marginBottom: 20
}
function Maintain() {
  return(
    <div className="comming-soon-box" style={bgStyle}>
      <img
          src="/images/gochi_logo_print.png"
          alt="The website under maintenance"
          width="100%"
          style={imageMaintain}
      />
      
      <div className="text-left fs-14 mb-3">
        {/* エラーが発生しました。時間をおいてお試しください。 */}
        ただいまシステムに内部の構成変更に伴うメンテナンスのため、一時的にサービスの機能を全て停止しております。大変ご不便をおかけいたしますが、今しばらくお待ちください。<br/>
        <br/>
        <b>【メンテナンス作業予定日時】</b><br/>
        2024年2月21日（水）<b>20:00</b> ~ 2月22日（木）<b>08:00</b><br/>
        ※メンテナンス作業が終了次第公開いたします。<br/>
        <br/>
        <b>【お問い合わせ】</b><br/>
        <a 
          className="text-green"
          target="_blank"
          rel="noopener noreferrer"
          href="https://support.gigi.tokyo/contact/contact01/"
        >
          https://support.gigi.tokyo/contact/contact01/
        </a>
      </div>
    </div>
  );
}
export default Maintain;
