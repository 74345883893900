import { getListShopsMenu, getListShopsMenuBooking } from '../libs/apis/MenuApi';

export const GET_SHOPS_MENU_REQUEST = 'GET_SHOPS_MENU_REQUEST';
export const GET_SHOPS_MENU_SUCCESS = 'GET_SHOPS_MENU_SUCCESS';
export const GET_SHOPS_MENU_FAILURE = 'GET_SHOPS_MENU_FAILURE'; 

export const GET_BOOKING_SHOPS_REQUEST = 'GET_BOOKING_SHOPS_REQUEST';
export const GET_BOOKING_SHOPS_SUCCESS = 'GET_BOOKING_SHOPS_SUCCESS';
export const GET_BOOKING_SHOPS_FAILURE = 'GET_BOOKING_SHOPS_FAILURE';

export const getListShops = params => dispatch => {
  dispatch({ type: GET_SHOPS_MENU_REQUEST });
  return getListShopsMenu(params)
    .then(response =>
      dispatch({
        type: GET_SHOPS_MENU_SUCCESS,
        payload: response.data
      })
    )
    .catch(error =>
      dispatch({
        type: GET_SHOPS_MENU_FAILURE,
        payload: error.response,
        error: true
      })
    );
}; 

export const getListShopsBooking = params => dispatch => {
  dispatch({ type: GET_BOOKING_SHOPS_REQUEST });
  return getListShopsMenuBooking(params)
    .then(response =>
      dispatch({
        type: GET_BOOKING_SHOPS_SUCCESS,
        payload: response.data
      })
    )
    .catch(error =>
      dispatch({
        type: GET_BOOKING_SHOPS_FAILURE,
        payload: error.response,
        error: true
      })
    );
}; 